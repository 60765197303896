import React, { useState, useEffect } from "react";
import { CheckBox, DataTable, Status, ToolTip } from "../../components";
import { toast } from "react-toastify";
import api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
export default function GroupLinkBuilderBatchList() {
  const [isLoading, setIsLoading] = useState(false);
  const { user, group } = useParams();
  const [users, setUsers] = useState([]);
  const [myUser, setMyUser] = useState({});
  const [myGroup, setMyGroup] = useState({});
  const [groupListBatch, setGroupListBatch] = useState([]);

  // Get User Lists
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const findMyUser = () => {
    const foundUser = users?.users?.find((item) => item.name === user);
    if (foundUser) {
      setMyUser(foundUser);
    }
  };

  // Fetch User Groups List using
  const fetchGroupListHasBatch = () => {
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/groupNameListHasBatch/`,
        {
          user_id: myUser.id,
          level: myUser.role_id.level,
        }
      )
      .then((response) => {
        setGroupListBatch(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Find group from the list
  const findMyGroup = () => {
    const foundGroup = groupListBatch?.groupList?.find(
      (item) => item.group_name === group
    );
    if (foundGroup) {
      setMyGroup(foundGroup);
    }
  };

  const [groupLinkBuilderBatchList, setGroupLinkbuilderBatchList] = useState(
    {}
  );

  // Batches List
  const getGroupLinkbuilderBatchList = async () => {
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/groupLinkbuilderBatchList`,
        {
          user_id: myUser.id,
          group_id: myGroup.id,
        }
      )
      .then((response) => {
        setGroupLinkbuilderBatchList(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const [selectedData, setSelectedData] = useState([]);

  // Find User from users list to get user id.
  useEffect(() => {
    if (users?.totalCount > 0) {
      findMyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  // Fetch Groups List if you have user
  useEffect(() => {
    if (myUser?.name) {
      fetchGroupListHasBatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser]);

  // Find Group after you groups list
  useEffect(() => {
    if (groupListBatch?.totalCount > 0) {
      findMyGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupListBatch]);

  // Fetch Group Batches List when you found group
  useEffect(() => {
    if (myGroup?.group_name) {
      getGroupLinkbuilderBatchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myGroup]);

  useEffect(() => {
    setSelectedData(groupLinkBuilderBatchList?.groupListbatch || []);
  }, [groupLinkBuilderBatchList]);

  const onSingleselect = (index) => {
    setSelectedData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        assign_status: updatedData[index].assign_status === 0 ? 1 : 0,
      };
      return updatedData;
    });
  };

  const findAssignStatus = (batch) => {
    return selectedData.some(
      (item) => item.id === batch.id && item.assign_status === 1
    );
  };

  const handleSubmit = () => {
    const user = {
      user_id: myUser.id.toString(),
      group_id: myGroup.id,
      batch_ids: selectedData
        .filter((item) => item.assign_status === 1)
        .map((item) => item.id),
    };

    setIsLoading(true);
    api
      .put(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/batchAssignToLinkbuilder/`,
        user
      )
      .then(() => {
        toast.success("Action was successful!", { autoClose: 3000 });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const navigate = useNavigate();
  const getGroupDomainList = (batch) => {
    navigate(`/users/${user}/${group}/${batch.name}`);
  };

  return (
    <div className="p-7">
      <DataTable
        searchBox
        title={`batches list of ${group}`}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        tableHeight="h-[calc(100vh-340px)] md:h-[calc(100vh-210px)]"
        heads={[
          "Sr#",
          "assign",
          "batch name",
          "total domain",
          "Industry",
          "actions",
          "status",
        ]}
        items={groupLinkBuilderBatchList?.groupListbatch?.map(
          (batch, index) => ({
            serialNumber: index + 1,
            assign: (
              <ToolTip
                className="ml-5"
                arrowStyle="mr-24"
                tool={
                  <CheckBox
                    disabled={
                      batch["assign_status"] === 1 &&
                      (batch["report_status"] === "1" ||
                        batch["report_status"] === "2")
                    }
                    onChange={() => onSingleselect(index)}
                    checked={findAssignStatus(batch)}
                  />
                }
                tip={
                  batch["assign_status"] === 1 &&
                  (batch["report_status"] === "1" ||
                    batch["report_status"] === "2") &&
                  "The batch is in process, you can not unassign."
                }
              />
            ),
            name: batch["name"],
            domain: batch["total_batch_domain"],
            industry: batch["industry_name"],
            action: (
              <p onClick={() => getGroupDomainList(batch)} className="btnWhite">
                domains list
              </p>
            ),
            status:
              (batch["assign_status"] === 0 &&
                batch["report_status"] === "0" && (
                  <Status type="notAssign" />
                )) ||
              (batch["assign_status"] === 1 &&
                batch["report_status"] === "0" && <Status type="assigned" />) ||
              (batch["assign_status"] === 1 &&
                batch["report_status"] === "2" && (
                  <Status type="inProcess" />
                )) ||
              (batch["assign_status"] === 1 &&
                batch["report_status"] === "1" && <Status type="success" />),
          })
        )}
      />
    </div>
  );
}
