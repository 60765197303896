import React from "react";
import dayjs from "dayjs";
import MUIDataTable from "../../../components/MUIGrid";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { FaInfo } from "react-icons/fa";
const LogsModal = ({ logsData, getSessionLogInfo }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  function convertToLocalFormatted(datetimeStr) {
    return dayjs.utc(datetimeStr).local().format("ddd, MMM D, YYYY h:mm A");
  }
  const columns = [
    { field: "counter", headerName: "Sr#", width: 80 },
    {
      field: "login_time",
      headerName: "Login Time",
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) =>
        params.value ? convertToLocalFormatted(params.value) : "N/A",
    },
    {
      field: "logout_time",
      headerName: "Logout Time",
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) =>
        params.value ? convertToLocalFormatted(params.value) : "N/A",
    },
    {
      field: "session_duration",
      headerName: "Session Duration",
      minWidth: 100,
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <span className=" py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {formatTimeToHMS(params.value)}
          </span>
        ) : (
          <span className=" py-1 px-2 !rounded hover:cursor-pointer ml-3">
            N/A
          </span>
        ),
    },
    {
      field: "session_id",
      headerName: "Total Hit",
      minWidth: 100,
      flex: 1,
      renderCell: (params) =>
      (<div className="cursor-pointer">{params?.row?.session_id_log ? params.row.session_id_log.toString() : <FaInfo size={16} onClick={() => {
        getSessionLogInfo(params.value, params.rowNode.id - 1)
      }} />}</div>),
    },
  ];

  function formatTimeToHMS(timeStr) {
    if (timeStr === "00:00:00" || timeStr === "00:00:00.000000") {
      return "N/A";
    }

    const hasMilliseconds = timeStr.includes(".");

    let parsedTime;

    if (hasMilliseconds) {
      // Regex to extract hours, minutes, seconds, and milliseconds
      const regex = /^(\d{2}):(\d{2}):(\d{2})\.(\d{6})$/;
      const match = timeStr.match(regex);

      if (match) {
        const hours = parseInt(match[1], 10);
        const minutes = parseInt(match[2], 10);
        const seconds = parseInt(match[3], 10);
        const milliseconds = parseInt(match[4], 10);

        // Create a duration using dayjs with hours, minutes, seconds, and milliseconds
        parsedTime = dayjs.duration({
          hours,
          minutes,
          seconds,
          milliseconds,
        });
      }
    } else {
      // Handle time in format "HH:mm:ss"
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      parsedTime = dayjs.duration({
        hours,
        minutes,
        seconds,
      });
    }

    // Build the formatted string, omitting zero values
    const parts = [];

    // Include hours if greater than 0
    if (parsedTime.hours() > 0) {
      parts.push(`${parsedTime.hours()} hours`);
    }

    // Include minutes if greater than 0
    if (parsedTime.minutes() > 0) {
      parts.push(`${parsedTime.minutes()} minutes`);
    }

    // Include seconds if greater than 0
    if (parsedTime.seconds() > 0) {
      parts.push(`${parsedTime.seconds()} seconds`);
    }

    if (parts.length === 0) {
      return "N/A";
    }

    return parts.join(", ");
  }

  return (
    <MUIDataTable
      columnDefs={columns}
      items={(logsData.length ? logsData : [])?.map((record, index) => {
        const { login_time, logout_time, session_duration, session_id, session_id_log } = record;
        return {
          session_id_log: session_id_log,
          counter: index + 1,
          login_time,
          logout_time,
          session_duration,
          session_id: session_id
        };
      })}
      totalItems={logsData?.length}
      height="60vh"
      pagination="No"
      searchable="No"
      showCount="No"
      sortingMode="client"
      paginationMode="client"
      toolbar="No"
    />
  );
};

export default LogsModal;
