import { Tooltip } from "@mui/material";
import Loader from "../../components/Loader/Loading";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "../../components/common/Modal";
export const columns = ({
  backlinksTypeList,
  backlinksStatusList,
  followStatus,
  toggleStates,
  backlinksData,
  selectedData,
  onSelectAll,
  isSelected,
  onSingleSelect,
  isLoading,
  nicheProductList,
  locationTypes,
  showCategoryTooltip,
  setCategoryShowTooltip,
  showLocationTooltip,
  setLocationShowTooltip,
}) => [
  {
    field: "checkbox",
    column: "",
    renderHeader: (params) => (
      <input
        type="checkbox"
        onChange={(e) => onSelectAll(e.target.checked)}
        className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        checked={selectedData?.length === backlinksData?.length}
      />
    ),
    filterable: false,
    sortable: false,
    width: 10,
    renderCell: (params) => (
      <div className="flex justify-center w-full items-center">
        {isSelected(params.row.records) && isLoading ? (
          <Loader />
        ) : (
          <input
            type="checkbox"
            checked={isSelected(params.row.records)}
            onChange={(e) =>
              onSingleSelect({
                checked: e.target.checked,
                data: params.row.records,
              })
            }
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          />
        )}
      </div>
    ),
    disableColumnMenu: true,
    hideable: false,
  },
  { field: "counter", headerName: "#", filterable: false, width: 60 },
  {
    field: "b.backlink_domain",
    headerName: "Backlink Domain",
    minWidth: 180,
    editable: false,
    renderCell: (params) => {
      return (
        <a
          href={`https://www.${params.row["b.backlink_domain"]}`}
          className="hover:text-secondary underline"
          target="_blank"
          rel="noreferrer"
        >
          {params.row["b.backlink_domain"]}
        </a>
      );
    },
  },
  {
    field: "b.money_domain",
    headerName: "Money Domain",
    minWidth: 180,
    editable: false,
    renderCell: (params) => {
      return (
        <a
          href={`https://www.${params.row["b.money_domain"]}`}
          className="hover:text-secondary underline"
          target="_blank"
          rel="noreferrer"
        >
          {params.row["b.money_domain"]}
        </a>
      );
    },
  },
  {
    field: "b.backlink_type_id",
    headerName: "Backlink Type",
    width: 120,
    editable: false,
    align: "left",
    type: "singleSelect",
    getOptionValue: (value) => value?.id,
    getOptionLabel: (value) => value.type,
    valueOptions: backlinksTypeList,
    renderCell: (params) => {
      const status = backlinksTypeList.find(
        (status) => status.id === params?.row["b.backlink_type_id"]
      );
      return status ? status.type : null;
    },
    valueGetter: (params) => params?.row["b.backlink_type_id"],
    valueSetter: (params) => {
      const { value } = params;
      if (!value) {
        return {
          ...params.row,
          "b.backlink_type_id": null,
          "b.type": null,
        };
      }
      const status = backlinksTypeList.find((status) => status.id === value);
      if (status) {
        return {
          ...params.row,
          "b.backlink_type_id": value,
          "b.type": status.type,
        };
      }
      return params.row;
    },
    valueFormatter: (params) => params?.value,
  },
  {
    field: "b.ready_status_id",
    headerName: "Backlink Ready Status",
    minWidth: 100,
    flex: 1,
    editable: false,
    filterable: true,
    type: "singleSelect",
    getOptionValue: (value) => value?.id,
    getOptionLabel: (value) => value.ready_status,
    valueOptions: backlinksStatusList,
    renderCell: (params) => {
      const status = backlinksStatusList.find(
        (status) => status.id === params.row["b.ready_status_id"]
      );
      return status ? status.ready_status : "";
    },
    valueGetter: (params) => params.row["b.ready_status_id"],
    valueSetter: (params) => {
      if (params.value) {
        const { value } = params;
        const status = backlinksStatusList.find(
          (status) => status.id === value
        );
        if (status) {
          const updatedRow = {
            ...params?.row,
            "b.ready_status_id": params?.value,
            "b.ready_status": status?.ready_status,
          };
          return updatedRow;
        }
      }
      return params;
    },
    valueFormatter: (params) => params.value,
  },
  {
    field: "b.good_to_assign",
    headerName: "Good To Assign",
    width: 120,
    editable: false,
    type: "singleSelect",
    minWidth: 60,
    disableColumnMenu: true,
    align: "center",
    valueOptions: [
      { value: 1, label: "Yes" },
      { value: 0, label: "No" },
    ],
    getOptionValue: (option) => option?.value,
    getOptionLabel: (option) => option.label,
    renderCell: (params) => {
      const isChecked =
        toggleStates[params.id] !== undefined
          ? toggleStates[params.id]
          : params.row["b.good_to_assign"] === 1;
      return (
        <label className="flex items-center relative w-max cursor-pointer select-none ">
          <input
            type="checkbox"
            checked={isChecked}
            disabled={params?.row?.records?.isNew}
            className="appearance-none transition-colors cursor-pointer w-14 h-6 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500 checked:bg-green-500"
          />
          <span className="absolute font-medium text-xxs uppercase right-1 text-white">
            {" "}
            No{" "}
          </span>
          <span className="absolute font-medium text-xxs uppercase right-8 text-white">
            {" "}
            Yes{" "}
          </span>
          <span
            className={`w-6 h-6 right-7 absolute rounded-full transform transition-transform bg-gray-200 ${
              isChecked ? "translate-x-7" : "translate-x-0"
            }`}
          />
        </label>
      );
    },
  },
  {
    field: "b.follow_status",
    headerName: "Follow Status",
    minWidth: 100,
    flex: 1,
    editable: false,
    filterable: true,
    type: "singleSelect",
    getOptionValue: (value) => value?.value,
    getOptionLabel: (value) => value.label,
    valueOptions: followStatus,
    renderCell: (params) => params.value["b.follow_status"],
    valueGetter: (params) => params.row["b.follow_status"],
    valueFormatter: (params) => params.value,
  },
  {
    field: "b.backlink_paid_status",
    headerName: "Backlink Paid Status",
    minWidth: 100,
    flex: 1,
    editable: false,
    filterable: true,
    type: "singleSelect",
    getOptionValue: (value) => value?.value,
    getOptionLabel: (value) => value.label,
    valueOptions: [
      { value: "free", label: "Free" },
      { value: "paid", label: "Paid" },
    ],
    renderCell: (params) => {
      return (
        <span className="capitalize">
          {params.row["b.backlink_paid_status"]}
        </span>
      );
    },
    valueGetter: (params) => params.row["b.backlink_paid_status"],
    valueFormatter: (params) => params.value,
  },
  {
    field: "b.niche_product_ids",
    headerName: "Niche Sub Category",
    width: 220,
    filterable: false,
    editable: false,
    align: "left",
    type: "singleSelect",
    getOptionValue: (value) => value?.id,
    getOptionLabel: (value) => value.niche_category,
    valueOptions: nicheProductList,
    renderCell: (params) => {
      const categories = params?.row?.["b.niche_categories"]?.split(",") || [];
      function close() {
        setCategoryShowTooltip("");
      }
      return (
        <div className="relative">
          <p
            className={`bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3`}
            onClick={(e) => {
              if (categories.length) {
                setCategoryShowTooltip(params.id);
              }
            }}
          >
            {categories.length}
          </p>
          {showCategoryTooltip === params.id && (
            <Modal
              className="max-w-xl"
              handleModal={close}
              handleModalSubmit={close}
            >
              <div>
                <div
                  onClick={close}
                  className="cursor-pointer absolute top-2 right-2 text-xl text-gray-700 pb-2"
                >
                  <CloseIcon />
                </div>
                <div className="max-h-[300px] overflow-y-auto">
                {categories.map((item, catCount) => (
                  <div className="flex">
                    <p className="pr-2">{catCount + 1}.</p>
                    <p>{item}</p>
                  </div>
                ))}
                </div>
              </div>
            </Modal>
          )}
        </div>
      );
    },
    valueGetter: (params) => params?.row["b.niche_product_ids"],
    valueSetter: (params) => {
      const { value } = params;
      if (!value) {
        return {
          ...params.row,
          "b.niche_product_ids": null,
          "b.niche_categories": null,
        };
      }
      const status = nicheProductList.find((status) => status.id === value);
      if (status) {
        return {
          ...params.row,
          "b.niche_product_ids": value,
          "b.niche_categories": status.niche_category,
        };
      }
      return params.row;
    },
    valueFormatter: (params) => params?.value,
  },
  {
    field: "b.location_type",
    headerName: "Location Type",
    minWidth: 150,
    flex: 1,
    editable: false,
    filterable: true,
    type: "singleSelect",
    getOptionValue: (value) => value?.value,
    getOptionLabel: (value) => value.label,
    valueOptions: locationTypes,
    renderCell: (params) => (
      <div className="capitalize">{params.row["b.location_type"]}</div>
    ),
    valueGetter: (params) => params.row["b.location_type"],
    valueFormatter: (params) => params.value,
  },
  {
    field: "b.is_verified",
    headerName: "Verified",
    minWidth: 100,
    flex: 1,
    editable: false,
    filterable: true,
    type: "singleSelect",
    getOptionValue: (value) => value?.value,
    getOptionLabel: (value) => value.label,
    valueOptions: [
      { value: 1, label: "Yes" },
      { value: 0, label: "No" },
    ],
    renderCell: (params) => (params.value === 1 ? "Yes" : "No"),
    valueGetter: (params) => params.row["b.is_verified"],
    valueFormatter: (params) => params.value,
  },
  {
    field: "b.location",
    headerName: "Location",
    minWidth: 150,
    flex: 1,
    editable: false,
    filterable: false,
    renderCell: (params) => {
      const categories = params?.row?.["b.location_names"]?.split(",") || [];
      function close() {
        setLocationShowTooltip("");
      }
      return (
        <div className="relative">
          <p
            className={`bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3`}
            onClick={(e) => {
              if (categories.length) {
                setLocationShowTooltip(params.id);
              }
            }}
          >
            {categories.length}
          </p>
          {showLocationTooltip === params.id && (
            <Modal
              className="max-w-xl"
              handleModal={close}
              handleModalSubmit={close}
            >
              <div>
                <div
                  onClick={close}
                  className="cursor-pointer absolute top-2 right-2 text-xl text-gray-700 pb-2"
                >
                  <CloseIcon />
                </div>
                {categories.map((item, catCount) => (
                  <div className="flex">
                    <p className="pr-2">{catCount + 1}.</p>
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            </Modal>
          )}
        </div>
      );
    },
  },
  {
    field: "b.intersect_count",
    headerName: "Intersect Count",
    width: 120,
    editable: false,
    align: "left",
  },
  {
    field: "b.traffic",
    headerName: "Traffic",
    width: 120,
    editable: false,
    align: "left",
    filterable: false,
  },
  {
    field: "b.domain_rating",
    headerName: "DR",
    width: 80,
    editable: false,
    filterable: false,
    renderHeader: (params) => (
      <div className="flex justify- items-center w-full">
        <Tooltip
          title={
            <div className="flex flex-col text-sm w-[full]">Domain Rating</div>
          }
        >
          <span>DR</span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: "b.created_at",
    headerName: "Created At",
    width: 200,
    editable: false,
    filterable: false,
  },
  {
    field: "b.updated_at",
    headerName: "Updated At",
    width: 200,
    editable: false,
    filterable: false,
  },
];
