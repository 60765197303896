import React, { useEffect, useState } from "react";
import { InputField, TextArea, SelectBox } from "../../../components";
import { toast } from "react-toastify";
import api from "../../../services/api";
const URLModal = ({
  onClose,
  link_to_website,
  setLinkToWebsite,
  updateStatus,
  taskObj,
  isStatusNote,
  setStatusNote,
  errors,
  setHoldReason,
  isHoldReason,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [holdReasons, setHoldReasons] = useState([]);

  const fetchHoldReasons = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/hold_reasons`
      );

      if (response?.data) {
        setHoldReasons(response.data);
      } else {
        console.error("No data found in the response.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch hold reasons. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (taskObj?.status === "hold") {
      fetchHoldReasons();
    }
  }, [taskObj]);

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-40 bg-black/60">
        <div className="bg-white p-4 rounded-lg shadow-md relative w-auto max-w-xl min-w-[450px]">
          <div className="text-base font-medium !mb-6">
            {taskObj &&
            (taskObj.status === "hold" ||
              taskObj.status === "pending" ||
              taskObj.status === "cancelled" ||
              taskObj.status === "reOpen")
              ? "Please add a Note"
              : taskObj && taskObj.status === "completed"
              ? "Please paste the URL"
              : taskObj && taskObj.status === "inReview"
              ? "Please add a URL"
              : null}
          </div>
          {taskObj &&
            (taskObj.status === "hold" ||
              taskObj.status === "pending" ||
              taskObj.status === "cancelled" ||
              taskObj.status === "reOpen") && (
              <div className="flex items-center grid grid-cols-1 gap-x-6">
                <TextArea
                  placeholder="Add Note"
                  className="w-56"
                  value={isStatusNote}
                  onChange={(e) => setStatusNote(e.target.value)}
                />
                {errors?.note_status && (
                  <div className="text-red-500">{errors?.note_status}</div>
                )}

                {taskObj && taskObj?.status === "hold" ? (
                  <SelectBox
                    className="pt-2 mt-2"
                    label="Select Reason"
                    placeholder="Hold Reason"
                    onChange={(e) => setHoldReason(e.target.value)}
                    valueKey="id"
                    labelKey="hold_reason"
                    options={holdReasons}
                    value={isHoldReason}
                    height="h-[42px]"
                  />
                ) : null}
              </div>
            )}
          {taskObj && taskObj.status === "inReview" && (
            <div className="flex items-center grid grid-cols-1 gap-x-6">
              <InputField
                placeholder="Add URL"
                className="w-56"
                value={link_to_website}
                onChange={(e) => setLinkToWebsite(e.target.value)}
              />
            </div>
          )}
          {taskObj && taskObj.status === "completed" && (
            <div className="flex items-center grid grid-cols-1 gap-x-6">
              <InputField
                placeholder="Paste URL"
                className="w-56"
                value={link_to_website}
                onChange={(e) => setLinkToWebsite(e.target.value)}
              />
            </div>
          )}
          <div className="flex justify-end !mt-4">
            {isLoading ? (
              <div className="bg-blue-100 flex items-center justify-start rounded-md py-2 w-55">
                <img
                  src="/img/loading.gif"
                  className="w-6 mr-2"
                  alt="Loading"
                />
              </div>
            ) : (
              <button
                className="px-4 py-2 mr-2 bg-secondary text-white rounded hover:bg-blue-600"
                onClick={() => {
                  taskObj && taskObj?.rowId
                    ? updateStatus(
                        taskObj?.rowId,
                        taskObj?.status,
                        taskObj?.prevStatus
                      )
                    : updateStatus(taskObj?.status);
                }}
              >
                Confirm
              </button>
            )}
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default URLModal;
