import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { Modal } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import { MdDelete } from "react-icons/md";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import AddUpdateForm from "./AddUpdateForm";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { get_niche_category } from "../../store/slices/nicheCategorySlice";
export default function NicheSubCategory() {
  const dispatch = useDispatch();
  const [isSubCategorys, setSubCategorys] = useState([]);
  const [isLoader, setIsLoading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState({});
  const selectedCategoryId = localStorage.getItem("niche_category") ?? "1";
  const { isLoading, record } = useSelector((state) => state.nicheCategory);
  const selectedCategory = record.length?record.find(e=>e.id === parseInt(selectedCategoryId)):{};

  const fetchCategoryData = () => {
    dispatch(get_niche_category());
  };

  const fetchSubCategorysData = async () => {
    setIsLoading(true);
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/list_niche_sub_category`,
        { niche_category_id: selectedCategoryId }
      );
      setSubCategorys(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(`Error fetching users data: ${error}`);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!record.length) {
        fetchCategoryData();
    }
    fetchSubCategorysData();
  }, []);

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleNewSubCategory = () => {
    handleModal(true);
    setModalType("new");
  };

  const handleEditSubCategory = (data) => {
    setSelectedSubCategory(data);
    setModalType("edit");
    handleModal();
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3 flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
        </div>
        <div className="flex">
          <button className="btnPrimary mr-2" onClick={handleNewSubCategory}>
            Create Sub Category
          </button>
        </div>
      </GridToolbarContainer>
    );
  }
  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 200, filterable: false },
    {
      headerName: "Sub Category Name",
      field: "niche_sub_category",
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: "Category Name",
      field: "niche_category",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <div className="">{selectedCategory.niche_category}</div>
      )
    },
    {
      headerName: "Actions",
      field: "",
      minWidth: 200,
      filterable: false,
      renderCell: (params) => (
        <div className="relative">
          <div className="flex items-center justify-center">
            {userPermission("Edit Niche") ? (
              <PencilSquareIcon
                key={`${params?.row.id}-action`}
                className="h-5 cursor-pointer text-black-400"
                onClick={() => handleEditSubCategory(params.row.records)}
                title="Edit Niche"
              />
            ) : null}
            {userPermission("Delete Niche") ? (
              <MdDelete
                className="h-5 cursor-pointer text-black-400 ml-1"
                title="Delete Niche"
                onClick={(e) => deleteSubCategory(params.row.records.id)}
                size={20}
              />
            ) : null}
          </div>
        </div>
      ),
    },
  ];

  const deleteSubCategory = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (!isConfirmed) return;
    api
      .delete(`/api/backlinks/niche_sub_category/${id}`)
      .then((res) => {
        toast.success("Subcategory Removed Successfully.");
        fetchSubCategorysData();
      })
      .catch((err) => toast.error(err.response.data.message));
  };
  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Niche Sub Category", link: "" },
        ]}
      />
      <ToastContainer />
      {(isLoader || isLoading) && <Loading />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={isSubCategorys?.map((data, index) => {
            const { niche_category_id, niche_sub_category } = data;
            const category = record.find(
              (e) => e.id === niche_category_id
            );
            return {
              records: data,
              counter: index + 1,
              niche_sub_category,
              niche_category: category?.niche_category,
            };
          })}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
          pagination="No"
          paginationMode="client"
        />
      </div>
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-xl"
        title={
          (modalType === "new" && "Create Sub Category") ||
          (modalType === "edit" && "Edit Sub Category")
        }
      >
        <AddUpdateForm
          handleModal={handleModal}
          fetchData={fetchSubCategorysData}
          isLoading={isLoader}
          setIsLoading={setIsLoading}
          modalType={modalType}
          subcategory={selectedSubCategory}
          isCategorys={record}
        />
      </Modal>
    </div>
  );
}
