import React from "react";
import { DataTable } from "../../components";

export default function LinkBuilderBatchesList({
  batchesList,
  getBatchDomainList,
}) {
  return (
    <DataTable
      className="px-0 py-0"
      heads={["Sr#", "batch name", "total domain", "total backlink", "actions"]}
      items={batchesList["linkbuilder_batch_list"]?.map((batch, index) => ({
        serialNumber: index + 1,
        name: batch["batch_name"],
        domain: batch["total_domain"],
        backlink: batch["total_backlink"],
        action: (
          <p
            onClick={() => getBatchDomainList(batch)}
            className="font-medium text-secondary cursor-pointer p-2 bg-white text-center rounded-full transition-all"
          >
            domains list
          </p>
        ),
      }))}
    />
  );
}
