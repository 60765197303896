import React from "react";
import Chart from "./Chart";
export default function ChartSection({ user, dashboardCount }) {
  return (
    <div className={`grid md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6`}>
      {(user?.role_id === 1 || user?.role_id === 7) && (
        <>
          {" "}
          <Chart
            to="/settings/users"
            title="Users"
            value={dashboardCount?.userCount}
            icon="/img/icons/LinkBuilders.png"
            color="#C9F7F5"
            border="#1BC5BD"
          />
          <Chart
            to="/backlinks/backlinks-list"
            title="Backlinks"
            value={dashboardCount?.backlinkCount}
            icon="/img/icons/Backlinks.png"
            color="#EFF5FF"
            border="#0B2541"
          />
          <Chart
            to="/domains"
            title="Domains"
            value={dashboardCount?.domainCount}
            icon="/img/icons/Domains.png"
            color="#EFF5FF"
            border="#0B2541"
          />
        </>
      )}
    </div>
  );
}
