const SelectBox = ({
  name,
  label,
  onChange,
  onBlur,
  disabled = false,
  placeholder,
  value,
  required,
  className,
  options,
  error,
  valueKey,
  labelKey,
  selectValue,
  height,
}) => {
  return (
    <div className={`w-full ${className}`}>
      <label
        className="inputLabel dark:text-white mb-1 font-bold"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="relative">
        <select
          disabled={disabled}
          name={name}
          aria-label={label}
          placeholder={placeholder}
          className={`inputField ${height}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
        >
          <option value="">{selectValue || "Select"}</option>
          {options.map((item, index) => (
            <option key={index} value={item[valueKey]}>
              {item[labelKey]}
            </option>
          ))}
        </select>
      </div>
      {error && <p className="text-red-400">{error}</p>}
    </div>
  );
};

export default SelectBox;
