import React from "react";
import dayjs from "dayjs";
import MUIDataTable from "../../../components/MUIGrid";

const LogsModal = ({ logsData, taskStatusOptions, users }) => {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const columns = [
    { field: "counter", headerName: "Sr#", width: 10 },
    {
      headerName: "Task Status",
      field: "task_status",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <span className="capitalize">
              {addSpaceBeforeUpperCase(params?.row?.task_status)}
            </span>
          </>
        );
      },
      type: "singleSelect",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        status: value?.value,
      }),
      valueOptions: taskStatusOptions,
    },
    {
      field: "role",
      headerName: "Role",
      width: 250,
      editable: true,
    },
    {
      field: "user_id",
      headerName: "User",
      flex: 1,
      minWidth: 200,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: users,
      valueGetter: (params) => params.row?.user_id,
      valueFormatter: (params) => {
        const user = users.find((user) => user.id === params.value);
        return user ? user.name : "";
      },
      renderCell: (params) => {
        const user = users.find((user) => user.id === params.row?.user_id);
        return <>{user ? user.name : "User Not Found"}</>;
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 200,
    },
  ];
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };

  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }

  return (
    <MUIDataTable
      columnDefs={columns}
      items={(logsData.length ? logsData : [])?.map((record, index) => {
        const { task_status, user_id, role, name } = record;
        const created_at = dayjs(record["created_at"]).format(
          "ddd, MMM D, YYYY h:mm A"
        );
        const updated_at = dayjs(record["updated_at"]).format(
          "ddd, MMM D, YYYY h:mm A"
        );
        return {
          counter: index + 1,
          task_status,
          user_id,
          role,
          name,
          created_at,
          updated_at,
        };
      })}
      totalItems={logsData?.length}
      height="60vh"
      pagination="No"
      paginationModel={paginationModel}
      searchable="No"
      showCount="No"
      sortingMode="client"
      paginationMode="client"
      toolbar="No"
      onPaginationModelChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};

export default LogsModal;
