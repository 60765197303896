import { Menu, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import React, { Fragment } from "react";
import { FaAngleDown } from "react-icons/fa";
import userPermission from "../../utils/userPermission";

export default function SubPermission({
  name,
  children,
  selectedData,
  onSingleselect,
  item,
  handleUpdatePermissions,
}) {
  const renderSubRoles = (items) => {
    return (
      <Menu.Items className="pl-5 ">
        {items?.map((item, key) => {
          return (
            <div
              key={key}
              title={item.name}
              className="flex items-center gap-2  dark:border-white/20 last:border-none"
            >
              {item?.sub_permissions?.length ? (
                <SubPermission
                  children={item.sub_permissions}
                  name={item.name}
                />
              ) : (
                <div className="flex justify-between w-full ">
                  <div
                    className="flex items-center gap-2 w-full py-2 border-b "
                    title={item.name}
                  >
                    <span>{item.name}</span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </Menu.Items>
    );
  };
  return (
    <Menu as="div" className="w-full">
      {name ? (
        <div className="flex items-center gap-2  border-b">
          <Menu.Button className="flex items-center justify-between w-full border-">
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex items-center gap-2 py-2">{name}</div>
              <FaAngleDown className="mr-2" />
            </div>
            {userPermission("Edit Permission") && item?.id ? (
              <div className="flex items-center px-2">
                <PencilSquareIcon
                  key={`${name}-action`}
                  onClick={() =>
                    handleUpdatePermissions({
                      id: item?.id,
                      name,
                      sub_permissions: children,
                    })
                  }
                  className="h-5 cursor-pointer text-gray-400"
                />
              </div>
            ) : null}
          </Menu.Button>
        </div>
      ) : null}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {renderSubRoles(children, onSingleselect, selectedData)}
      </Transition>
    </Menu>
    // <div as="div" className="w-full">
    //   {name ? (
    //     <div className="flex items-center gap-2 border-b last:border-none">
    //       <div className="flex items-center justify-between w-full border-">
    //         <div className="flex items-center gap-2 py-2">{name}</div>
    //       </div>
    //     </div>
    //   ) : null}
    //   {renderSubRoles(children, onSingleselect, selectedData)}
    // </div>
  );
}
