import React from "react";
import LayoutHead from "./LayoutHead";
import Sidebar from "../Menu/Sidebar";
import { useDarkMode } from "../../utils/useDarkMode";
import PDFModal from "./PdfModal";
import { useSelector } from "react-redux";
//import WebSocketListener from "./WebSocketListener";
export default function Layout({
  user,
  searchText,
  setSearchText,
  show_navlabel,
  toggleNavbar,
  isLoading,
  children,
}) {
  const [isDarkMode] = useDarkMode();
  const { instructionModal } = useSelector((state) => state.auth);
  return (
    <div className={`${isDarkMode ? "dark bg-gray-900" : "bg-light"} relative`}>
      {isLoading ? (
        <div className="flex flex-col w-full justify-center text-center h-screen">
          Loading...
        </div>
      ) : user ? (
        <>
          <LayoutHead
            toggleNavbar={toggleNavbar}
            show_navlabel={show_navlabel}
            setSearchText={setSearchText}
            searchText={searchText}
          />
          <div
            className={
              show_navlabel
                ? "grid grid-cols-body h-screen"
                : "w-full min-h-screen scroll-container"
            }
          >
            {show_navlabel && <Sidebar show_navlabel={show_navlabel} />}
            <div
              className={
                show_navlabel
                  ? "pt-16 overflow-y-scroll scroll-container bg-light dark:bg-dark dark:text-white/80"
                  : "pt-12 bg-light dark:bg-dark dark:text-white/80"
              }
            >
              {children}
            </div>
          </div>
        </>
      ) : (
        children
      )}
      {instructionModal ? <PDFModal /> : null}
      {/* {user?.user && user?.user?.role_id === 1 ? <WebSocketListener /> : null} */}
    </div>
  );
}
