import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown";
import { menuItems } from "./Menu";
import { useDarkMode } from "../../utils/useDarkMode";
import userPermission from "../../utils/userPermission";
import { MdDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../components";
import Loading from "../../components/Loader/Loading";
import api from "../../services/api";
import { toast } from "react-toastify";
import { setLoggedInUser } from "../../store/slices/authSlice";
import { osName } from "react-device-detect";
const Topbar = ({ show_navlabel }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const [isDarkMode] = useDarkMode();
  const { user } = useSelector((state) => state.auth);
  const [merchantId, setMerchantId] = useState(user?.user?.merchant_id?.id);
  const [isLoading, setIsLoading] = useState(false);
  const isAllow = (item) => {
    if (user?.permissions?.some((obj) => obj === item?.label)) {
      return true;
    } else {
      return false;
    }
  };
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };
  const sideMenu = Object.keys(menuItems).reduce(
    (acc, key) => {
      const menuItem = menuItems[key];
      if (user?.permissions?.some((obj) => obj === menuItem?.label)) {
        acc.push({
          ...menuItem,
          key,
        });
      }
      return acc;
    },
    [user]
  );
  const handleDownload = async () => {
    const response = await fetch("https://api.roboanywhere.com/launcher.yaml");
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "launcher.yaml";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
  const handleSwitchMerchant = async (id) => {
    if (!id) return;
    setIsLoading(true);
    try {
      const response = await api.post("/api/merchants/switch_merchant", {
        merchant_id: parseInt(id),
      });
      if (response?.status === 200) {
        dispatch(
          setLoggedInUser({
            ...user,
            user: { ...user?.user, merchant_id: response?.data?.merchant },
          })
        );
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            user: { ...user?.user, merchant_id: response?.data?.merchant },
          })
        );
        toast.success("Merchant changed successfully");
        navigate("/dashboard");
        window.location.reload();
      } else {
        toast.error(
          response?.data ||
            response?.data?.message ||
            "Merchant couldn't be switched"
        );
      }
    } catch (error) {
      toast.error(
        error?.response?.data ||
          error?.response?.data?.message ||
          "Merchant couldn't be switched"
      );
      console.error(error);
    }
    setIsLoading(false);
  };
  const handleMenuClick = (e) => {
    if (user?.user?.merchant_id?.id !== 1) {
      e.preventDefault();
      navigate(`/dashboard`);
      toast.error("No records for domains!");
    }
  };
  const handleAgentDownload = () => {
    let downloadLink;

    if (osName === "Windows") {
      // Windows download link
      downloadLink =
        "https://mlxdists.s3.eu-west-3.amazonaws.com/mlx/1.16.0/multiloginx_setup-amd64.exe";
    } else if (osName === "Mac OS") {
      // Additional check for M-series or Intel on macOS\
      const isAppleSilicon = (() => {
        // Try to detect architecture using navigator.userAgentData if available
        if (navigator.userAgentData?.platform === 'macOS' && navigator.userAgentData?.architecture === 'arm') {
          return true; // Likely Apple Silicon
        }
        // Fallback checks based on platform and userAgent
        if (navigator.platform === 'MacIntel') {
          return false; // Intel Mac
        } else if (navigator.platform === 'MacARM64' || /Apple M/.test(navigator.userAgent)) {
          return true; // Apple Silicon (M-series)
        }

        return false; // Default to Intel if unknown
      })();
      downloadLink = isAppleSilicon
        ? "https://cdn-mlx-prod.multiloginapp.com/mlx/latest/multiloginx-arm64.pkg"
        : "https://cdn-mlx-prod.multiloginapp.com/mlx/latest/multiloginx-amd64.pkg";
    }

    // Create an invisible anchor element and trigger the download
    const link = document.createElement("a");
    link.href = downloadLink;
    link.download = downloadLink.split("/").pop(); // Set the download attribute with filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  };
  return (
    <div className="flex items-center justify-between w-fit gap-6">
      {isLoading && <Loading />}
      {!show_navlabel ? (
        <>
          <Link to="/">
            {isDarkMode ? (
              <img
                src="/img/logo.svg"
                className="h-10 hidden md:block"
                alt=""
              />
            ) : (
              <img
                src="/img/robo_logo_3.png"
                className="h-10 hidden md:block"
                alt=""
              />
            )}
          </Link>
          <div className="hidden md:flex items-center justify-center">
            {sideMenu.map((item, key) => {
              const { label, to, children } = item;
              const isCurrentPath = currentPath.endsWith(to);
              if (!isAllow(item)) {
                return null;
              }
              return (
                <div key={key} className="sidebar-item">
                  {item?.children ? (
                    <Dropdown
                      show_navlabel={show_navlabel}
                      label={label}
                      to={to}
                      options={children}
                      allowedRoles={item?.allowedRoles}
                    />
                  ) : (
                    <Link
                      className={`pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize ${
                        isCurrentPath &&
                        "bg-secondary text-white hover:bg-secondary px-4"
                      }`}
                      title={label}
                      to={to}
                      onClick={to === "/domains" ? handleMenuClick : null}
                    >
                      <span className="ml-1">{label}</span>
                    </Link>
                  )}
                </div>
              );
            })}
            {userPermission("Download Agent") && (
              <button
                onClick={handleAgentDownload}
                className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize ml-2 bg-secondary text-white hover:bg-secondary px-4"
              >
                <MdDownload size={16} className="mr-1" /> Download Agent
              </button>
            )}
            {userPermission("Download Key") ? (
              <button
                onClick={handleDownload}
                title="Download key"
                className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize ml-2 bg-secondary text-white hover:bg-secondary px-4"
              >
                <MdDownload size={16} className="mr-1" /> Download Key
              </button>
            ) : null}
          </div>
          <div className="w-fit ml-2">
            {userPermission("Switch Merchant") ? (
              <select
                name="Users"
                className="text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md mr-2 border hover:text-black w-[180px]"
                // onChange={(e) => setMerchantId(e.target.value)}
                onChange={(e) => handleSwitchMerchant(e.target.value)}
                value={
                  user?.user?.merchant_id?.id ||
                  user?.user?.selected_merchant_id
                }
              >
                <option value="" className="dark:bg-blue">
                  Switch Merchant
                </option>
                {user?.user?.merchants?.map(({ id, company_name }) => (
                  <option key={id} value={id}>
                    {company_name}
                  </option>
                ))}
              </select>
            ) : (
              <select
                name="Users"
                className="text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md mr-2 border hover:text-black w-[180px]"
              >
                <option value="" className="dark:bg-blue">
                  {user && user?.user?.merchant_id
                    ? user?.user?.merchant_id?.company_name
                    : ""}
                </option>
              </select>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="hidden xl:flex items-center justify-center">
            {userPermission("Download Agent") && (
              <button
                onClick={handleAgentDownload}
                className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize ml-2 bg-secondary text-white hover:bg-secondary px-4"
              >
                <MdDownload size={16} className="mr-1" /> Download Agent
              </button>
            )}
            {/* {userPermission("Download Agent") ? (
              <a
                title="Download Agent"
                href="https://mlxdists.s3.eu-west-3.amazonaws.com/mlx/1.16.0/multiloginx_setup-amd64.exe"
                className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize ml-2 bg-secondary text-white hover:bg-secondary px-4"
              >
                <MdDownload size={16} className="mr-1" /> Download Agent
              </a>
            ) : null} */}

            {userPermission("Download Key") ? (
              <button
                onClick={handleDownload}
                title="Download key"
                className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize ml-2 bg-secondary text-white hover:bg-secondary px-4"
              >
                <MdDownload size={16} className="mr-1" /> Download Key
              </button>
            ) : null}
          </div>
          <div className="w-fit ml-2">
            {userPermission("Switch Merchant") ? (
              <select
                name="Users"
                className="text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md mr-2 border hover:text-black w-[180px]"
                // onChange={(e) => setMerchantId(e.target.value)}
                onChange={(e) => handleSwitchMerchant(e.target.value)}
                value={
                  user?.user?.merchant_id?.id ||
                  user?.user?.selected_merchant_id
                }
              >
                <option value="" className="dark:bg-blue">
                  Switch Merchant
                </option>
                {user?.user?.merchants?.map(({ id, company_name }) => (
                  <option key={id} value={id}>
                    {company_name}
                  </option>
                ))}
              </select>
            ) : (
              <select
                name="Users"
                className="text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md mr-2 border hover:text-black w-[180px]"
              >
                <option value="" className="dark:bg-blue">
                  {user && user?.user?.merchant_id
                    ? user?.user?.merchant_id?.company_name
                    : ""}
                </option>
              </select>
            )}
          </div>
        </>
      )}
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-xl"
        title={
          <span className="text-lg italictext-2xl text-[#3b0062] m-1 ">
            Switch Merchant
          </span>
        }
      >
        <div className="flex flex-col items-center">
          <div className="grid md:grid-cols-1 gap-4 w-full max-w-lg h-[90px]">
            <div className="mt-2">
              <label htmlFor="roleId" className="inputLabel"></label>
              <select
                onChange={(e) => setMerchantId(e.target.value)}
                name="merchant_id"
                className="inputField mt-1 w-full"
                value={merchantId}
              >
                <option value="">Select Merchant</option>
                {user?.user?.merchants?.map(({ id, company_name }) => (
                  <option key={id} value={id}>
                    {company_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex items-center justify-end mt-5 space-x-2 text-white w-full mr-2">
            <button
              type="button"
              onClick={handleSwitchMerchant}
              className="btnPrimary bg-green-600"
            >
              Switch Now
            </button>
            <button
              onClick={handleModal}
              type="button"
              className="btnPrimary bg-red-500"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Topbar;
