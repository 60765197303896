import React, { useEffect, useRef, useState } from "react";
import { DataTable, Modal } from "../../components";
import api from "../../services/api";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import AddUpdateUserForm from "../LinkBuilders/AddUpdateUserForm";
import { useNavigate } from "react-router-dom";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";

export default function LinkBuilders() {
  const [LinkBuilders, setLinkBuilders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchLinkBuildersList = () => {
    setIsLoading(true);
    api
      .get(`/api/linkbuilder_user/`)
      .then((res) => setLinkBuilders(res.data))
      .catch((err) => console.log(err.data.message))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetchLinkBuildersList();
    //fetcRoles();
  }, []);

  const [modalType, setModalType] = useState("");
  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  // Create New Link Builder
  const handlelinkBuilder = () => {
    setModalType("newUser");
    handleModal();
  };

  // Edit LInk Builder
  const [selectedUser, setSelectedUser] = useState({});
  const handleEditUser = (user) => {
    setModalType("editUser");
    setSelectedUser(user);
    handleModal();
  };

  const navigate = useNavigate();
  const handleUserTask = (userId, filter) => {
    navigate(`/settings/link-builders/${userId}`, {
      state: {
        filter,
      },
    });
  };

  return (
    <div className="px-6 pt-6">
       {isLoading && (
            <Loading />
        )}
      <div className="flex items-center justify-between mt-2">
        <h1 className="elementHeading2">Link Builders</h1>
        {userPermission("Add Link Builders") ? (
          <button onClick={handlelinkBuilder} className="btnPrimary">
            Add Link Builder
          </button>
        ) : null}
      </div>
      <DataTable
        heads={[
          "Edit",
          "Sr#",
          "Name",
          "Role",
          "Username",
          "Email",
          "total tasks",
          "pending",
          "completed",
          "in process",
          "on hold",
        ]}
        items={LinkBuilders?.map((user, index) => {
          return {
            // id: user.id,
            action: userPermission("Edit Link Builders") ? (
              <div className="flex items-center pl-1">
                <PencilSquareIcon
                  key={`${user.username}-action`}
                  onClick={() => handleEditUser(user)}
                  className="h-5 cursor-pointer text-gray-400"
                />
              </div>
            ) : null,
            serialnumber: index + 1,
            name: user["name"],
            role: user["role"],
            username: user["username"],
            email: user["email"],
            totalTasks: (
              <button
                onClick={() => handleUserTask(user.id, "all")}
                className="text-center"
              >
                {user["total_tasks"]}
              </button>
            ),
            pending: (
              <button
                onClick={() => handleUserTask(user.id, "pending")}
                className="text-center"
              >
                {user["pending_tasks"]}
              </button>
            ),
            completed: (
              <button
                onClick={() => handleUserTask(user.id, "completed")}
                className="text-center"
              >
                {user["completed_tasks"]}
              </button>
            ),
            inProcess: (
              <button
                onClick={() => handleUserTask(user.id, "inProcess")}
                className="text-center"
              >
                {user["inProcess_tasks"]}
              </button>
            ),
            onHold: (
              <button
                onClick={() => handleUserTask(user.id, "hold")}
                className="text-center"
              >
                {user["hold_tasks"]}
              </button>
            ),
          };
        })}
      />

      {(modalType === "editUser" || modalType === "newUser") && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-2xl"
          title={
            (modalType === "newUser" && "Create New Link Builder") ||
            (modalType === "editUser" && "Edit Link Builder Info")
          }
        >
          <AddUpdateUserForm
            handleModal={handleModal}
            fetchUsersData={fetchLinkBuildersList}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            modalType={modalType}
            user={selectedUser}
          />
        </Modal>
      )}
    </div>
  );
}
