import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isError: false,
  isLoading: false,
  data: [],
  message: "",
  errors: [],
};
export const taskPrevDomainSlice = createSlice({
  name: "selectedTaskDomainId",
  initialState,
  reducers: {
    domainReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.message = "";
      state.data = [];
      state.errors = [];
    },
    setTaskDomainData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { domainReset, setTaskDomainData } = taskPrevDomainSlice.actions;
export default taskPrevDomainSlice.reducer;
