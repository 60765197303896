const userPermission = (item) => {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  if (user?.permissions?.length > 0) {
    if (user?.permissions?.includes(item)) {
      return true;
    }
    return false;
  }
};
export default userPermission;
