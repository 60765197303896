import { useState } from "react";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import SearchBox from "../SearchBox";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
const DataTable = ({
  heads,
  items,
  className,
  tableHeight,
  isLoading,
  totalRecords,
  startIndex,
  lastIndex,
  toNextPage,
  toPreviousPage,
  toFirstPage,
  toLastPage,
  nextPageDisabled,
  previousPageDisabled,
  denseRow,
  title,
  searchBox,
  handleSubmit,
  extras,
  itemsPerPage,
  handlePerPageChange,
  enableAllRecords,
  onRowClick,
  expandedRow,
  additionalData,
  excludeKeys,
  childActions,
  userPermission,
  deleteContent,
  handleUpdateContent,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const handleRowClick = (itemId) => {
    if (onRowClick) {
      onRowClick(itemId);
    }
  };
  return (
    <>
      <div className="flex items-center justify-between">
        {title && <h1 className="font-bold text-3xl capitalize">{title}</h1>}
        <div className="flex items-center justify-end gap-3">
          {extras}
          {searchBox && (
            <div className="relative">
              <SearchBox
                type="text"
                placeholder="Search..."
                className="border border-gray-300 py-1 rounded-md bg-white w-fit h-[38px] mt-2"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {searchQuery && (
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 mt-1"
                  onClick={() => setSearchQuery("")}
                >
                  Clear
                </button>
              )}
            </div>
          )}
          {handleSubmit && (
            <>
              {isLoading ? (
                <div className="bg-blue-100 flex items-center justify-center rounded-md py-2 w-48">
                  <img src="/img/loading.gif" className="w-7" alt="" />
                </div>
              ) : (
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btnPrimary bg-green-600"
                >
                  Save and Update
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div className={`rounded w-full bg-white mt-5 p-3 ${className}`}>
        <div
          className={`w-full overflow-y-scroll scroll-container ${
            totalRecords ? "h-[calc(100vh-235px)]" : "h-[calc(100vh-210px)]"
          } ${tableHeight}`}
        >
          <div className="min-w-full divide-y divide-gray-200 dark:divide-white/25">
            <div className="g-gray-20 bg-[#f8f8f8]  backdrop-blur-md sticky top-0 shadow-sm flex z-10">
              {heads?.map((head, index) => (
                <div
                  key={index}
                  className={`py-2 px-3 tableHead ${
                    index === 0 ? "w-[250px]" : "w-full"
                  }`}
                >
                  {head}
                </div>
              ))}
            </div>
            <div className="divide-y divide-gray-200 dark:divide-white/40 h-full overflow-y-scroll dark:text-white/70">
              {items
                ?.filter((item) =>
                  Object.values(item)?.some((value) =>
                    value
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(searchQuery.toLowerCase())
                  )
                )
                ?.map((item, index) => (
                  <div key={index}>
                    <div
                      className="hover:bg-gray-100 dark:hover:bg-white/10 cursor-pointer flex"
                      onClick={(e) => handleRowClick(item)}
                    >
                      <div className="w-[250px] py-3 px-3 text-gray-500">
                        {expandedRow === item.id ? (
                          <MdKeyboardArrowDown className="w-5 h-5" />
                        ) : (
                          <MdKeyboardArrowRight className="w-5 h-5" />
                        )}
                      </div>
                      {Object.keys(item)
                        .filter((key) => !excludeKeys.includes(key))
                        .map((key) => (
                          <div
                            className={`px-3 ml-2 ${
                              denseRow ? "py-1" : "py-3"
                            } w-full`}
                            key={key}
                          >
                            <div
                              className={`text-sm whitespace-nowrap`}
                              onClick={(event) =>
                                key === "actions"
                                  ? event.stopPropagation()
                                  : null
                              }
                            >
                              {childActions && key === "actions"
                                ? null
                                : item[key]}
                            </div>
                          </div>
                        ))}
                    </div>
                    {expandedRow === item?.id && additionalData && (
                      <div className="border-t border-gray-200 dark:border-white/40">
                        {additionalData && additionalData.length > 0 ? (
                          additionalData.map((addItem, idx) => (
                            <>
                              {!childActions ? (
                                <div
                                  key={idx}
                                  className="hover:bg-gray-100 dark:hover:bg-white/10 cursor-pointer flex text-justify border-b border-gray-300"
                                >
                                  <div className="w-[10%] p-5 text-gray-500"></div>
                                  <div className="p-5 w-[80%]">
                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                      {addItem.content}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  key={idx}
                                  className="hover:bg-gray-100 dark:hover:bg-white/10 cursor-pointer flex text-justify border-b border-gray-300"
                                >
                                  <div className="w-[10%] p-5 text-gray-500"></div>
                                  <div className="p-5 w-[68%]">
                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                      {addItem.content}
                                    </div>
                                  </div>
                                  <div
                                    className="p-5 w-[15%]"
                                    onClick={(event) =>
                                      idx === "actions"
                                        ? event.stopPropagation()
                                        : null
                                    }
                                  >
                                    <div className="flex items-center gap-3 text-gray-500 max-w-md">
                                      {userPermission(
                                        "Delete Backlink Content"
                                      ) ? (
                                        <TrashIcon
                                          onClick={() =>
                                            deleteContent(addItem.id)
                                          }
                                          className="w-5 cursor-pointer"
                                        />
                                      ) : null}
                                      {userPermission(
                                        "Edit Backlink Content"
                                      ) ? (
                                        <PencilIcon
                                          onClick={() =>
                                            handleUpdateContent(addItem)
                                          }
                                          className="w-5 cursor-pointer"
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ))
                        ) : (
                          <div className="hover:bg-gray-100 dark:hover:bg-white/10 cursor-pointer flex border-b border-gray-300 text-center">
                            <div className="w-[10%] p-3 text-gray-500"></div>
                            <div className="p-3 w-[80%]">
                              <div className="text-sm">No Records</div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              {isLoading && (
                <div className="flex items-center justify-center py-10 w-full">
                  <img src="/img/loading.gif" className="w-16" alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {totalRecords && (
        <div className="flex flex-col md:flex-row items-center justify-between mt-3">
          <div className="hidden md:flex item-center">
            Total Records: {totalRecords}
          </div>
          {startIndex && (
            <div className="flex items-center justify-end gap-2">
              {handlePerPageChange && (
                <select
                  className="inputField mr-6 px-2 py-1 dark:bg-transparent w-full md:w-fit dark:border-white/30 cursor-pointer rounded-md"
                  value={itemsPerPage}
                  onChange={handlePerPageChange}
                >
                  <option className="dark:bg-blue">25</option>
                  <option className="dark:bg-blue">50</option>
                  <option className="dark:bg-blue">100</option>
                  <option className="dark:bg-blue">200</option>
                  <option className="dark:bg-blue">300</option>
                  {enableAllRecords ? (
                    <option className="dark:bg-blue" value={totalRecords}>
                      All Records
                    </option>
                  ) : null}
                </select>
              )}
              {startIndex} - {lastIndex} of {totalRecords}
              {toFirstPage && (
                <button
                  type="button"
                  className="pagination ml-5"
                  onClick={toFirstPage}
                  disabled={previousPageDisabled}
                >
                  <FirstPage />
                </button>
              )}
              {toPreviousPage && (
                <button
                  type="button"
                  className="pagination"
                  onClick={toPreviousPage}
                  disabled={previousPageDisabled}
                >
                  <KeyboardArrowLeft />
                </button>
              )}
              {toNextPage && (
                <button
                  type="button"
                  className="pagination"
                  onClick={toNextPage}
                  disabled={nextPageDisabled}
                >
                  <KeyboardArrowRight />
                </button>
              )}
              {toLastPage && (
                <button
                  type="button"
                  className="pagination"
                  onClick={toLastPage}
                  disabled={nextPageDisabled}
                >
                  <LastPage />
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DataTable;
