import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown";
import { menuItems } from "./Menu";
import { BiLogOut } from "react-icons/bi";
import { setLoggedInUser } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const Sidebar = ({ show_navlabel }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const { user } = useSelector((state) => state.auth);
  const isAllow = (item) => {
    if (user?.permissions?.some((obj) => obj === item?.label)) {
      return true;
    } else {
      return false;
    }
  };
  const sideMenu = Object.keys(menuItems).reduce(
    (acc, key) => {
      const menuItem = menuItems[key];
      if (user?.permissions?.some((obj) => obj === menuItem?.label)) {
        acc.push({
          ...menuItem,
          key,
        });
      }
      return acc;
    },
    [user]
  );
  const { refreshToken } = localStorage;
  const handleLogout = async () => {
    try {
      axios.get("/api/logout", {
        baseURL: process.env.REACT_APP_PUBLIC_API,
        headers: { refreshToken },
      });
      localStorage.clear();
      dispatch(setLoggedInUser(null));
    } catch (err) {
      console.log("👊 ~ handleLogout ~ err:", err);
    }
  };
  const dispatch = useDispatch();
  const handleMenuClick = (e) => {
    if (user?.user?.merchant_id?.id !== 1) {
      e.preventDefault();
      navigate(`/dashboard`);
      toast.error("No records for domains!");
    }
  };
  return (
    <div className="p-3 pt-24 bg-white dark:bg-blue dark:text-white/80 shadow-r dark:shadow-gray-800">
      <div className="flex flex-col justify-between h-full">
        <div>
          {sideMenu.map((item, key) => {
            const { label, to, Icon, children } = item;
            if (!isAllow(item)) {
              return null;
            }
            const isCurrentPath = currentPath.endsWith(to);
            return (
              <div key={key} className="sidebar-item">
                {item?.children ? (
                  <Dropdown
                    show_navlabel={!!show_navlabel}
                    label={label}
                    to={to}
                    Icon={Icon}
                    options={children}
                  />
                ) : (
                  <Link
                    className={`pageLink capitalize ${
                      isCurrentPath
                        ? show_navlabel
                          ? "bg-secondary hover:bg-secondary text-white"
                          : "flex items-center justify-center"
                        : show_navlabel
                        ? "dark:hover:text-white hover:bg-blue/10 dark:hover:bg-white/10"
                        : "flex items-center justify-center "
                    }`}
                    title={label}
                    to={to}
                    onClick={to === "/domains" ? handleMenuClick : null}
                  >
                    <Icon className="w-4" />
                    {show_navlabel && <span className="ml-3">{label}</span>}
                  </Link>
                )}
              </div>
            );
          })}
        </div>

        <div>
          <Link
            onClick={handleLogout}
            className={
              show_navlabel
                ? "text-red-400 pageLink hover:bg-blue/10 dark:hover:bg-white/10"
                : "text-red-400 pageLink flex items-center justify-center"
            }
          >
            <BiLogOut />
            {show_navlabel && <span className="ml-3">Logout</span>}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
