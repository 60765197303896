import React, { useEffect, useState } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import api from "../../services/api";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { useDispatch } from "react-redux";
import { domainReset } from "../../store/slices/taskPrevDomainSlice";
import { FaPersonCirclePlus } from "react-icons/fa6";
import userPermission from "../../utils/userPermission";
import MUIDataTable from "../../components/MUIGrid";
export default function LinkBuildersReport() {
  const dispatch = useDispatch();
  const [LinkBuilders, setLinkBuilders] = useState([]);
  const [linkBuilderStatus, setLinkBuilderStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role_id, setRoleId] = useState("");

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [{ field: "is_active", op: "eq", data: 1 }],
  });

  const linkBuilderOptionStatus = [
    { value: 1, label: "Active" },
    { value: 0, label: "In Active" },
  ];
  const get_user = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("/api/users/users_report", payload);
      if (res.status === 200) {
        setLinkBuilders(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  // const fetchLinkBuildersList = () => {
  //   setIsLoading(true);
  //   api
  //     .get(`/api/linkbuilder_user/`)
  //     .then((res) => setLinkBuilders(res.data))
  //     .catch((err) => console.log(err.data.message))
  //     .finally(() => setIsLoading(false));
  // };

  useEffect(() => {
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/roles`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    get_user({
      filters: queryOptions,
      page: 1,
      perPage: 1000,
    });
    dispatch(domainReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserTask = (userId, filter, event) => {
    const url = `/settings/link-builders/${userId}?filter=${encodeURIComponent(
      filter
    )}`;
    if (event.ctrlKey || event.metaKey) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const handleSearch = async () => {
    let payload = {
      groupOp: "AND",
      rules: [],
    };
    const isActiveValue =
      linkBuilderStatus ||
      queryOptions.rules.find((rule) => rule.field === "is_active")?.data;
    if (isActiveValue !== undefined) {
      payload.rules.push({
        field: "is_active",
        op: "eq",
        data: isActiveValue,
      });
    }

    if (role_id) {
      payload.rules.push({
        field: "role_id",
        op: "eq",
        data: role_id,
      });
    }

    get_user({
      filters: payload,
      page: 1,
      perPage: 1000,
    });
  };

  const handleAssignBatch = (userId) => {
    const batchUrl = `/batches?user_id=${userId}`;
    window.open(batchUrl, "_blank");
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3 flex items-center justify-between">
        <div className="flex">
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 10, filterable: false },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: 80,
    },
    {
      headerName: "Assign Batch",
      field: "assign_batch",
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <div class="flex justify-center items-center h-screen">
          <button
            onClick={() => handleAssignBatch(params.row.uid)}
            className="w-full inline-flex items-center justify-center space-x-2"
          >
            <FaPersonCirclePlus className="w-5 h-5" />
            <span className="bg-[#93c5fd] text-center py-1 px-2 !rounded hover:cursor-pointer ml-1 text-xs mr-2">
              Assign
            </span>
          </button>
        </div>
      ),
    },
    {
      headerName: "Role",
      field: "role",
      minWidth: 200,
      filterable: false,
    },
    {
      headerName: "Username",
      field: "username",
      flex: 1,
      minWidth: 80,
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 250,
    },
    {
      headerName: "Total Tasks",
      field: "totalTask",
      flex: 1,
      type: "number",
      minWidth: 80,
      renderCell: (params) => (
        <button
          onClick={(e) => handleUserTask(params.row.uid, "", e)}
          className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
        >
          {params.row.totalTask}
        </button>
      ),
    },
    {
      headerName: "Pending",
      field: "pending",
      flex: 1,
      minWidth: 80,
      type: "number",
      renderCell: (params) => (
        <button
          onClick={(e) => handleUserTask(params.row.uid, "pending", e)}
          className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
        >
          {params.row.pending}
        </button>
      ),
    },
    {
      headerName: "In Process",
      field: "inProcess",
      flex: 1,
      type: "number",
      minWidth: 80,
      renderCell: (params) => (
        <button
          onClick={(e) => handleUserTask(params.row.uid, "inProcess", e)}
          className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
        >
          {params.row.inProcess}
        </button>
      ),
    },
    {
      headerName: "In Review",
      field: "inReview",
      type: "number",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <button
          onClick={(e) => handleUserTask(params.row.uid, "inReview", e)}
          className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
        >
          {params.row.inReview}
        </button>
      ),
    },
    {
      headerName: "Hold",
      field: "hold",
      type: "number",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <button
          onClick={(e) => handleUserTask(params.row.uid, "hold", e)}
          className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
        >
          {params.row.hold}
        </button>
      ),
    },
    {
      headerName: "Cancelled",
      field: "cancelled",
      type: "number",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <button
          onClick={(e) => handleUserTask(params.row.uid, "cancelled", e)}
          className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
        >
          {params.row.cancelled}
        </button>
      ),
    },
    {
      headerName: "Completed",
      field: "completed",
      type: "number",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <button
          onClick={(e) => handleUserTask(params.row.uid, "completed", e)}
          className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
        >
          {params.row.completed}
        </button>
      ),
    },
    {
      headerName: "ReOpen",
      field: "reOpen",
      type: "number",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <button
          onClick={(e) => handleUserTask(params.row.uid, "reOpen", e)}
          className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
        >
          {params.row.reOpen}
        </button>
      ),
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    assign_batch: userPermission("Assign Batch") ? true : false,
  });

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <div className="px-6 pt-4">
      {isLoading && <Loading />}
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Linkbuilders Tasks Report", link: "" },
        ]}
      />
      <div className="bg-white my-2 border rounded">
        <div className="flex items-center px-2 py-2">
          <select
            disabled={isLoading}
            className="inputField disabled:cursor-not-allowed w-fit py-2"
            value={linkBuilderStatus || 1}
            onChange={(event) => setLinkBuilderStatus(event.target.value)}
          >
            <option key="" value="">
              Select
            </option>
            {linkBuilderOptionStatus.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <select
            value={role_id}
            onChange={(e) => setRoleId(e.target.value)}
            name="role_id"
            className="inputField disabled:cursor-not-allowed w-fit py-2 ml-2"
          >
            <option value="">Select Role</option>
            {roles?.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
          <button className="btnPrimary ml-2" onClick={handleSearch}>
            Search
          </button>
          <button
            onClick={() => {
              setLinkBuilderStatus("");
              setRoleId("");
              get_user({
                filters: {
                  groupOp: "AND",
                  rules: [{ field: "is_active", op: "eq", data: 1 }],
                },
                page: 1,
                perPage: 1000,
              });
            }}
            className="btnPrimary ml-1 bg-gray-500"
          >
            Reset
          </button>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={LinkBuilders?.records?.map((data, index) => {
            const { id, name, role, username, email, task_info } = data;
            const totalTask = (task_info || []).reduce((total, item) => {
              return total + item.totals;
            }, 0);
            const pending =
              task_info?.find(({ task_status }) => task_status === "pending")
                ?.totals || 0;
            const completed =
              task_info?.find(({ task_status }) => task_status === "completed")
                ?.totals || 0;
            const inProcess =
              task_info?.find(({ task_status }) => task_status === "inProcess")
                ?.totals || 0;
            const hold =
              task_info?.find(({ task_status }) => task_status === "hold")
                ?.totals || 0;
            const cancelled =
              task_info?.find(({ task_status }) => task_status === "cancelled")
                ?.totals || 0;
            const inReview =
              task_info?.find(({ task_status }) => task_status === "inReview")
                ?.totals || 0;
            const reOpen =
              task_info?.find(({ task_status }) => task_status === "reOpen")
                ?.totals || 0;
            return {
              records: data,
              counter: index + 1,
              uid: id,
              name,
              role,
              username,
              email,
              totalTask,
              pending,
              inProcess,
              inReview,
              hold,
              cancelled,
              completed,
              reOpen,
              assign_batch: "",
            };
          })}
          CustomToolbar={CustomToolbar}
          totalItems={LinkBuilders?.count}
          height="75vh"
          searchable="No"
          showCount="No"
          paginationMode="client"
          columnVisibilityModel={columnVisibilityModel}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
        />
      </div>
    </div>
  );
}
