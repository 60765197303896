import React from "react";
import dayjs from "dayjs";
import { IconButton } from "@mui/material";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { Badge } from "../../../components";
const TaskListCard = ({ item, expandedRows, handleExpandClick }) => {
  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }
  return (
    <>
      <div
        className={` last:border-none shadow-md  bg-white mb-0"
      }`}
      >
        <div
          className={`grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 py-0 `}
        >
          <div className="flex items-center text-lg gap-3 flex-wrap justify-between">
            <IconButton
              onClick={() => {
                handleExpandClick(item?.backlink_id);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              {expandedRows[item?.backlink_id] ? (
                <>
                  <MdKeyboardArrowDown size={22} />
                  <a
                    href={`https://www.${item?.backlink_domain}`}
                    className="hover:text-secondary  mx-2 text-lg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item?.backlink_domain}
                  </a>{" "}
                  <Badge value={item?.task_details?.length} />
                </>
              ) : (
                <>
                  <MdKeyboardArrowRight size={22} />
                  <a
                    href={`https://www.${item?.backlink_domain}`}
                    className="hover:text-secondary mx-2 text-lg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item?.backlink_domain}
                  </a>
                  <Badge value={item?.task_details?.length} />
                </>
              )}
            </IconButton>
          </div>
        </div>

        {item.isChild ? (
          <>
            <div
              className={`overflow-hidden transition-max-height duration-500 ease-in-out px-2  py-0 max-h-[300px] overflow-y-auto`}
            >
              {item?.task_details.map((task, index) => (
                <div
                  className="grid items-center px-6 py-0 border-b last:border-none"
                  key={index}
                >
                  <div className="flex items-center text-sm gap-3 flex-wrap justify-between"></div>
                  <div className="overflow-hidden transition-max-height duration-500 ease-in-out px-6 max-h-screen py-3 overflow-x-auto">
                    <span className="text-lg font-medium text-gray-600 dark:text-blue-500 hover:underline">
                      Money Site Domain - {task?.domain}
                    </span>
                    <div className="overflow-x-auto">
                      <div className="overflow-x-auto">
                        <div className="flex space-x-12 py-2">
                          {task?.task_log
                            ?.sort(
                              (a, b) =>
                                new Date(b.timestamp) - new Date(a.timestamp)
                            )
                            .map((item, index) => (
                              <div
                                key={index}
                                className={`flex items-start ${
                                  index !== 0
                                    ? "border-l border-gray-300 pl-4"
                                    : ""
                                }`}
                              >
                                <div className="flex-shrink-0 text-sm">
                                <p className="flex items-baseline">
                                    <b className="text-gray-600 dark:text-white">
                                      Move To:
                                    </b>
                                    <span className="ml-1 text-sm text-gray-500">
                                      {addSpaceBeforeUpperCase(item?.status)}
                                    </span>
                                  </p>
                                  <p className="flex items-baseline">
                                    <b className="text-gray-600 dark:text-white">
                                      Task Date:
                                    </b>
                                    <span className="ml-1 text-sm text-gray-500">
                                      {dayjs(item.timestamp).format(
                                        "ddd, MMM D, YYYY h:mm A"
                                      )}
                                    </span>
                                  </p>
                                  
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default TaskListCard;
