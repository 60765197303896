import React, { useState, useEffect, useRef } from "react";
const Autocomplete = ({
  data,
  idField,
  valueField,
  onQueryChange,
  onSelect,
  placeholder,
  setAutoComLoader,
  isAutoComLoader,
  selectedItem,
  width,
  height,
  extraClass,
}) => {
  const [query, setQuery] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
        setAutoComLoader(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setAutoComLoader]);

  useEffect(() => {
    if (selectedItem === null || selectedItem === "") {
      setQuery("");
    } else if (selectedItem) {
      setQuery(selectedItem[valueField] || "");
    }
  }, [selectedItem, valueField]);

  const handleChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    onQueryChange(newQuery);
    setIsDropdownVisible(true);
    if (newQuery === "") {
      setAutoComLoader(false);
    }
  };

  const handleSelect = (item) => {
    setQuery(item[valueField]);
    onSelect(item);
    setIsDropdownVisible(false);
  };

  return (
    <div className="relative" ref={autocompleteRef}>
      <div className={`flex items-center border rounded-sm overflow-hidden ${width ?? ''} ${height ?? ''} ${extraClass ?? ''} `} >
        <input
          type="text"
          value={query}
          onChange={handleChange}
          placeholder={placeholder || `Type to search...`}
          className="flex-1 p-2 border-none outline-none"
        />
        {isAutoComLoader && (
          <div className="flex items-center p-2">
            <img src="/img/loading.gif" className="w-7" alt="" />
          </div>
        )}
      </div>
      {isDropdownVisible && data?.length > 0 && (
        <ul className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
          {data.map((item) => (
            <li
              key={item[idField]}
              onClick={() => handleSelect(item)}
              className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-200 "
            >
              {item[valueField]}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
