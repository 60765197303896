import React, { useState } from "react";
import { TablePagination } from "@mui/material";
import CustomFilters from "../../../components/common/CustomFilters";
const TaskListHeader = ({
  selectedPayload,
  paginationModel,
  tasks,
  handleTopPageChange,
  handleTopPageSizeChange,
  setSelectedPayload,
  fetchTasksList,
  setExpandedRows,
  setPaginationModel,
  users,
  setQueryOptions,
  queryOptions,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const allTaskStatusOptions = [
    { value: "pending", label: "Pending" },
    { value: "inProcess", label: "In Process" },
    { value: "hold", label: "Hold" },
    { value: "cancelled", label: "Cancelled" },
    { value: "completed", label: "Completed" },
    { value: "inReview", label: "In Review" },
    { value: "reOpen", label: "Reopen" },
  ];

  const handleTaskStatusChange = (e) => {
    const newTaskStatus = e.target.value;
    const filter = {
      groupOp: "AND",
      rules: [
        {
          field: "task_status",
          op: "eq",
          data: e.target.value,
        },
      ],
    };
    setQueryOptions(filter);
    setSelectedPayload((prevState) => ({
      ...prevState,
      task_status: newTaskStatus || "",
    }));
  };

  const handleSearch = async () => {
    setExpandedRows({});
    fetchTasksList({
      ...paginationModel,
      with_static_ip: selectedPayload?.with_static_ip,
      filter: queryOptions,
    });
  };

  const handleToggle = () => {
    setIsChecked((prev) => {
      const newValue = !prev;
      setSelectedPayload((prevState) => ({
        ...prevState,
        with_static_ip: newValue,
      }));
      fetchTasksList({
        ...paginationModel,
        with_static_ip: newValue,
        filter: queryOptions,
      });
      return newValue;
    });
  };

  const handleResetFilters = async () => {
    const filter = {
      groupOp: "AND",
      rules: [
        {
          field: "task_status",
          op: "eq",
          data: "hold",
        },
      ],
    };
    setQueryOptions(filter);
    setExpandedRows({});
    setIsChecked(false);
    setSelectedPayload((prevState) => ({
      ...prevState,
      with_static_ip: false,
    }));
    fetchTasksList({
      ...paginationModel,
      filter: filter,
    });
  };

  const filterColumns = [
    {
      headerName: "Backlink Domain",
      field: "b.backlink_domain",
      type: "string",
    },
    {
      headerName: "Users",
      field: "u.id",
      type: "select",
      valueOptions: users || [],
      label: "name",
      value: "id",
      additionalOperators: [],
    },
    {
      headerName: "Task Status",
      field: "task_status",
      type: "select",
      valueOptions: allTaskStatusOptions || [],
      label: "label",
      value: "value",
      additionalOperators: [],
    },
  ];
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    fetchTasksList({
      page: 1,
      pageSize: paginationModel.pageSize,
      filter: queryOptions,
      with_static_ip: selectedPayload?.with_static_ip,
    });
  };
  const handleFilterChange = (data) => {
    if (!data?.rules.length) {
      setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
      fetchTasksList({
        page: 1,
        pageSize: paginationModel.pageSize,
        filter: null,
        with_static_ip: selectedPayload?.with_static_ip,
      });
    }
    setQueryOptions({ ...data });
  };
  return (
    <>
      <div className="bg-white my-2 border rounded">
        <div className="flex flex-wrap items-center px-2 py-2">
          <label className="flex items-center relative w-max cursor-pointer select-none mt-0 ml-8 mr-2">
            <span className="mr-2 font-medium">Static IP :</span>
            <input
              type="checkbox"
              id="good_to_assign"
              checked={isChecked}
              onChange={handleToggle}
              className="appearance-none transition-colors cursor-pointer w-14 h-6 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500 checked:bg-green-500"
            />
            <span className="absolute font-medium text-xxs uppercase right-1 text-white">
              No
            </span>
            <span className="absolute font-medium text-xxs uppercase right-8 text-white">
              Yes
            </span>
            <span
              className={`w-6 h-6 right-7 absolute rounded-full transform transition-transform bg-gray-200 ${
                isChecked ? "translate-x-7" : "translate-x-0"
              }`}
            />
          </label>
     {/*      <select
            className="inputField disabled:cursor-not-allowed w-fit py-2 ml-6"
            value={
              queryOptions?.rules.find((rule) => rule.field === "task_status")
                ?.data || "hold"
            }
            onChange={handleTaskStatusChange}
          >
            <option value="">Select Status</option>
            {allTaskStatusOptions?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <button className="btnPrimary ml-2" onClick={handleSearch}>
            Search
          </button>
          <button
            className="btnPrimary ml-1 bg-gray-500 mr-2"
            onClick={handleResetFilters}
          >
            Reset
          </button> */}
          <CustomFilters
            handleFilterChange={handleFilterChange}
            handleNewFilter={handleNewFilter}
            filterModel={queryOptions}
            columns={filterColumns}
          />
          <div className="ml-auto mt-2 md:mt-0">
            <TablePagination
              color="primary"
              shape="rounded"
              size="medium"
              showFirstButton
              showLastButton
              count={tasks?.data?.length || 0}
              page={paginationModel.page - 1 || 0}
              onPageChange={(e, page) => handleTopPageChange(page)}
              rowsPerPage={paginationModel.pageSize || 0}
              onRowsPerPageChange={(e) =>
                handleTopPageSizeChange(e.target.value)
              }
              component="div"
              rowsPerPageOptions={[
                25,
                50,
                75,
                100,
                250,
                500,
                1000,
                1500,
                2000,
                {
                  label: "All",
                  value: tasks?.data?.length || 0,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskListHeader;
