import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { DateRange, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { BsArrowRight, BsCalendarDate } from "react-icons/bs";
import { FaRegClock } from "react-icons/fa";

const DateRangeCustomComp = (props) => {
  const {
    inputClassName,
    pickerClassName,
    onChange,
    dateRange,
    dateRangePicker,
    onPreviewChange,
    button,
    mainClass,
    value,
    format,
    startDate,
    endDate,
  } = props;

  const [range, setRange] = useState([
    {
      startDate: startDate ? startDate : new Date(),
      endDate: endDate ? endDate : new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (value) {
      setRange(value);
    }
  }, [value]);

  const [open, setOpen] = useState(false);
  const refOne = useRef(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setRange([
        {
          startDate: dayjs(startDate).toDate(),
          endDate: dayjs(endDate).toDate(),
          key: "selection",
        },
      ]);
    }
  }, [startDate, endDate]);

  // Handle date change when range is selected
  const handleDateChange = (item) => {
    const { startDate, endDate } = item.selection;
    // Adjust to local time and set start and end of the day
    const formattedStartDate = dayjs(startDate)
      .startOf("day")
      .local()
      .toISOString();
    const formattedEndDate = dayjs(endDate).endOf("day").local().toISOString();
    setRange([item.selection]);
    onChange({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  return (
    <div className={`inline-block relative cursor-pointer w-full ${mainClass}`}>
      {button ? (
        <button
          className="flex flex-row inline-bock justify-center items-center py-1 px-2 whitespace-nowrap boxShadow-datePicker border border-[#d1dbe8d9] h-[40px] font-semibold text-base"
          type="button"
          onClick={() => setOpen(!open)}
        >
          {`${dayjs(range[0].startDate).format(format ? format : "MMM D YY")}`}
          <span className="flex items-center justify-center">
            <BsArrowRight className="mx-1 text-base" />
          </span>
          {`${dayjs(range[0].endDate).format(format ? format : "MMM D YY")}`}
          <span className="flex items-center justify-center">
            <BsCalendarDate
              className="mx-1 text-base text-[#a4b8d1]"
              size={18}
            />
          </span>
          <span className="flex items-center justify-center">
            00:00
            <span className="flex items-center justify-center">
              <BsArrowRight className="mx-1 text-base" />
            </span>
            24:00
            <span className="flex items-center justify-center">
              <FaRegClock className="mx-1 text-base text-[#a4b8d1]" size={18} />
            </span>
          </span>
        </button>
      ) : (
        <input
          value={`${dayjs(range[0].startDate).format(
            format ? format : "DD, MMM YYYY"
          )} - ${dayjs(range[0].endDate).format(
            format ? format : "DD, MMM YYYY"
          )}`}
          readOnly
          className={`${inputClassName} cursor-pointer`}
          onClick={() => setOpen(!open)}
        />
      )}

      <div ref={refOne}>
        {open && dateRangePicker && (
          <DateRangePicker
            onChange={handleDateChange}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className={`left-1/2 absolute z-[999] border border-border-1 top-10 ${pickerClassName}`}
            onPreviewChange={onPreviewChange}
          />
        )}
        {open && dateRange && (
          <DateRange
            onChange={(item) => {
              setRange([item.selection]);
              onChange(item);
            }}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className={`left-1/2 absolute z-[999] border border-border-1 top-10 ${pickerClassName}`}
          />
        )}
      </div>
    </div>
  );
};

export default DateRangeCustomComp;
