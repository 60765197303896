import React, { useState, useRef } from "react";
import Modal from "../common/Modal";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
function SourceDomainModal({ domains, setOpen, open }) {
  const sourceDomain = domains?.split(",");
  const [searchText, setSearchText] = useState("")
  const sourceDomains = sourceDomain?.filter(
    (domain) =>domain?.toLowerCase().includes(searchText.toLowerCase())
  );
  // Modal
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Modal
        open={false}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        title={
          <>
            Source Domains List
            <span className="px-4 py-2 ml-5 rounded-full bg-gray-200 text-base font-medium">
              Total = {sourceDomains?.length}
            </span>
          </>
        }
        className="max-w-5xl h-[calc(100vh-300px)]"
      >
        <div className="flex w-full justify-end items-center !mb-2">
        <div className={`relative w-72 border border-gray-300 rounded`}>
          <div className="flex items-center w-full appearance-none px-3 flex-1">
            <MagnifyingGlassIcon className="w-5" />
            <input
              placeholder="Search.."
              className="bg-transparent flex-1 w-full outline-none ml-3 py-2 appearance-none"
              onChange={(e)=>setSearchText(e.target.value)}
            />
          </div>
        </div>
        </div>
        <div className="overflow-y-scroll max-h-[calc(100vh-300px)] md:max-h-[calc(100vh-180px)] flex items-center justify-center">
          {sourceDomains?.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-x-2 w-full">
              {sourceDomains?.map((domain, index) => (
                <a
                  key={index}
                  className="py-2 hover:px-5 transition-all overflow-hidden text-left border-b hover:rounded-md hover:bg-gray-200"
                href={domain}
                target="_blank"
                rel="noreferrer"
                >
                  {domain}
                </a>
              ))}
            </div>
          ) : (
            <p className="py-16 text-center">
              No Source Domains List
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default SourceDomainModal;
