import React, { useEffect, useRef, useState } from "react";
import { DataTable, Modal, ToolTip } from "../../components";
import { TrashIcon } from "@heroicons/react/24/solid";
import { removeDomainFromBatch } from "../../store/slices/selectedBatchesSlice"; // Adjust the import path
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import AssignedBacklinksList from "./AssignedBacklinksList";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";

export default function DomainBatchesList() {
  const [showAllDomains, setShowAllDomains] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedBatches, setSelectedBatches] = useState(
    location?.state?.selectedBatches
  );

  const fetchUsers = () => {
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/linkbuilder_user/`)
      .then((response) => {
        setUsers(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedBatches?.length === 0) {
      navigate("/batches");
    }
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const handleShowAll = (batchIndex) => {
    setShowAllDomains((prev) => (prev === batchIndex ? null : batchIndex));
  };

  const handleDeleteDomain = (batchIndex, domainIndex) => {
    removeDomainFromBatch({ batchIndex, domainIndex });
  };

  const handleConfirm = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let prevData = [...selectedBatches];
    prevData = prevData?.map((item) => {
      if (item.title === name) {
        return {
          ...item,
          user_id: value,
        };
      }
      return item;
    });
    setSelectedBatches(prevData);
  };

  const handleChangeDescription = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    let prevData = [...selectedBatches];
    prevData = prevData?.map((item) => {
      if (item.title === name) {
        return {
          ...item,
          description: value,
        };
      }
      return item;
    });

    setSelectedBatches(prevData);
  };

  const handleSaveSingleBatch = async (batch, batchIndex) => {
    setIsLoading(batchIndex);
    try {
      const modifiedData = batch?.map((item) => {
        const domainsArray = item.domains.map((domain) => domain["d.id"]);
        const backlinksArray = item.backlinks.map(
          (backlink) => backlink["b.id"]
        );
        const { title, ...newItem } = item;
        return { ...newItem, domains: domainsArray, backlinks: backlinksArray };
      });

      const res = await api.post("api/domains/batches", modifiedData);

      if (res.status === 201) {
        // Batch saved successfully, now remove it from selectedBatches
        const updatedSelectedBatches = [...selectedBatches];
        updatedSelectedBatches.splice(batchIndex, 1); // Remove the batch at batchIndex
        setSelectedBatches(updatedSelectedBatches);
        toast.success("Batch created successfully");
        navigate(`/batches`);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      console.log("🚀 ~ handleSaveSingleBatch ~ err:", err);
      toast.error(err.response?.data?.message || "Failed to create batch");
    } finally {
      setIsLoading(null);
      if (selectedBatches?.length === 0) {
        navigate(`/batches`);
      }
    }
  };

  // Modal
  const [modalType, setModalType] = useState("");
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  // Total Selected Backlinks
  const [totalSelectedBacklinks, setTotalSelectedBacklinks] = useState([]);
  const handleTotalSelectedBacklinks = (backlinks) => {
    setTotalSelectedBacklinks(backlinks);
    setModalType("totalAssignedBacklinks");
    handleModal();
  };

  // Assigned Backlinks
  const [selectedBatch, setSelectedBatch] = useState([]);
  const handleAssignBacklinks = (batch) => {
    setSelectedBatch(batch);
    setModalType("assignedBacklinksList");
    handleModal();
  };
  return (
    <div className="p-6">
      <ToastContainer />
      {selectedBatches?.map((batch, batchIndex) => {
        return (
          <div key={batchIndex} className="mb-8 pr-2">
            <h2 className="font-bold text-xl border-b dark:border-white/20">
              {batch?.title}
            </h2>
            <div className="flex items-end justify-between mt-4  dark:text-white pr-2">
              <div className="flex flex-row items-center w-full space-x-2">
                <select
                  value={batch?.user_id}
                  onChange={(e) => handleConfirm(e)}
                  name={batch?.title}
                  className="inputField w-[300px] h-[40px] text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md"
                >
                  <option value="" className="dark:bg-blue">
                    Select User
                  </option>
                  {users.map((item, index) => (
                    <option
                      key={index}
                      value={item?.id}
                      className="dark:bg-blue"
                    >
                      {item?.name.replace(/\b\w/g, (c) => c.toUpperCase())}
                    </option>
                  ))}
                </select>
                <textarea
                  onChange={(e) => handleChangeDescription(e)}
                  value={batch?.description}
                  className="bg-white border border-gray-300 dark:bg-dark dark:border-gray-600 text-sm rounded w-full max-w-[300px] p-2.5 "
                  placeholder={`${batch?.title} description`}
                  rows={1}
                  name={batch?.title}
                />
              </div>
              <div className="flex items-center space-x-2 justify-end w-full">
                <ToolTip
                  tool={
                    <button
                      onClick={() =>
                        handleTotalSelectedBacklinks(batch?.backlinks)
                      }
                      disabled={batch?.backlinks?.length === 0}
                      className="btnPrimary bg-gray-800"
                    >
                      Total Selected Backlinks
                      {batch?.backlinks?.length > 0 ? (
                        <small className="flex w-4 h-4 p-0.5 rounded-full bg-white ml-2 items-center justify-center text-black text-xs">
                          {batch?.backlinks?.length}
                        </small>
                      ) : null}
                    </button>
                  }
                  tip={
                    batch?.backlinks?.length === 0 &&
                    "Disabled! no backlinks assigned yet"
                  }
                />

                <button
                  onClick={() => handleAssignBacklinks(batch)}
                  className="btnSecondary flex flex-row items-center"
                >
                  Assign Backlinks
                </button>
                {isLoading === batchIndex ? (
                  <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
                    <img src="/img/loading.gif" className="w-7" alt="" />
                  </div>
                ) : (
                  <button
                    onClick={() => handleSaveSingleBatch([batch], batchIndex)}
                    className="btnPrimary bg-green-600"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>

            <DataTable
              tableHeight={
                showAllDomains === batchIndex
                  ? "max-h-[300px]"
                  : "max-h-[150px]"
              }
              heads={["Sr#", "Domain", "Backlinks", "action"]}
              items={batch?.domains
                ?.slice(
                  0,
                  showAllDomains === batchIndex ? batch?.domains?.length : 5
                )
                .map((domain, domainIndex) => ({
                  serialNumber: domainIndex + 1,
                  domain: domain["d.domain"],
                  backlinks_count: domain?.backlinks_count,
                  action: (
                    <TrashIcon
                      className="w-5 text-red-500 cursor-pointer"
                      onClick={() =>
                        handleDeleteDomain(batchIndex, domainIndex)
                      }
                    />
                  ),
                }))}
            />

            <button
              onClick={() => handleShowAll(batchIndex)}
              className="mt-3 text-xs font-semibold cursor-pointer"
            >
              {showAllDomains === batchIndex ? "See Less <<" : "See More >>"}
            </button>
          </div>
        );
      })}

      {modalType === "assignedBacklinksList" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="modal max-w-screen-xl"
          title={`Assign Backlinks to ${selectedBatch?.title}`}
        >
          <AssignedBacklinksList
            setSelectedBatches={setSelectedBatches}
            setSelectedBatch={setSelectedBatch}
            selectedBatches={selectedBatches}
            selectedBatch={selectedBatch}
            handleModal={handleModal}
          />
        </Modal>
      )}

      {modalType === "totalAssignedBacklinks" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-screen-xl"
          title={`Assign Backlinks to ${selectedBatch?.title}`}
        >
          <DataTable
            tableHeight="h-[69vh]"
            className="px-0 py-0 mt-0"
            heads={[
              "Sr#",
              "backlink domain",
              "backlink location type",
              "backlink rank",
              "blacklisted",
              "city",
              "domain rating",
              "intersect count",
              "robot group",
              "signup url",
              "source domains list",
              "state",
              "type",
              "created at",
              "updated at",
            ]}
            items={totalSelectedBacklinks?.map((row, index) => ({
              // id: row["b.id"],
              serialNumber: index + 1,
              backlink_domain: (
                <a
                  href={`https://www.${row["b.backlink_domain"]}`}
                  className="hover:text-secondary underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {row["b.backlink_domain"]}
                </a>
              ),
              backlink_location_type: row["b.backlink_location_type"],
              backlink_rank: row["b.backlink_rank"],
              blacklisted: row["b.blacklisted"],
              city: row.city,
              domain_rating: row["b.domain_rating"],
              intersect_count: row["intersect_count"],
              robot_group: row["b.robot_group"],
              signup_url: row["b.signup_url"],
              source_domains_list: (
                <ul>
                  {row?.["b.source_domains_list"]
                    ?.split(",")
                    .map((link, idx) => (
                      <li key={idx}>
                        <a
                          href={link}
                          className="hover:text-secondary underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {link}
                        </a>
                      </li>
                    ))}
                </ul>
              ),
              state: row.state,
              type: row["b.type"],
              created_at: dayjs(row?.created_date).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
              updated_at: dayjs(row?.updated_date).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
            }))}
          />
        </Modal>
      )}
    </div>
  );
}
