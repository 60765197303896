import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton, TablePagination } from "@mui/material";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import FullPagePopup from "./fullPagePopup";
import { Modal } from "../../components";
const DomainsReport = () => {
  const [domainReport, setDomainReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });

  const [sortingModel, setSortingModel] = React.useState({
    sortField: null,
    sortOrder: null,
  });
  const getDomainList = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("/api/domains", payload);
      if (res.status === 200) {
        setDomainReport(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getDomainList({
      ...sortingModel,
      ...paginationModel,
      perPage: paginationModel.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getDomainList({
      ...sortingModel,
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getDomainList({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getDomainList({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    getDomainList({
      ...sortingModel,
      filters: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    getDomainList({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };

  const handleSortModelChange = (params) => {
    setSortingModel({
      sortField: params[0]?.field,
      sortOrder: params[0]?.sort ? params[0]?.sort.toUpperCase() : "default",
    });

    getDomainList({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
      sortField: params[0]?.field,
      sortOrder: params[0]?.sort ? params[0]?.sort.toUpperCase() : "default",
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
        </div>
        <div className="flex items-center">
          <TablePagination
            color="primary"
            shape="rounded"
            size="medium"
            showFirstButton
            showLastButton
            count={
              domainReport?.count
                ? domainReport?.count
                : domainReport?.domainListData?.length || 0
            }
            page={paginationModel.page - 1 || 0}
            onPageChange={(e, page) => handleTopPageChange(page)}
            rowsPerPage={paginationModel.pageSize || 100}
            onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
            component="div"
            rowsPerPageOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              2000,
              {
                label: "All",
                value: domainReport?.count
                  ? domainReport?.count
                  : domainReport?.domainListData?.length || 0,
              },
            ]}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  const [idsArray, setIdsArray] = useState([]);
  const TagsCellRenderer = (data) => {
    const values = idsArray?.includes(data?.row?._id)
      ? data?.value
      : data?.value?.slice(0, 1);
    const tags = values?.map((tag, index) => (
      <span
        key={tag._id}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {index + 1}. {tag}
      </span>
    ));

    return (
      <div className="flex flex-col">
  <div className="flex flex-col w-auto">{tags}</div>
  {data?.value?.length > 1 ? (
    !idsArray?.includes(data?.row?._id) ? (
      <div
        className="flex flex-col w-auto ml-3 text-[#1d4ed8] cursor-pointer hover:text-blue-700 focus:text-blue-700"
        onClick={() => setIdsArray([...idsArray, data?.row?._id])}
      >
        {`See More (${data?.value?.length - 1})`}
      </div>
    ) : (
      <div
        className="flex flex-col w-auto ml-3 text-[#1d4ed8] cursor-pointer hover:text-blue-700 focus:text-blue-700"
        onClick={() =>
          setIdsArray((preArray) => {
            if (!preArray || !data?.row?._id) return preArray;
            return preArray.filter((id) => id !== data.row._id);
          })
        }
      >
        {`See Less`}
      </div>
    )
  ) : null}
</div>

    );
  };

  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 10, filterable: false },
    {
      headerName: "Domain",
      field: "d.domain",
      width: 250,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row["d.domain"]}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row["d.domain"]}
          </a>
        );
      },
    },
    {
      headerName: "Total Tasks",
      field: "backlinks_count",
      type: "number",
      minWidth: 100,
      renderCell: (params) =>
        params?.row.backlinks_count > 0 ? (
          <button
            onClick={() =>
              openPopup(params?.row["b.id"], params.row["d.domain"])
            }
          >
            <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
              {params?.row.backlinks_count}
            </span>
          </button>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "dd.total_pending",
      headerName: "Total Pending",
      minWidth: 120,
      editable: false,
      type: "number",
      renderCell: (params) =>
        params?.row["dd.total_pending"] > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row["dd.total_pending"]}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "dd.total_inProcess",
      headerName: "Total In Process",
      minWidth: 130,
      editable: false,
      type: "number",
      renderCell: (params) =>
        params?.row["dd.total_inProcess"] > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row["dd.total_inProcess"]}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "dd.total_completed",
      headerName: "Total In Completed",
      minWidth: 130,
      editable: false,
      type: "number",
      renderCell: (params) =>
        params?.row["dd.total_completed"] > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row["dd.total_completed"]}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "dd.total_hold",
      headerName: "Total Hold",
      minWidth: 120,
      editable: false,
      type: "number",
      renderCell: (params) =>
        params?.row["dd.total_hold"] > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row["dd.total_hold"]}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "dd.total_inReview",
      headerName: "Total In Review",
      minWidth: 120,
      editable: false,
      type: "number",
      renderCell: (params) =>
        params?.row["dd.total_inReview"] > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row["dd.total_inReview"]}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "dd.total_cancelled",
      headerName: "Total Cancelled",
      minWidth: 120,
      editable: false,
      type: "number",
      renderCell: (params) =>
        params?.row["dd.total_cancelled"] > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row["dd.total_cancelled"]}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      headerName: "First Name",
      field: "d.fname",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Last Name",
      field: "d.lname",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Email",
      field: "d.email",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Phone",
      field: "d.phone",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Assigned Users",
      field: "dd.assigned_user_names",
      renderCell: TagsCellRenderer,
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
    },
    {
      headerName: "Industry",
      field: "i.industry_name",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "State",
      field: "d.state",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "City",
      field: "d.city",
      flex: 1,
      minWidth: 100,
    },
  ];

  const dataRows = (domainReport?.domainListData || [])?.map(
    (record, index) => {
      const counter = index + 1;
      const records = record;
      const {
        "d.id": id,
        "d.domain": backlink_domain,
        backlinks_count,
        "d.fname": fname,
        "d.lname": lname,
        "d.email": email,
        "d.phone": phone,
        "i.industry_name": industry_name,
        "d.state": state,
        "d.city": city,
        "d.created_at": created_at,
        "dd.total_pending": total_pending,
        "dd.total_inProcess": total_inProcess,
        "dd.total_completed": total_completed,
        "dd.total_hold": total_hold,
        "dd.total_inReview": total_inReview,
        "dd.total_cancelled": total_cancelled,
        "dd.assigned_user_names": assigned_user_names,
      } = record;
      const detailsArray = assigned_user_names
        ?.split(",")
        .map((detail) => detail.trim());
      return {
        counter,
        records,
        _id: id,
        "b.id": id,
        "d.domain": backlink_domain,
        backlinks_count,
        "d.fname": fname || "",
        "d.lname": lname,
        "d.email": email,
        "d.phone": phone,
        "i.industry_name": industry_name || "",
        "d.state": state || "",
        "d.city": city,
        "d.created_at": created_at,
        "dd.total_pending": total_pending,
        "dd.total_inProcess": total_inProcess,
        "dd.total_completed": total_completed,
        "dd.total_hold": total_hold,
        "dd.total_inReview": total_inReview,
        "dd.total_cancelled": total_cancelled,
        "dd.assigned_user_names": detailsArray,
      };
    }
  );

  const [showPopup, setShowPopup] = useState(false); // State to control the popup visibility
  const showPopupRef = useRef(null);
  const [selectBackLinks, setSelectBackLinks] = useState("");
  const [backlinksData, setBacklinksData] = useState([]);
  const openPopup = (id, domain) => {
    setIsLoading(true);
    setSelectBackLinks(domain);
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/linkbuilder_user/task_info/${id}`,
        {}
      )
      .then((response) => {
        setBacklinksData(response?.data);
        setIsLoading(false);
        setShowPopup(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closePopup = () => {
    setShowPopup(!showPopup);
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    "d.fname": false,
    "d.lname": false,
    "d.email": false,
    "d.phone": false,
    "d.state": false,
    "d.city": false,
  });
  const handleColumnVisibilityChange = (model) => {
    setColumnVisibilityModel(model);
  };

  const getRowHeight = (params) => {
    const rowHeight = 40;
    const cellContentHeight = 0;
    const data = params?.model;
    const items = data["dd.assigned_user_names"] || [];

    const numberOfLines = items.length || 0;
    const addressHeight = rowHeight + numberOfLines * 20;

    if (items.length > 1) {
      if (!idsArray?.includes(data?._id)) {
        return rowHeight + 15;
      }

      const tagHeight = 25;
      const numTags = items.length;

      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }

    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    }

    return rowHeight + cellContentHeight;
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Domains", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          totalItems={domainReport?.count}
          paginationModel={paginationModel}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          gridOptions={getRowHeight}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </div>
      <Modal
        open={!showPopup}
        handleModal={closePopup}
        cancelButtonRef={showPopupRef}
        className="max-w-6xl"
        title={`Total Tasks - ${selectBackLinks}`}
      >
        <FullPagePopup backlinksData={backlinksData} />
      </Modal>
    </div>
  );
};

export default DomainsReport;
