import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import Loading from "../../../components/Loader/Loading";
import MUIDataTable from "../../../components/MUIGrid";
import { FaSearch } from "react-icons/fa";
import DateRangeComp from "../../../components/DateRangePicker";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";

export default function PerformanceReport() {
  const [isRecords, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });

  const taskStatus = [
    { value: "inReview", label: "In Review" },
    { value: "hold", label: "Hold" },
    { value: "reOpen", label: "Reopen" },
    { value: "inProcess", label: "In Process" },
  ];

  const currentDate = new Date();
  // Function to format date as 'YYYY-MM-DD'
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const initialFilters = {
    start_date: formatDate(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      )
    ),
    end_date: formatDate(new Date()),
  };

  const [filters, setFilters] = React.useState(initialFilters);

  const fetchListing = async (filter) => {
    setIsLoading(true);
    let startDate = new Date(filters?.start_date);
    startDate.setDate(startDate.getDate() - 1);
    startDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${startDate
      .getDate()
      .toString()
      .padStart(2, "0")} 18:30:00`;
    let payload = {
      ...filter,
      start_date: startDate,
      end_date: filters?.end_date + " 18:29:59",
      for_leaders: true,
    };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/report/user_performance_report`,
        payload
      );
      setRecords(response.data);
    } catch (error) {
      console.error(`Error fetching  data: ${error}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchListing({
      filters: queryOptions,
    });
    get_user();
  }, []);

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3 flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />

          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
        </div>
      </GridToolbarContainer>
    );
  }

  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 200, filterable: false },
    {
      field: "user_id",
      headerName: "User",
      minWidth: 300,
      flex: 1,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: users,
      valueGetter: (params) => params.row.user_id,
      valueFormatter: (params) => params.value,
      renderCell: (params) => <>{params.row.name}</>,
    },
    {
      field: "task_status",
      headerName: "Task Status",
      width: 100,
      flex: 1,
      editable: true,
      filterable: false,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: taskStatus,
      valueFormatter: (params) => params.value,
    },
    {
      field: "total_task_count",
      headerName: "Total Count",
      filterable: false,
      flex: 1,
      width: 150,
      renderCell: (params) =>
        params?.row.total_task_count > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.total_task_count ? params?.row.total_task_count : "-"}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "cancelled_count",
      headerName: "Cancelled Count",
      width: 150,
      flex: 1,
      filterable: false,
      renderCell: (params) =>
        params?.row.cancelled_count > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.cancelled_count ? params?.row.cancelled_count : "-"}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "completed_count",
      headerName: "Completed Count",
      filterable: false,
      flex: 1,
      width: 150,
      renderCell: (params) =>
        params?.row.completed_count > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.completed_count ? params?.row.completed_count : "-"}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "reOpen_count",
      headerName: "Reopen Count",
      width: 150,
      flex: 1,
      filterable: false,
      renderCell: (params) =>
        params?.row.reOpen_count > 0 ? (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            {params?.row.reOpen_count ? params?.row.reOpen_count : "-"}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
  ];

  const dataRows = (isRecords || [])?.map((record, index) => {
    const counter = index + 1;
    const records = record;
    const {
      user_id,
      name,
      total_task_count,
      cancelled_count,
      completed_count,
      reOpen_count,
    } = record;

    return {
      counter,
      records,
      user_id,
      name,
      total_task_count,
      cancelled_count,
      completed_count,
      reOpen_count,
    };
  });

  const get_user = async () => {
    const filters = {
      groupOp: "AND",
      rules: [
        { field: "is_active", op: "eq", data: 1 },
        { field: "role_id", op: "isAnyOf", data: [8, 10] },
      ],
    };
    try {
      const res = await api.post("/api/users/users_report", {
        filters,
        page: 1,
        perPage: 1000,
      });
      if (res.status === 200) {
        setUsers(res.data?.records);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleNewFilter = () => {
    fetchListing({
      filters: queryOptions,
    });
  };

  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber
      ? "equals"
      : operator === "eq" && isSelect && !isNumber
      ? "is"
      : operator === "eq" && !isSelect && isNumber
      ? "="
      : operator === "not" && !isSelect && isNumber
      ? "!="
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator;
  };
  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });

  const processFilters = (filters) => {
    return (filters || [])?.map((filter) => {
      return filter;
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    const item = processFilters(filterModel?.items || []);
    setFilterModel({
      ...filterModel,
      items: item,
    });
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      fetchListing({ filters: queryOptions });
    }
    // eslint-disable-next-line array-callback-return
    processFilters(filterModel?.items)?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel?.logicOperator?.toUpperCase() || "AND",
      rules: ruless,
    });
    // eslint-disable-next-line
  }, []);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    task_status: false,
  });
  const handleColumnVisibilityChange = (model) => {
    setColumnVisibilityModel(model);
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Leader Performance Report", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="p-3 flex justify-end items-center w-full">
        <div className="flex items-center">
          <DateRangeComp
            inputClassName="!font-medium w-[220px] text-center bg-[#e9ecef] text-sm rounded-l !py-1 !px-2 border border-[#ced4da] !mb-0 rounded-0"
            pickerClassName="!left-1/2 !top-8 -translate-x-[75%]"
            dateRange
            onChange={(e) => {
              // Create new Date objects for start and end dates
              const startDate = new Date(e.selection.startDate);
              const endDate = new Date(e.selection.endDate);
              // Manually format the start date to 'yyyy-mm-dd'
              const formattedStartDate = `${startDate.getFullYear()}-${(
                startDate.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}-${startDate
                .getDate()
                .toString()
                .padStart(2, "0")}`;

              // Manually format the end date to 'yyyy-mm-dd'
              const formattedEndDate = `${endDate.getFullYear()}-${(
                endDate.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}-${endDate
                .getDate()
                .toString()
                .padStart(2, "0")}`;
              setFilters({
                ...filters,
                start_date: formattedStartDate,
                end_date: formattedEndDate,
              });
            }}
            startDate={filters.start_date}
            endDate={filters.end_date}
          />

          <button
            variant="btn_cancel"
            className="h-[31px] !bg-[#042A42]  rounded-r px-3 py-1.5 text-white"
            onClick={() =>
              fetchListing({
                filters: queryOptions,
              })
            }
          >
            <FaSearch size={14} />
          </button>
        </div>
      </div>
      <div className="bg-white my-0 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          CustomToolbar={CustomToolbar}
          height="75vh"
          searchable="No"
          showCount="No"
          pagination="No"
          paginationMode="client"
          totalItems={isRecords?.count}
          onFilterModelChange={onFilterChange}
          columnVisibilityModel={columnVisibilityModel}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
          filterModel={filterModel}
        />
      </div>
    </div>
  );
}
