import React from "react";

const ServiceContent = ({ content }) => {
  if (!content) {
    // If content is null or undefined, return null or some placeholder content
    return null;
  }

  const headingMapping = {
    h1: "service-title text-lg", // Add "text-lg" class for h1 headings
    h2: "service-subtitle",
  };

  const parseContent = (content) => {
    const paragraphsAndHeadings = content.split(/<\/p>|<h[1-6]>/);

    return paragraphsAndHeadings.map((item, index) => {
      const headingRegex = /<h([1-6])>/;
      const headingMatch = item.match(headingRegex);

      if (headingMatch) {
        const headingLevel = headingMatch[1];
        const headingClass = headingMapping[`h${headingLevel}`];

        item = item.replace(
          headingRegex,
          `<h${headingLevel} class="${headingClass}">`
        );
      } else {
        item = `<div class="service-paragraph">${item}</div>`;
      }

      return item;
    });
  };

  const parsedContent = parseContent(content);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: parsedContent.join("") }}
      id="backlink_content"
    />
  );
};

export default ServiceContent;
