import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { gridClasses } from "@mui/x-data-grid-pro";
import api from "../../services/api";
import { Modal } from "../../components";
import MUIDataTable from "../../components/MUIGrid";
import { Dropdown } from "primereact/dropdown";
import Loading from "../../components/Loader/Loading";
import dayjs from "dayjs";
import { Button as MUIButton, TablePagination } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Delete } from "@mui/icons-material";
import { LinearProgress, Box, Button } from "@mui/material";
import userPermission from "../../utils/userPermission";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { useNavigate, Link } from "react-router-dom";
import { MdAddCircleOutline } from "react-icons/md";
import { useLocation } from "react-router-dom";
export default function Batches() {
  const location = useLocation();
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isDataModal, setDataModal] = useState(false);
  const [isDomainData, setDomainData] = useState("");
  const cancelButtonRef1 = useRef(false);
  const [isSelectedBatch, setSelectedBatch] = useState("");
  const [domains, setDomains] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const handleDataModal = () => {
    setDataModal(!isDataModal);
  };

  const get_batches = (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    api
      .post("/api/domains/list_batches", payload)
      .then((response) => {
        setBatches(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const getDomainList = async (filter) => {
    let payload = { page: 1, perPage: 3000 };

    try {
      const res = await api.post("/api/domains", payload);
      if (res.status === 200) {
        setDomains(
          res?.data?.domainListData?.map((d) => ({
            domain_id: d["d.id"],
            domain: d["d.domain"],
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      getDomainList();
      get_batches({
        ...paginationModel,
        perPage: paginationModel.pageSize,
        filters: queryOptions,
      });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const filters = {
      groupOp: "AND",
      rules: [
        { field: "is_active", op: "eq", data: 1 },
        { field: "role_id", op: "isAnyOf", data: [3, 8] },
      ],
    };
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/users/users_report`, {
        filters,
        page: 1,
        perPage: 1000,
      })
      .then((response) => {
        const capitalizedUsers = response.data?.records.map((user) => {
          return {
            ...user,
            name: user.name.replace(/\b\w/g, (c) => c.toUpperCase()),
          };
        });
        setUsers(capitalizedUsers);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const handleAssignUser = ({ batch_id, user_id }) => {
    // Show a confirmation prompt
    const isConfirmed = window.confirm(
      "Are you sure you want to assign this user to the batch?"
    );
    if (!isConfirmed) {
      // If not confirmed, do nothing
      return;
    }
    const payload = {
      batch_id: batch_id,
      user_id: user_id,
    };
    setIsLoading(true);
    api
      .post(`/api/domains/assign_batch_to_user/`, payload)
      .then((response) => {
        if (response.status === 200) {
          get_batches({
            page: paginationModel.page,
            perPage: paginationModel.pageSize,
          });
        }
        toast.success("User assigned successfully");
      })
      .catch((error) => {
        toast.error("User couldn't be assigned");
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  function separateWords(inputString) {
    if (!inputString) return;
    return inputString
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(/(?=[A-Z])/)
      .join(" ");
  }

  const deleteBatch = (batchId) => {
    api
      .delete(`/api/domains/batches/${batchId}`)
      .then((res) => toast.success(res.data.message))
      .catch((res) => toast.error(res.response.data.message))
      .finally(() =>
        get_batches({
          page: paginationModel.page,
          perPage: paginationModel.pageSize,
        })
      );
  };
  const openDataModal = async (domainData, domain, slug) => {
    slug ? setSelectedBatch(domainData) : setSelectedBatch("");
    setIsLoading(true);
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/domains/batch_info/${domainData?.bid}?type=${domain}`
      );
      setDomainData(response?.data);
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
    }
    setDataModal(true);
    setIsLoading(false);
  };
  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 80, filterable: false },
    {
      field: "bd.domain_id",
      headerName: "Domain",
      minWidth: 200,
      type: "singleSelect",
      getOptionValue: (value) => value?.domain_id,
      getOptionLabel: (value) => value.domain,
      valueOptions: domains,
      valueGetter: (params) => params.row.domain_id,
      valueFormatter: (params) => params.value,
    },
    {
      field: "u.id",
      headerName: "Assigned User",
      minWidth: 200,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: users,
      renderCell: (params) =>
        params?.row?.user_id ? (
          <span>{params?.row?.name}</span>
        ) : userPermission("Assign User") ? (
          <Dropdown
            value={params?.row?.user_id}
            onChange={(e) => {
              handleAssignUser({
                batch_id: params?.row?.records?.id,
                user_id: e.value,
              });
            }}
            options={users}
            optionLabel="name"
            optionValue="id"
            placeholder="Select User"
            className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-gray-500 capitalize"
            filter
            panelClassName="dropdown-panel-batches w-[50px]"
          />
        ) : null,
      valueGetter: (params) => params.row.user_id,
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "Status",
      field: "b.status",
      minWidth: 150,
      type: "singleSelect",
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        status: value?.value,
      }),
      renderCell: (params) => <>{separateWords(params.row.status)}</>,
      valueOptions: [
        { value: "Created", label: "Created" },
        { value: "AssignedToUser", label: "Assigned To User" },
        { value: "Completed", label: "Completed" },
      ],
    },
    {
      headerName: "Batch Number",
      field: "b.batch_number",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => <div>{params.row.batch_number}</div>,
    },
    {
      headerName: "Domain Count",
      field: "b.domain_count",
      width: 100,
      flex: 1,
      renderCell: (params) =>
        params?.row.domain_count > 0 ? (
          <span
            className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
            onClick={(e) => openDataModal(params?.row, "domain")}
          >
            {params?.row.domain_count ? params?.row.domain_count : "-"}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      headerName: "Backlink Count",
      field: "b.backlink_count",
      width: 100,
      flex: 1,
      renderCell: (params) =>
        params?.row.backlink_count > 0 ? (
          <span
            className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
            onClick={(e) => openDataModal(params?.row, "backlink", true)}
          >
            {params?.row.backlink_count ? params?.row.backlink_count : "-"}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      headerName: "Completed",
      field: "completePercentage",
      flex: 1,
      minWidth: 120,
      filterable: false,
      renderCell: (params) => {
        const { task_info } = params?.row?.records || {};
        const safeTaskInfo = task_info || [];
        
        const totalSum = safeTaskInfo.reduce((acc, task) => acc + task.totals, 0);
        const completedTask = safeTaskInfo.find(
          (task) => task.task_status === "completed"
        );
        const cancelledTask = safeTaskInfo.find(
          (task) => task.task_status === "cancelled"
        );
    
        const completedPercentage =
          completedTask && totalSum > 0
            ? (
                ((completedTask.totals +
                  (cancelledTask ? cancelledTask.totals : 0)) /
                  totalSum) *
                100
              ).toFixed(2)
            : "0.00";
    
        return completedPercentage ? (
          <Box width="100%">
            <LinearProgress
              variant="determinate"
              value={Number(completedPercentage)}
            />
            {completedPercentage}%
          </Box>
        ) : (
          "0%"
        );
      },
    },
    {
      field: "b.assigned_date",
      headerName: "Assigned Date",
      width: 200,
      editable: false,
      filterable: false,
      renderCell: (params) => <div>{params.row.assigned_date}</div>,
    },
    {
      field: "b.created_at",
      headerName: "Created At",
      width: 200,
      editable: false,
      filterable: false,
      renderCell: (params) => <div>{params.row.created_at}</div>,
    },
    {
      headerName: "Action",
      field: "batch_detail",
      minWidth: 100,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-3">
            {userPermission("Delete Batche") ? (
              <button onClick={() => deleteBatch(params.row.bid)}>
                <Delete className="text-red-400" fontSize="small" />
              </button>
            ) : null}
          </div>
        );
      },
    },
  ];

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_batches({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
          <Link to="/new_batches" style={{ textDecoration: "none" }}>
            <Button
              variant="text"
              startIcon={<MdAddCircleOutline size={16} />}
              sx={{
                borderRadius: "6px",
                marginRight: "4px",
                border: "1px solid",
                borderColor: "#e8eaee",
                height: "26px",
                fontSize: "0.8125rem",
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              Create Batch
            </Button>
          </Link>
        </div>
        <div className="flex items-center">
          <TablePagination
            color="primary"
            shape="rounded"
            size="medium"
            showFirstButton
            showLastButton
            count={
              batches?.count
                ? batches?.count
                : batches?.batchListData?.length || 0
            }
            page={paginationModel.page - 1 || 0}
            onPageChange={(e, page) => handleTopPageChange(page)}
            rowsPerPage={paginationModel.pageSize || 100}
            onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
            component="div"
            rowsPerPageOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              2000,
              {
                label: "All",
                value: batches?.count
                  ? batches?.count
                  : batches?.batchListData?.length || 0,
              },
            ]}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  const getRowClass = (params) => {
    if (params.row.status === "Completed") {
      return "bg-green-200 dark:bg-green-500/40";
    }
  };

  const assignBacklinks = (batchData) => {
    const domainObject = domains?.find(
      (item) => item.domain_id === +queryParams.get("domain_id")
    );

    navigate(`/domains/assign-backlinks`, {
      state: {
        selectedBatch: batchData,
        domain_id: queryParams.get("domain_id") || "",
        selectedDomains: domainObject ? [domainObject] : isDomainData || [],
      },
    });
  };

  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber
      ? "equals"
      : operator === "eq" && isSelect && !isNumber
      ? "is"
      : operator === "eq" && !isSelect && isNumber
      ? "="
      : operator === "not" && !isSelect && isNumber
      ? "!="
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator;
  };
  const [queryOptions, setQueryOptions] = useState({
    groupOp: "AND",
    rules: [
      ...(queryParams.get("user_id")
        ? [
            {
              field: "u.id",
              op: "eq",
              data: parseInt(queryParams.get("user_id")),
            },
          ]
        : []),
      ...(queryParams.get("domain_id")
        ? [
            {
              field: "bd.domain_id",
              op: "eq",
              data: parseInt(queryParams.get("domain_id")),
            },
          ]
        : []),
    ],
  });
  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });

  const processFilters = (filters) => {
    return (filters || [])?.map((filter) => {
      if (
        filter.operator === "isAnyOf" &&
        filter.value?.length &&
        filter.value[0]?.includes(",")
      ) {
        return {
          ...filter,
          value: filter.value[0].split(",").map((item) => item.trim()),
        };
      }
      return filter;
    });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    const item = processFilters(filterModel?.items || []);
    setFilterModel({
      ...filterModel,
      items: item,
    });
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      get_batches({
        page: 1,
        perPage: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    processFilters(filterModel?.items)?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel?.logicOperator?.toUpperCase() || "AND",
      rules: ruless,
    });
    // eslint-disable-next-line
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };

  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_batches({
      filters: queryOptions,
      page: +params.page + 1,
      perPage: params.pageSize,
    });
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;

  const dataRows = (batches?.batchListData || []).map((record, index) => {
    const counter = offset + index + 1;
    const {
      "b.id": bid,
      "bd.domain_id": domain_id,
      "b.batch_number": batch_number,
      "b.domain_count": domain_count,
      "b.backlink_count": backlink_count,
      "b.status": status,
      "b.assigned_date": assigned_date,
      "b.created_at": created_at,
      "u.name": name,
      "u.id": user_id,
      task_info,
    } = record;

    const formattedAssignedDate = assigned_date
      ? dayjs(assigned_date).format("ddd, MMM D, YYYY h:mm A")
      : "Not Assigned";
    const formattedCreatedAt = dayjs(created_at).format(
      "ddd, MMM D, YYYY h:mm A"
    );

    return {
      records: record,
      counter,
      batch_number,
      domain_count,
      backlink_count,
      name,
      status,
      user_id,
      assigned_date: formattedAssignedDate,
      created_at: formattedCreatedAt,
      task_info,
      bid,
      domain_id,
    };
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    "bd.domain_id": false,
  });
  const handleColumnVisibilityChange = (model) => {
    setColumnVisibilityModel(model);
  };

  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    get_batches({
      filters: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    get_batches({
      filters: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Batches", link: "" },
        ]}
      />
      <ToastContainer />

      <div className="bg-white my-3 border rounded">
        {isLoading && <Loading />}
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          totalItems={batches?.count}
          searchText={searchText}
          setSearchText={setSearchText}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          CustomToolbar={CustomToolbar}
          height="80vh"
          searchable="No"
          getRowClass={getRowClass}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: "none",
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: "none",
              },
          }}
          showCount="No"
          onFilterModelChange={onFilterChange}
          filterModel={filterModel}
          columnVisibilityModel={columnVisibilityModel}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
        />
      </div>

      <Modal
        open={!isDataModal}
        handleModal={handleDataModal}
        cancelButtonRef={cancelButtonRef1}
        className="max-w-2xl  min-h-[90vh]"
        title={
          isDomainData && isDomainData[0]?.domain ? "Domains" : "Backlinks"
        }
      >
        {isSelectedBatch ? (
          <div className="flex flex-row items-center w-full space-x-2">
            <button
              className="btnPrimary bg-green-600 ml-auto"
              onClick={(e) => {
                assignBacklinks(isSelectedBatch);
              }}
            >
              Add Backlink
            </button>
          </div>
        ) : null}
        <div className="lg:grid-cols-1 grid gap-3 border-b dark:bg-white/10 dark:rounded-md font-bold mt-0">
          <div className="flex items-center py-1 pr-5 justify-between"></div>
        </div>
        <div className="lg:grid-cols-1 grid grid-cols-1 gap-3 dark:text-white/70 mt-1">
          <div className="flex flex-col h-96 overflow-y-auto pt-1 border border-gray-200 border-t-0 text-sm">
            {isDomainData &&
              isDomainData[0]?.domain &&
              isDomainData.map((item, index) => (
                <p key={index} className="px-1 flex items-center">
                  <span className="text-left pr-2">{index + 1}.</span>{" "}
                  {item.domain}
                </p>
              ))}
            {isDomainData &&
              isDomainData[0]?.backlink_domain &&
              isDomainData.map((item, index) => (
                <p key={index} className="px-1 flex items-center">
                  <span className="text-left pr-2">{index + 1}.</span>{" "}
                  {item.backlink_domain}
                </p>
              ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}
