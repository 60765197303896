import React, { useMemo, useEffect, useState } from "react";
import api from "../../../services/api";
import { toast, ToastContainer } from "react-toastify";
import userPermission from "../../../utils/userPermission";
import {
  ClipboardDocumentIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import ServiceContent from "../../GroupList/ServiceContent";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import TaskStatus from "../../TaskList/TaskDetail/TaskStatus";
import Notes from "../../TaskList/TaskDetail/Notes";
import { BsBrowserChrome } from "react-icons/bs";
import { osName } from "react-device-detect";
import axios from "axios";
import URLModal from "../../TaskList/TaskDetail/URLModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsBrowserActive,
  setIsBrowserDomain,
  setLoggedInUser,
} from "../../../store/slices/authSlice";
//import VerifyModal from "./VerifyModal";
import Loading from "../../../components/Loader/Loading";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import { FaExternalLinkAlt } from "react-icons/fa";
import CustomPagination from "../../../components/common/CustomPagination";
import {
  get_realtime_mail,
  mailBoxReset,
  setTaskEmail,
} from "../../../store/slices/mailboxRealTimeSlice";
import { MdCopyAll, MdDownload } from "react-icons/md";
import dayjs from "dayjs";
import CustomTopPagination from "./CustomTopPagination";

export default function TaskDetail() {
  const location = useLocation();
  const [taskDetail, setTaskDetail] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("");
  const [ready_status_id, setBacklinkReadyStatus] = useState("");
  const [errors, setErrors] = useState({});
  const [isTaskStatus, setTaskStatus] = useState({});
  const [isStatusNote, setStatusNote] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [backlinkLiveUrl, setBacklinkLiveUrl] = useState(
    taskDetail?.link_to_website || ""
  );
  const [topPaginationTasks, setTopPaginationTasks] = useState([]);
  const [notes, setNotes] = useState([]);
  const navigate = useNavigate();
  const { is_browser_active, is_browser_domain } = useSelector(
    (state) => state.auth
  );
  const [taskLists, setTasksLists] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isUrlModal, setIsUrlModal] = useState(false);
  const [backlinksStatusList, setBacklinksStatusList] = useState([]);
  const [newUpdatedTaskId, setNewupdateTaskId] = useState("");

  const getTaskDetail = ({ page, perPage, newTaskId }) => {
    setIsLoading(true);
    setBacklinkLiveUrl("");
    api
      .post(`/api/backlinkSubmission/backlinkSubmissionDashboard`, {
        task_id: newTaskId,
      })
      .then((res) => {
        setTaskDetail((prevState) => ({
          ...prevState,
          ...res.data,
        }));
        setCurrentStatus(res?.data?.task_status);
        setBacklinkLiveUrl(res?.data?.link_to_website);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        return toast.error(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { task } = useParams();
  const getTaskArr = () => {
    return topPaginationTasks?.length
      ? topPaginationTasks
      : location?.state?.taskIds;
  };

  const [taskArr, setTaskArr] = useState(getTaskArr);
  const itemsPerPage = 1;

  // Memoize taskArr to prevent unnecessary re-renders
  const memoizedTaskArr = useMemo(
    () => getTaskArr(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [topPaginationTasks, location?.state?.taskIds]
  );

  // Re-run when `topPaginationTasks` or `location?.state?.taskIds` changes
  useEffect(() => {
    setTaskArr(memoizedTaskArr);
  }, [memoizedTaskArr]);

  const customTaskArr = taskArr;
  const taskIndex = customTaskArr.indexOf(parseInt(task));
  const [currentPageIndex, setCurrentPageIndex] = useState(taskIndex);
  const totalPages = Math.ceil(customTaskArr.length / itemsPerPage);

  const [currentTaskId, setCurrentTaskId] = useState(parseInt(task));

  const navigatePage = () => {
    const currentPageData = customTaskArr?.slice(
      currentPageIndex * itemsPerPage,
      (currentPageIndex + 1) * itemsPerPage
    );
    const newTaskId = currentPageData[0];

    // Only update if the newTaskId is different from the currentTaskId
    if (newTaskId !== currentTaskId) {
      // Update the currentTaskId state
      setCurrentTaskId(newTaskId); // Update the state with the new task ID

      // Set the newTaskId and navigate
      setNewupdateTaskId(newTaskId);
      let newPayload = {
        task_status: location?.state?.selectedPayload?.task_status,
        user_id: location?.state?.selectedPayload?.user_id,
        open_backlinkid: location?.state?.selectedPayload?.open_backlinkid,
        prevPageId: location?.state?.backlink_id,
        backlink_data: location?.state?.selectedPayload?.backlink_data,
        page: location?.state?.selectedPayload?.page,
        pageSize: location?.state?.selectedPayload?.pageSize,
        updated_at: location?.state?.selectedPayload?.updated_at,
        filters: location.state.filters,
        orgTaskid: location.state.orgTaskid,
        taskArr: customTaskArr,
        taskId: task,
        taskIndex: taskIndex,
      };
      navigate(
        `/reports/review-task/${newTaskId || location.state.orgTaskid}`,
        {
          state: {
            domain_id: location?.state?.domain_id,
            backlink_id: location?.state?.backlink_id,
            userId: location?.state?.userId,
            taskIds: taskArr,
            newTaskId: newTaskId,
            filter: location?.state?.filter,
            taskDate: location?.state?.taskDate,
            selectedPayload: newPayload,
            filters: location.state.filters,
            orgTaskid: newTaskId,
            taskArr: customTaskArr,
            taskId: location.state.taskId,
            taskIndex: taskIndex,
          },
        }
      );
    } else {
      setNewupdateTaskId(parseInt(task));
    }
  };

  useEffect(() => {
    navigatePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskArr, currentPageIndex]);

  const mailboxNotify = () => {
    const payload = {
      fromEmail: taskDetail?.email,
    };

    dispatch(get_realtime_mail({ ...payload }));
    const mailboxPayload = {
      email: taskDetail?.email,
      backlink: taskDetail?.backlink_name,
    };
    dispatch(setTaskEmail(mailboxPayload));
    /*   const timerID = setInterval(() => {
      dispatch(get_realtime_mail({ ...payload }));
    }, 5000);*/
    return () => {
      //clearInterval(timerID);
      dispatch(mailBoxReset());
    };
  };

  React.useEffect(() => {
    if (taskDetail?.email) {
      mailboxNotify();
    }
    // eslint-disable-next-line
  }, [taskDetail]);

  const getNotes = (newTaskId) => {
    api
      .get(`/api/linkbuilder_user/task_notes/${newTaskId}`)
      .then((res) => {
        setNotes(res.data);
      })
      .catch((err) => {
        console.error(err.response?.data?.message || err.message);
        setNotes([]);
      });
  };
  useEffect(() => {
    if (newUpdatedTaskId) {
      getTaskDetail({
        page: currentPageIndex + 1,
        perPage: 1,
        newTaskId: newUpdatedTaskId,
      });
      getNotes(newUpdatedTaskId);
      getBacklinkStatusList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUpdatedTaskId]);

  const handleCopyContent = (content, message) => {
    // Copy the content to the clipboard
    if (!content) {
      toast.error(`no record`, { autoClose: 2000 });
      return;
    }
    navigator.clipboard.writeText(content);
    toast.success(`${message} copied to clipboard!`, { autoClose: 2000 });
  };

  const [addNewNote, setAddNewNote] = useState(false);

  // Update Task
  const [statusList, setStatusList] = useState(false);
  const handleStatus = () => {
    setStatusList(!statusList);
  };
  const updateStatus = async (task_status) => {
    setIsLoading(true);
    const taskErrors = {};
    if (currentStatus === "inReview" && task_status === "pending") {
      if (isStatusNote.trim() === "") {
        taskErrors.note_status = "Please enter a note.";
      } else {
        setErrors(taskErrors);
      }
      if (Object.keys(taskErrors).length > 0) {
        await setErrors(taskErrors);
        setIsLoading(false);
        return;
      }
    }
    if (isUrlModal) {
      setIsUrlModal(false);
    }
    const c = window.confirm("Are you sure want to change the status?");
    if (!c) {
      setIsLoading(false);
      return;
    }
    const requestData = {
      task_status,
      ...(link_to_website && { link_to_website }),
      ...(isStatusNote && { note: isStatusNote }),
      task_ids: [Number(task)],
    };
    api
      .post(`/api/linkbuilder_user/update_multiple_tasks_status`, requestData)
      .then((res) => {
        setCurrentStatus(task_status);
        setLinkToWebsite("");
        handleStatus();
        setStatusNote("");
        getNotes(newUpdatedTaskId);
        toast.success(res.data.message);
        getTaskDetail({
          page: currentPageIndex + 1,
          perPage: 1,
          newTaskId: newUpdatedTaskId,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
    setIsLoading(false);
  };
  const [link_to_website, setLinkToWebsite] = useState("");
  const handleStartBrowser = async ({ domain, id, state_level = 0 }) => {
    setIsLoading(true);
    let profileId = null;
    try {
      const launchPayload = {
        os_type: osName === "Mac OS" ? "macos" : "windows",
        domain,
      };

      if (state_level === 1 || state_level === true) {
        launchPayload.state_level = state_level;
      }
      const launch = await api.post(
        "/api/multilogin/launch_browser_static_ip",
        launchPayload
      );
      if (launch.status === 200) {
        dispatch(setIsBrowserActive(1));
        dispatch(setIsBrowserDomain(domain));
        profileId = launch?.data?.profile_id;

        // Define a function to handle the URL launch
        const attemptUrlLaunch = async () => {
          const urlLaunch = await axios.get(launch.data.launchUrl, {
            headers: {
              Authorization: `Bearer ${launch.data.token}`,
              Accept: "application/json",
            },
            maxBodyLength: Infinity,
          });
          return urlLaunch;
        };

        let urlLaunch;
        try {
          urlLaunch = await attemptUrlLaunch();
        } catch (error) {
          if (
            error?.response?.data?.status?.error_code === "LOCK_PROFILE_ERROR"
          ) {
            // Unlock profile if needed
            await axios.get(
              "https://api.multilogin.com/bpds/profile/unlock_profiles",
              {
                headers: {
                  Authorization: `Bearer ${launch.data.token}`,
                  Accept: "application/json",
                },
              }
            );
            // Retry launching the URL after unlocking the profile
            urlLaunch = await attemptUrlLaunch();
          } else {
            throw error; // Rethrow error if it’s not a lock profile issue
          }
        }

        // Check response status and continue handling as in your original code
        if (urlLaunch.data.status.http_code === 200) {
          const update = await api.post(
            "/api/multilogin/update_browser_status_static_ip",
            {
              profile_id: profileId,
              is_browser_active: 1,
            }
          );
          if (update.status === 200) {
            toast.success("Browser started successfully");
          } else {
            dispatch(setIsBrowserActive(null));
            dispatch(setIsBrowserDomain(null));
            toast.error(
              update?.data?.message || "Browser status couldn't be updated"
            );
          }
        } else {
          // Handle other errors as in your original code
          handleLaunchError(
            urlLaunch.data.status.error_code,
            domain,
            profileId
          );
        }
      } else {
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
        toast.error(launch?.data?.message || "Browser couldn't be launched.");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(setIsBrowserActive(null));
      dispatch(setIsBrowserDomain(null));
      handleApiError(error, domain, id, state_level);
    }
  };

  // Helper function to handle URL launch errors
  const handleLaunchError = (error, domain, profileId) => {
    if (error === "LOCK_PROFILE_ERROR") {
      console.log("👊 ~ handleStartBrowser ~ error:", error);
      // Handle other specific error codes here...
    }
  };

  // Helper function to handle API errors
  const handleApiError = (error, domain, id, state_level) => {
    if (error?.response?.data?.status?.error_code) {
      const errorMessage = error?.response?.data?.status?.error_code;
      if (
        errorMessage === "CORE_DOWNLOADING_STARTED" ||
        errorMessage === "CORE_DOWNLOADING_ALREADY_STARTED"
      ) {
        toast.error("Please try again after a minute", { autoClose: 10000 });
      } else if (
        errorMessage === "GET_PROXY_CONNECTION_IP_ERROR" &&
        state_level === 0
      ) {
        return handleStartBrowser({ domain, id, state_level: true });
      } else {
        toast.error(errorMessage || "Browser couldn't be launched.");
      }
    } else {
      toast.error("Proxy error: Could not proxy request!");
    }
    console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await api.get(`${process.env.REACT_APP_PUBLIC_API}/api/logout`);
      localStorage.clear();
      dispatch(setLoggedInUser(null));
    } catch (err) {
      console.log("👊 ~ handleLogout ~ err:", err);
    }
    setIsLoading(false);
  };
  const handleStopBrowser = async () => {
    setIsLoading(true);
    try {
      const launch = await api.post("/api/multilogin/stop_browser_static_ip");
      if (launch.status === 200) {
        const urlLaunch = await axios.get(launch.data.stop_url, {
          headers: { Authorization: `Bearer ${launch.data.token}` },
        });
        if (urlLaunch.data?.status?.http_code === 200) {
          const update = await api.post(
            "/api/multilogin/update_browser_status_static_ip",
            {
              is_browser_active: 0,
            }
          );
          if (update.status === 200) {
            toast.success("Browser stopped successfully");
            dispatch(setIsBrowserActive(null));
            dispatch(setIsBrowserDomain(null));
          } else {
            toast.error(
              update?.data?.message || "Browser status couldn't be updated"
            );
          }
        } else {
          toast.error(
            urlLaunch?.data?.message || "Browser couldn't be stopped"
          );
        }
      } else {
        toast.error(launch?.data?.message || "Browser couldn't be stopped");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.status?.message === "profile already stopped"
      ) {
        toast.success("Browser stopped successfully");
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
        return await api.post(
          "/api/multilogin/update_browser_status_static_ip",
          {
            is_browser_active: 0,
          }
        );
      }
      toast.error(
        error?.response?.data?.message || "Browser couldn't be stopped"
      );
      if (error?.response?.data?.message === "Browser not exists") {
        handleLogout();
      }
      console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
    }
  };
  const getBacklinkStatusList = () => {
    api
      .get(`/api/backlinks/backlink_status_list`)
      .then((res) => setBacklinksStatusList(res.data))
      .catch((err) => console.log(err));
  };

  const handleSubmit = (e, taskDetail) => {
    setIsLoading(true);
    const errors = {};
    if (!ready_status_id) {
      errors.ready_status_id = "Please select backlink ready status.";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }
    const updateRecord = {
      id: taskDetail?.backlink_id,
      ready_status_id,
      backlink_domain: taskDetail?.backlink_name,
    };
    if (updateRecord && updateRecord.id) {
      api
        .put(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/updateBacklink`,
          updateRecord
        )
        .then(() => {
          toast.success("Backlink updated successfully!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          navigatePage();
          setIsLoading(false);
        });
    }
  };

  const handleLiveUrlSubmit = () => {
    setIsLoading(true);
    const errors = {};
    if (!backlinkLiveUrl) {
      errors.backlinkLiveUrl = "Please enter Backlink Live URL.";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }
    const updateRecord = {
      link_to_website: backlinkLiveUrl,
    };
    if (updateRecord && updateRecord.link_to_website) {
      api
        .put(
          `${process.env.REACT_APP_PUBLIC_API}/api/linkbuilder_user/update_backlink_live_url/${task}`,
          updateRecord
        )
        .then((res) => {
          toast.success(res?.data?.message, { autoClose: 3000 });
          setErrors({});
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          navigatePage();
          setIsLoading(false);
        });
    }
  };
  const handlePageChange = (page) => {
    setCurrentPageIndex(page - 1);
    navigatePage();
  };
  const downloadImage = async (url, width, height, filename) => {
    try {
      // Fetch the image as a Blob

      const response = await axios.get(
        url
          ?.replaceAll("https://", "https://www.")
          ?.replaceAll("http://", "https://www."),
        { responseType: "blob" }
      );
      const blob = response.data;

      // Create an Image object
      const img = new Image();
      img.src = URL.createObjectURL(blob);

      img.onload = () => {
        // Create a canvas and draw the image
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas to a Blob
        canvas.toBlob((resizedBlob) => {
          // Create a link element to download the resized image
          const link = document.createElement("a");
          link.href = URL.createObjectURL(resizedBlob);
          link.download = filename;
          link.click();

          // Clean up
          URL.revokeObjectURL(link.href);
        }, "image/jpeg");
      };
    } catch (error) {
      console.error("Error downloading or resizing the image:", error);
    }
  };
  const handleNewDownloadImage = async ({
    domain,
    tagName,
    width,
    height,
    filename,
    action = "download",
  }) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `https://www.${domain}/api/get_domain_images?domain=${domain}`,
        { headers: { Authorization: "Bearer AKIA3WTNB4VOJYIFVP7F" } }
      );
      if (res.status === 200) {
        const images = res.data;
        if (!images || !images?.length) {
          setIsLoading(false);
          return toast.error("No image found for this domain.");
        }
        const imgUrl = images?.find((item) => item?.tagName === tagName);
        if (!imgUrl) {
          setIsLoading(false);
          return toast.error("This image not found.");
        }
        if (action === "download") {
          await downloadImage(imgUrl?.path, width, height, filename);
        } else {
          await copyToClipboard(imgUrl?.path);
        }
      }
    } catch (err) {
      console.log("👊 ~ handleNewDownloadImage ~ err:", err);
    }
    setIsLoading(false);
  };
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isLoading) {
        event.preventDefault();
        event.returnValue =
          "Are you sure you want to leave? Your action is still in progress.";
      }
    };

    // Add the event listener for beforeunload only if isLoading is true
    if (isLoading) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    // Clean up the event listener when isLoading becomes false or component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);

  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }

  const timeConvert = (timestamp) => {
    const timezoneName = dayjs.tz.guess();
    dayjs.tz.setDefault(timezoneName);
    const parsedTime = dayjs(timestamp, "YYYY-MM-DD HH:mm:ss");
    const newTime = parsedTime.add(5, "hour").add(30, "minute");
    const currentTime = dayjs(newTime).format("ddd, MMM D, YYYY h:mm A");
    return currentTime;
  };

  const fetchTasksList = async ({ filter }) => {
    setIsLoading(true);
    setTasksLists([]);
    const url = `/api/report/review_tasks`;
    try {
      const response = await api.post(url, { filter });
      if (response.status !== 200) {
        toast.error(response?.data?.message);
        //setIsLoading(false);
        return;
      }
      setTasksLists(response.data?.map((item) => item["bl.id"]));
    } catch (error) {
      console.error(error);
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    const newFilters = location.state.filters;
    fetchTasksList({
      filter: newFilters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetPagination = () => {
    setCurrentPageIndex(0);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (
        event.key === "F5" ||
        (event.ctrlKey && event.key === "r") ||
        (event.metaKey && event.key === "r")
      ) {
        event.preventDefault();
        alert(
          "Page refresh is disabled! Please do not attempt to refresh the page"
        );
      }
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="p-5">
      {isLoading && <Loading />}
      <ToastContainer />
      {isUrlModal ? (
        <URLModal
          link_to_website={link_to_website}
          setLinkToWebsite={setLinkToWebsite}
          onClose={() => {
            setIsUrlModal(false);
            setStatusNote("");
            setLinkToWebsite("");
            setTaskStatus({});
          }}
          updateStatus={updateStatus}
          taskObj={isTaskStatus}
          setStatusNote={setStatusNote}
          isStatusNote={isStatusNote}
          errors={errors}
        />
      ) : null}
      <>
        {location?.state?.selectedPayload ? (
          <CustomBreadcrumbs
            crumbs={[
              { label: "Home", link: "/" },
              {
                label: `${
                  location?.state?.selectedPayload?.task_status === "hold"
                    ? "Hold Tasks"
                    : "Review Task"
                }`,
                link: `/reports/review-task`,
              },
              {
                label: "Task Detail",
              },
            ]}
            filter={location?.state}
          />
        ) : null}
      </>
      <CustomTopPagination
        data={taskLists}
        setIsLoading={setIsLoading}
        parentFilters={location?.state?.selectedPayload}
        prevPageBacklinkId={location?.state?.backlink_id}
        setTopPaginationTasks={setTopPaginationTasks}
        resetPagination={resetPagination}
      />
      <div className="grid grid-cols-3 gap-4 mt-2">
        <div className="flex items-center gap-4">
          {taskDetail?.city ? (
            <div className="card py-3 flex items-center gap-2">
              {isLoading ? (
                <div className="bg-blue-100 flex items-center justify-start rounded-md py-2 w-55">
                  <img
                    src="/img/loading.gif"
                    className="w-6 mr-2"
                    alt="Loading"
                  />
                  <span className="text-md text-red-500">
                    Please do not reload the page
                  </span>
                </div>
              ) : +is_browser_active ? (
                <button
                  onClick={handleStopBrowser}
                  className="capitalize text-red-600 font-semibold flex items-center"
                >
                  <BsBrowserChrome className="mr-2" />
                  stop browser
                </button>
              ) : (
                <button
                  onClick={() =>
                    handleStartBrowser({
                      domain: taskDetail?.domain,
                      id: task,
                    })
                  }
                  className="capitalize text-green-600 font-semibold flex flex-row items-center disabled:text-green-300"
                  disabled={!!+is_browser_active && !taskDetail?.city}
                >
                  <BsBrowserChrome className="mr-2" />
                  start browser
                </button>
              )}
            </div>
          ) : null}
        </div>
        <div className="flex flex-col items-center justify-center gap-0">
          <div className="flex items-center mb-3 text-center">
            <p className="text-md text-teal-800 font-medium">
              <label className="text-sm text-gray-500 font-semibold mr-2 font-serif">
                You Are Working On Backlink Domain -
              </label>
              <span className="md:inline-block md:text-left font-serif">
                {taskDetail?.backlink_name}
              </span>
            </p>
          </div>
          <div className="flex items-center justify-center">
            <CustomPagination
              currentPage={currentPageIndex + 1}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              is_browser_active={
                !!+is_browser_active && is_browser_domain !== taskDetail?.domain
              }
            />
          </div>
        </div>
        <div className="flex items-center justify-end gap-3">
          {/* <InputField
      placeholder="Paste URL"
      className="w-56"
      value={link_to_website}
      onChange={(e) => setLinkToWebsite(e.target.value)}
    /> */}
          <TaskStatus
            currentStatus={currentStatus}
            updateStatus={updateStatus}
            statusList={statusList}
            handleStatus={handleStatus}
            setStatusList={setStatusList}
            setIsUrlModal={setIsUrlModal}
            setTaskStatus={setTaskStatus}
            setErrors={setErrors}
          />
        </div>
      </div>
      <div className="mt-4 grid lg:grid-cols-2 gap-4">
        <div className="card">
          <p className="text-lg font-bold">Money Site Info:</p>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Money Site URLs:</p>
            <div className="flex items-center">
              <a
                href={`https://www.${taskDetail?.["domain"]}`}
                className="hover:text-secondary underline mr-2"
                target="_blank"
                rel="noreferrer"
              >
                {taskDetail?.["domain"]}
              </a>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(
                    `https://www.${taskDetail?.["domain"]}`,
                    "Money Site URL"
                  )
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Industry:</p>
            <div className="flex items-center">
              <p>{taskDetail?.["industry_name"]}</p>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(taskDetail?.industry_name, "Industry")
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Phone:</p>
            <a
              href={`tel:${taskDetail?.["phone"]}`}
              className="hover:text-secondary cursor-pointer"
            >
              <div className="flex items-center">
                <p> {taskDetail?.["phone"]}</p>
                <ClipboardDocumentIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleCopyContent(taskDetail?.phone, "Phone");
                  }}
                  className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
                />
              </div>
            </a>
          </div>

          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>First Name:</p>
            <div className="flex items-center">
              <p>{taskDetail?.["fname"]}</p>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(taskDetail?.fname, "First Name")
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>

          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Last Name:</p>
            <div className="flex items-center">
              <p>{taskDetail?.["lname"]}</p>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(taskDetail?.lname, "Last Name")
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Username:</p>
            <div className="flex items-center">
              <p>{taskDetail?.["username"]}</p>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(taskDetail?.username, "Username")
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Industry Username:</p>
            <div className="flex items-center">
              <p>
                {taskDetail?.company_name
                  ? taskDetail.company_name.toLowerCase().replace(/\s+/g, "")
                  : ""}
              </p>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(
                    taskDetail?.company_name
                      ? taskDetail.company_name
                          .toLowerCase()
                          .replace(/\s+/g, "")
                      : "",
                    "Company Name"
                  )
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Email:</p>
            <div className="flex items-center">
              <p>{taskDetail?.email}</p>
              <ClipboardDocumentIcon
                onClick={() => handleCopyContent(taskDetail?.email, "Email")}
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Password:</p>
            <p className="flex items-center">
              {visiblePassword ? taskDetail?.["password"] : "*******"}
              <span
                className="ml-2 cursor-pointer"
                onClick={() => setVisiblePassword(!visiblePassword)}
              >
                {visiblePassword ? (
                  <FaEye className="my_navIcon cursor-pointer text-green-500 w-5 h-5" />
                ) : (
                  <FaEyeSlash className="my_navIcon cursor-pointer text-green-500 w-5 h-5" />
                )}
              </span>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(taskDetail?.password, "Password")
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </p>
          </div>
          {taskDetail?.service_timing?.length ? (
            <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
              <p>Service Hours:</p>
              <div className="flex flex-row gap-2 overflow-x-auto w-[450px] cursor-pointer">
                {taskDetail?.service_timing?.map((item, index) => (
                  <div
                    key={index}
                    className="flex-none w-24 p-2 bg-gray-100 rounded-lg shadow-sm text-center text-xs"
                  >
                    <span className="block font-medium text-gray-700 capitalize">
                      {item.day}
                    </span>
                    <span className="block text-gray-500 text-xs">
                      {item.opens} - {item.closes}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Street:</p>
            <div className="flex items-center">
              <p>{taskDetail?.street}</p>
              <ClipboardDocumentIcon
                onClick={() => handleCopyContent(taskDetail?.street, "Street")}
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>City:</p>
            <div className="flex items-center">
              <p>{taskDetail?.city}</p>
              <ClipboardDocumentIcon
                onClick={() => handleCopyContent(taskDetail?.city, "City")}
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>State:</p>
            <div className="flex items-center">
              <p>{taskDetail?.state}</p>
              <ClipboardDocumentIcon
                onClick={() => handleCopyContent(taskDetail?.state, "State")}
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Zip:</p>
            <div className="flex items-center">
              <p>{taskDetail?.zip_code}</p>
              <ClipboardDocumentIcon
                onClick={() => handleCopyContent(taskDetail?.zip_code, "Zip")}
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Address:</p>
            <div className="flex items-center">
              <p>
                {[
                  taskDetail?.street,
                  taskDetail?.city,
                  taskDetail?.zip_code,
                  taskDetail?.state,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </p>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(
                    [
                      taskDetail?.street,
                      taskDetail?.city,
                      taskDetail?.zip_code,
                      taskDetail?.state,
                    ]
                      .filter(Boolean)
                      .join(", "),
                    "Address"
                  )
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Latitude:</p>
            <div className="flex items-center">
              <p>{taskDetail?.lat}</p>
              <ClipboardDocumentIcon
                onClick={() => handleCopyContent(taskDetail?.lat, "Latitude")}
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Longitude:</p>
            <div className="flex items-center">
              <p>{taskDetail?.lon}</p>
              <ClipboardDocumentIcon
                onClick={() => handleCopyContent(taskDetail?.lon, "Longitude")}
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Company Name:</p>
            <div className="flex items-center">
              <p>{taskDetail?.company_name}</p>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(taskDetail?.company_name, "Company Name")
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Building:</p>
            <div className="flex items-center">
              <p>{taskDetail?.building_number}</p>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(
                    taskDetail?.building_number,
                    "Building Name"
                  )
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 ml-2 mr-2"
              />
            </div>
          </div>

          <div>
            <div className="px-">
              <h5 className="font-semibold px-2 pb-1">Banner Image</h5>
              <hr />
              <div className="border-b flex justify-between px-5 py-2 ">
                <span className="text-gray-500 dark:text-gray-400">
                  Background Image
                </span>
                <div className="flex gap-x-2">
                  <button
                    onClick={() => {
                      const file = taskDetail?.domain?.replaceAll(".", "_");
                      handleNewDownloadImage({
                        domain: taskDetail?.domain,
                        runner: taskDetail?.runner_name,
                        tagName: "banner-bg",
                        width: 1920,
                        height: 1080,
                        filename: `${file}_domain_background_image`,
                      });
                    }}
                    className="hover:bg-gray-200 bg-gray-300 w-6 w-6 rounded-full flex justify-center items-center"
                  >
                    <MdDownload />
                  </button>
                  <button
                    onClick={() => {
                      const file = taskDetail?.domain?.replaceAll(".", "_");
                      handleNewDownloadImage({
                        domain: taskDetail?.domain,
                        tagName: "banner-bg",
                        width: 1280,
                        height: 720,
                        filename: `${file}_domain_hero_image`,
                        action: "copy",
                      });
                    }}
                    className="w-5 cursor-pointer text-black hover:text-gray-600"
                  >
                    <ClipboardDocumentIcon />
                  </button>
                </div>
              </div>
              <div className="border-b flex justify-between px-5 py-2">
                <span className="text-gray-500 dark:text-gray-400">
                  Hero Image
                </span>
                <div className="flex gap-x-2">
                  <button
                    onClick={() => {
                      const file = taskDetail?.domain?.replaceAll(".", "_");
                      handleNewDownloadImage({
                        domain: taskDetail?.domain,
                        tagName: "banner-bg",
                        width: 1280,
                        height: 720,
                        filename: `${file}_domain_hero_image`,
                      });
                    }}
                    className="hover:bg-gray-200 bg-gray-300 w-6 w-6 rounded-full flex justify-center items-center"
                  >
                    <MdDownload />
                  </button>
                  <button
                    onClick={() => {
                      const file = taskDetail?.domain?.replaceAll(".", "_");
                      handleNewDownloadImage({
                        domain: taskDetail?.domain,
                        tagName: "banner-bg",
                        width: 1280,
                        height: 720,
                        filename: `${file}_domain_hero_image`,
                        action: "copy",
                      });
                    }}
                    className="w-5 cursor-pointer text-black hover:text-gray-600"
                  >
                    <ClipboardDocumentIcon />
                  </button>
                </div>
              </div>
            </div>
            <div className="px-">
              <h5 className="font-semibold px-2 pb-1">Blog Image</h5>
              <hr />
              <div className="border-b flex justify-between px-5 py-2">
                <span className="text-gray-500 dark:text-gray-400">
                  Background Image
                </span>
                <div className="flex gap-x-2">
                  <button
                    onClick={() => {
                      const file = taskDetail?.domain?.replaceAll(".", "_");
                      handleNewDownloadImage({
                        domain: taskDetail?.domain,
                        tagName: "blog-image",
                        width: 1920,
                        height: 1080,
                        filename: `${file}_domain_background_image`,
                      });
                    }}
                    className="hover:bg-gray-200 bg-gray-300 w-6 w-6 rounded-full flex justify-center items-center"
                  >
                    <MdDownload />
                  </button>
                  <button
                    onClick={() => {
                      const file = taskDetail?.domain?.replaceAll(".", "_");
                      handleNewDownloadImage({
                        domain: taskDetail?.domain,
                        tagName: "blog-image",
                        width: 1280,
                        height: 720,
                        filename: `${file}_domain_background_image`,
                        action: "copy",
                      });
                    }}
                    className="w-5 cursor-pointer text-black hover:text-gray-600"
                  >
                    <ClipboardDocumentIcon />
                  </button>
                </div>
              </div>
              <div className="border-b flex justify-between px-5 py-2">
                <span className="text-gray-500 dark:text-gray-400">
                  Hero Image
                </span>
                <div className="flex gap-x-2">
                  <button
                    onClick={() => {
                      const file = taskDetail?.domain?.replaceAll(".", "_");
                      handleNewDownloadImage({
                        domain: taskDetail?.domain,
                        tagName: "blog-image",
                        width: 1280,
                        height: 720,
                        filename: `${file}_domain_hero_image`,
                      });
                    }}
                    className="hover:bg-gray-200 bg-gray-300 w-6 w-6 rounded-full flex justify-center items-center"
                  >
                    <MdDownload />
                  </button>

                  <button
                    onClick={() => {
                      const file = taskDetail?.domain?.replaceAll(".", "_");
                      handleNewDownloadImage({
                        domain: taskDetail?.domain,
                        tagName: "blog-image",
                        width: 1280,
                        height: 720,
                        filename: `${file}_domain_hero_image`,
                        action: "copy",
                      });
                    }}
                    className="w-5 cursor-pointer text-black hover:text-gray-600"
                  >
                    <ClipboardDocumentIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <p className="text-lg font-bold">Backlink Info:</p>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Backlink Type:</p>
            <p>{taskDetail?.backlink_type}</p>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Backlink Site URL:</p>
            <div className="flex items-center">
              <a
                href={`https://www.${taskDetail?.["backlink_name"]}`}
                className="hover:text-secondary underline mr-2"
                target="_blank"
                rel="noreferrer"
              >
                {taskDetail?.backlink_name}
              </a>
              <ClipboardDocumentIcon
                onClick={() =>
                  handleCopyContent(
                    taskDetail?.["backlink_name"],
                    "Backlink Site URL"
                  )
                }
                className="w-5 cursor-pointer text-black hover:text-gray-600 mr-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-2">
            <p>Backlink Live URL:</p>
            <div className="flex items-center">
              <div className="mt-2">
                <div className="flex border rounded-lg overflow-hidden">
                  <a
                    href={`${taskDetail?.["link_to_website"]}`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center px-4 py-2 bg-gray-200 hover:bg-gray-300 border-r border-gray-100"
                  >
                    <FaExternalLinkAlt />
                  </a>
                  <button
                    onClick={() =>
                      handleCopyContent(
                        taskDetail?.["link_to_website"],
                        "Backlink Live URL"
                      )
                    }
                    className="flex items-center px-4 py-2 bg-gray-200 hover:bg-gray-300"
                  >
                    <ClipboardDocumentIcon className="w-5 cursor-pointer text-black hover:text-gray-600" />
                  </button>
                  <input
                    type="text"
                    value={backlinkLiveUrl}
                    className="px-4 py-2 outline-none flex-grow"
                    placeholder="Enter text here"
                    onChange={(e) => setBacklinkLiveUrl(e.target.value)}
                    required
                  />
                  <button
                    onClick={handleLiveUrlSubmit}
                    className="btnPrimary px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-none"
                  >
                    Save
                  </button>
                </div>
                {errors.backlinkLiveUrl && (
                  <div className="text-red-500 mt-2">
                    {errors.backlinkLiveUrl}
                  </div>
                )}
              </div>
            </div>
          </div>
          {userPermission("Verify Backlink") ? (
            <div className="flex items-center justify-between text-gray-500 dark:text-gray-400 border-b border-dashed py-1 pb-2">
              <p>Backlink Ready Status:</p>
              <div className="flex items-center">
                <div className="mt-2">
                  <div className="flex items-center gap-2">
                    <select
                      value={
                        ready_status_id
                          ? ready_status_id
                          : taskDetail?.ready_status_id
                      }
                      onChange={(e) => setBacklinkReadyStatus(e.target.value)}
                      name="backlink_type_id"
                      className={`inputField mt-1 ${
                        errors.level ? "border-red-500" : ""
                      }`}
                      error={errors.ready_status_id}
                    >
                      <option value="">Select Backlink Ready Status</option>
                      {backlinksStatusList?.map(({ id, ready_status }) => (
                        <option key={id} value={id}>
                          {ready_status}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={(e) => handleSubmit(e, taskDetail)}
                      className="btnPrimary mt-1"
                    >
                      Save
                    </button>
                  </div>
                  {errors.ready_status_id && (
                    <div className="text-red-500 mt-2">
                      {errors.ready_status_id}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <div className="flex flex-row justify-between items-center mt-5">
            <p className="text-lg font-bold">Backlink TPL-Content</p>
            <ClipboardDocumentIcon
              onClick={() => handleCopyContent(taskDetail?.content, "Content")}
              className="w-5 cursor-pointer hover:text-gray-600"
            />
          </div>

          <div className="p-3 bg-gray-100 dark:bg-primary/30 mt-2">
            <div className="h-30 overflow-y-scroll text-sm">
              <ServiceContent content={taskDetail?.["content"]} />
            </div>
          </div>
          {taskDetail?.updtaedShortContent && (
            <>
              <div className="flex flex-row justify-between items-center mt-5">
                <p className="text-lg font-bold">Backlink Short-Content</p>
                <ClipboardDocumentIcon
                  onClick={() =>
                    handleCopyContent(
                      taskDetail?.updtaedShortContent,
                      "Content"
                    )
                  }
                  className="w-5 cursor-pointer hover:text-gray-600"
                />
              </div>
              <div className="p-3 bg-gray-100 dark:bg-primary/30 mt-2">
                <div className="h-30 overflow-y-scroll text-sm">
                  <ServiceContent
                    content={taskDetail?.["updtaedShortContent"]}
                  />
                </div>
              </div>
            </>
          )}
          {taskDetail?.backlink_content_title && (
            <>
              <div className="flex flex-row justify-between items-center mt-5">
                <p className="text-lg font-bold">Backlink Content Title</p>
                <ClipboardDocumentIcon
                  onClick={() =>
                    handleCopyContent(
                      taskDetail?.backlink_content_title,
                      "Content"
                    )
                  }
                  className="w-5 cursor-pointer hover:text-gray-600"
                />
              </div>
              <div className="p-3 bg-gray-100 dark:bg-primary/30 mt-2">
                <div className="h-30 overflow-y-scroll text-sm">
                  <ServiceContent
                    content={taskDetail?.["backlink_content_title"]}
                  />
                </div>
              </div>
            </>
          )}
          {taskDetail?.task_log ? (
            <>
              <div className="flex flex-row justify-between items-center mt-5">
                <p className="text-lg font-bold">Task Activity</p>
              </div>
              <div className="text-sm">
                <div className="bg-white dark:bg-white/20 backdrop-blur-lg text-sm shadow-xl dark:shadow-black mt-1 p-3 rounded-md flex flex-col min-h-[100px] max-h-[600px] overflow-y-scroll border dark:border-white/20">
                  {taskDetail.task_log ? (
                    <>
                      <div className="flex justify-between mb-2 border-b">
                        <span className="text-md font-semibold w-1/3 ml-4 ">
                          Status
                        </span>
                        <span className="text-md font-semibold w-1/3 text-center">
                          Reason
                        </span>
                        <span className="text-md font-semibold w-1/3 text-center">
                          Date
                        </span>
                      </div>
                      {JSON.parse(taskDetail.task_log).length > 0 ? (
                        JSON.parse(taskDetail.task_log).map((item, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between border-b border-dashed hover:bg-gray-100 dark:hover:bg-white/10 hover:rounded p-2"
                          >
                            <div className="capitalize w-1/3 ">
                              {addSpaceBeforeUpperCase(item.status)}
                            </div>
                            <div className="capitalize w-1/3 text-center">
                              {taskDetail?.hold_reason || "N/A"}
                            </div>
                            <div className="w-1/3 text-center">
                              {timeConvert(item.timestamp)}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No task logs available</div>
                      )}
                    </>
                  ) : (
                    <div>No task logs available</div>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
        {/*  <div className="card bg-orange-400 text-white text-sm">
          <p className="text-lg font-bold mb-2">Backlink Submission Steps:</p>
          {taskDetail?.steps?.map((step, index) => {
            const key = Object.keys(step)[0];
            const item = step[key];
            return (
              <div key={index} className="mb-2 grid grid-cols-step">
                <p className="bg-black/10 font-medium text-center py-1 capitalize">
                  {key}
                </p>
                <p className="bg-white/40 flex-1 text-black py-1 px-2 border-dashed border-2">
                  {item}
                </p>
              </div>
            );
          })}
        </div> */}
      </div>
      <div className="flex items-center justify-between gap-3 mt-5 mb-3">
        <h2 className="text-2xl font-bold">Notes:</h2>
        <div className="flex items-center justify-end gap-3">
          <button
            onClick={() => setAddNewNote(true)}
            className="btnPrimary disabled:cursor-not-allowed"
          >
            <PlusCircleIcon className="w-4" />
            Add Note
          </button>
          {/* <button
            className="btnPrimary bg-gray-700"
            onClick={() => {
              updateStatus("hold");
            }}
          >
            Hold
          </button>
          <button
            className="btnPrimary bg-green-600"
            onClick={() => {
              updateStatus("completed");
            }}
          >
            Complete
          </button> */}
        </div>
      </div>

      <Notes
        task={task}
        addNewNote={addNewNote}
        setAddNewNote={setAddNewNote}
        getNotes={getNotes}
        setNotes={setNotes}
        notes={notes}
        newUpdatedTaskId={newUpdatedTaskId}
      />

      {/*  <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-2xl"
        title="Verify Backlink"
      >
        <VerifyModal
          handleModal={handleModal}
          taskDetail={taskDetail}
          backlinksStatusList={backlinksStatusList}
          backLinkId={location?.state?.backlink_id}
          backLinkReadyStatusID={location?.state?.ready_status_id}
          getTaskDetail={getTaskDetail}
          userId={location?.state?.userId}
        />
      </Modal> */}
    </div>
  );
}
