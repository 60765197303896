export default function FullContainer({ children, className, style }) {
  return (
    <div
      className={`w-full flex flex-col items-center justify-center bg-center bg-cover ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}
