import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

// Styled Switch component
const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: theme.palette.grey[500],
    '&.Mui-checked': {
      color: '#4caf50', // Green color for the toggle switch
      '& + .MuiSwitch-track': {
        backgroundColor: '#4caf50', // Green background when checked
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.grey[500], // Default track color
  },
}));

const ToggleSwitch = ({ onToggleChange }) => {
  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    onToggleChange(newChecked); // Notify parent of the new status
  };

  return (
    <div className="flex items-center">
      <CustomSwitch
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <span className={`text-base text-gray-700 ${checked ? 'font-semibold' : 'font-semibold'}`}>
        {checked ? 'Completed' : 'Hold'}
      </span>
    </div>
  );
};

export default ToggleSwitch;
