import React, { useEffect, useRef, useState } from "react";
import api from "../../services/api";
import { InputField, Modal } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import CustomDataTable from "../../components/common/CustomDataTable";
export default function BacklinksType() {
  const [isLoading, setIsLoading] = useState(false);

  //   Get Type List
  const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const getBacklinkTypeList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getBacklinkTypeList();
  }, []);

  //   Modal
  const [modalType, setModalType] = useState("");
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const [type, setType] = useState([]);
  const handleNewType = () => {
    setModalType("new");
    handleModal();
  };

  //   Create New Type
  const createNewType = (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .post(`/api/backlinks/add_backlink_type`, { type: type })
      .then((res) => {
        toast.success("new type created");
        getBacklinkTypeList();
      })
      .catch((err) => toast.error(err.response.data.message));
    setType("");
    handleModal("");
    setIsLoading(false);
  };

  //   Update Type
  const [typeId, setTypeId] = useState("");
  const hanldeUpdateType = (item) => {
    setType(item?.type);
    setTypeId(item?.id);
    setModalType("update");
    handleModal();
  };
  const updateType = (e) => {
    e.preventDefault();
    setIsLoading(true);

    api
      .put(`/api/backlinks/update_backlink_type`, {
        id: typeId,
        type: type,
      })
      .then((res) => {
        toast.success("type updated");
        getBacklinkTypeList();
      })
      .catch((err) => toast.error(err.response.data.message));
    setType("");
    handleModal("");
    setIsLoading(false);
  };

  //   Delete Type
  const deleteType = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (!isConfirmed) {
      return;
    }
    setIsLoading(true);
    api
      .delete(`/api/backlinks/delete_backlink_type/${id}`)
      .then((res) => {
        toast.success("Type Removed");
        getBacklinkTypeList();
      })
      .catch((err) => toast.error(err.response.data.message));
    setIsLoading(false);
  };

  const handleRowClick = async (itemId) => {
    if (expandedRow === itemId?.id) {
      setExpandedRow(null);
      setAdditionalData([]);
    } else {
      setIsLoading(true);
      try {
        const res = await api.post(`/api/backlinks/backlink_content_by_id`, {
          backlink_type_id: itemId?.id,
        });
        setAdditionalData(res.data);
        setExpandedRow(itemId?.id);
      } catch (err) {
        toast.error(err.response.data.message);
      }
      setIsLoading(false);
    }
  };
  return (
    <div className="px-6 pt-4 pb-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Backlinks Type", link: "" },
        ]}
      />
      {isLoading && <Loading />}
      <ToastContainer />
      <CustomDataTable
        /*   isLoading={isLoading} */
        extras={
          <button onClick={handleNewType} className="btnPrimary mt-2">
            Create New
          </button>
        }
        searchBox
        heads={[" ", "Sr#", "Backlink Type", "Total Content", "actions"]}
        items={backlinksTypeList.map((item, index) => ({
          id: item.id,
          serialNumber: index + 1,
          type: item.type,
          backlink_type_content_count: (
            <span className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
              {item?.backlink_type_content_count}
            </span>
          ),
          actions: (
            <div className="relative flex items-center gap-3 text-gray-500">
              {userPermission("Delete Backlink Type") ? (
                <TrashIcon
                  onClick={() => deleteType(item.id)}
                  className="w-5 cursor-pointer"
                />
              ) : null}
              {userPermission("Edit Backlink Type") ? (
                <PencilIcon
                  onClick={() => hanldeUpdateType(item)}
                  className="w-5 cursor-pointer"
                />
              ) : null}
            </div>
          ),
        }))}
        onRowClick={handleRowClick}
        expandedRow={expandedRow}
        additionalData={additionalData}
        excludeKeys={["id"]}
        childActions={false}
      />
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-2xl"
        title={
          (modalType === "update" && "Update backlink type") ||
          (modalType === "new" && "Create New backlink type")
        }
        handleModalSubmit={
          (modalType === "update" && updateType) ||
          (modalType === "new" && createNewType)
        }
        modalType="new"
      >
        <InputField
          placeholder="Enter new type"
          value={type}
          onChange={(e) => setType(e.target.value)}
        />
      </Modal>
    </div>
  );
}
