import React, { useState, useRef } from "react";
import api from "../../services/api";
import Modal from "../common/Modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
function ImportModal({ setOpen, isImport, fetchBacklinksData }) {
  /* const initialData = {
    type: "",
    city: "",
    state: "",
  }; */
  //const [payloadData, setPayloadData] = useState(initialData);
  // const [open, setOpen] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValidationModal, setIsValidationModal] = useState(false);
  const [errors, setErrors] = useState({});
  //const [cities, setCities] = useState([]);
  const [excelFile, setExcelFile] = useState();
  const [isDuplicateDomainModal, setDuplicateDomainModal] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isImportAhref, setImportAhref] = useState(false);
  const handleModal = () => {
    setOpen(!isImport);
  };
  const handleValidationModal = () => {
    setIsValidationModal(!isValidationModal);
  };
  const handleDuplicateDomainModal = () => {
    setDuplicateDomainModal(!isDuplicateDomainModal);
  };
  const cancelButtonRef = useRef(null);
  const cancelButtonRef2 = useRef(null);
  const cancelButtonRef3 = useRef(null);
  const handleSubmit = (e, skip_error, importType) => {
    e.preventDefault();
    const errors = {};
    /* if (!payloadData?.type?.trim()) {
      errors.type = "Please select the type.";
    } */
    if (!excelFile) {
      errors.file = "Please select a file.";
    }
    /* if (payloadData?.type === "local" && !payloadData?.state?.trim()) {
      errors.state = "Please select the state.";
    }

    if (payloadData?.type === "local" && !payloadData?.city?.trim()) {
      errors.city = "Please select the city.";
    } */
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("files", excelFile);
    /* formData.append("type", payloadData?.type);
    if (payloadData?.type === "local") {
      formData.append("state", payloadData?.state);
      formData.append("city", payloadData?.city);
    } */
    if (skip_error) {
      formData.append("skip_errors", true);
    }
    if (!isValidate) {
      setIsLoading(true);
      setErrorMsg(null);
      const apiUrl = importType
        ? `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/import_ahref_backlink_sheet`
        : `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/import_backlink_sheet`;
      api
        .post(apiUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            toast.success("This file import successfully");
            handleModal();
            fetchBacklinksData({
              page: 1,
              perPage: 100,
              filter: [],
            });
            // setIsValidationModal(!isValidationModal);
            // setIsValidate(response?.data);
            // setIsValidationModal(!isValidationModal);
            // if (!response.data.backlink_sheet_validation) {
            //   setErrorMsg(
            //     "This file couldn't be import, please check the summary"
            //   );
            // }
          } else {
            toast.error(
              response.data && typeof response.data === "string"
                ? response.data
                : response.data?.message || "This file couldn't be import."
            );
          }
        })
        .catch((error) => {
          if (
            (error.hasOwnProperty("errors") &&
              (error.errors.hasOwnProperty("ready_status_errors") ||
                error.errors.hasOwnProperty("backlink_type_errors"))) ||
            error?.response?.data.hasOwnProperty("skippable_errors")
          ) {
            if (importType) {
              setImportAhref(true);
            }
            setIsChecked(false);
            setErrorMessage(error?.response?.data);
            setDuplicateDomainModal(true);
          } else {
            toast.error(
              error?.response.data && typeof error?.response.data === "string"
                ? error?.response.data
                : error?.response.data?.message ||
                    "This file couldn't be import."
            );
          }
          console.log(
            "🚀 ~ file: ImportModal.jsx:60 ~ handleSubmit ~ error:",
            error
          );
          setErrorMsg(
            error?.response.data && typeof error?.response.data === "string"
              ? error?.response.data
              : error?.response.data?.message || "This file couldn't be import."
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // else if (isValidate?.backlink_sheet_validation) {
    //   setIsLoading(true);
    //   setErrorMsg(null);
    //   api
    //     .post(
    //       `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/import_backlink_sheet`,
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       handleModal();
    //       toast.success("Import data successfully!", { autoClose: 3000 });
    //       fetchBacklinksData();
    //     })
    //     .catch((error) => {
    //       console.log(
    //         "🚀 ~ file: ImportModal.jsx:60 ~ handleSubmit ~ error:",
    //         error
    //       );
    //       setErrorMsg(error?.response?.data);
    //       toast.error(
    //         error?.response?.data?.message
    //           ? error?.response?.data?.message
    //           : "Couldn't be import",
    //         { autoClose: 3000 }
    //       );
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    // } else if (isValidate && !isValidate?.backlink_sheet_validation) {
    //   setIsValidationModal(!isValidationModal);
    // }
  };
  /*   const fetchCities = async (stateCode) => {
    setIsLoading(true);
    api
      .get(
        `${process.env.REACT_APP_PUBLIC_API}/api/location/cityList?stateCode=${stateCode}`
      )
      .then((response) => {
        setCities(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const cityOptions = Object.entries(cities)?.map(([value, label]) => ({
    value,
    label,
  })); */
  const onDrop = React.useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setExcelFile(files);
    setShowWarning(false);
    setIsValidate(null);
    setErrorMsg(null);
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  const saveBlobAsExcelFile = (blobData, type) => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute(
      "download",
      `${type ? "ahref_backlinks.xlsx" : "backlinks.xlsx"}`
    ); // Set the filename

    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };
  const handleSampleExport = async (type) => {
    /* setIsLoading(true);
    try {
      const res = await api.get(
        `/api/backlinks/export_sample_table${type ? "?type=ahref" : ""}`,
        { responseType: "blob" }
      );
      if (res.status === 200) {
        const blobData = res.data;
        saveBlobAsExcelFile(blobData, type);
        toast.success("Sample export successfully");
      } else {
        toast.error("Sample couldn't be export successfully");
      }
      setIsLoading(false);
    } catch (err) {
      toast.error("Sample couldn't be export successfully");
      setIsLoading(false);
      console.log("🚀 ~ handleSampleExport ~ err:", err);
    } */
  };

  const getClassName = (listing) => {
    let cols = 0;
    if (
      isErrorMessage &&
      isErrorMessage?.skippable_errors?.duplicate_backlinks &&
      isErrorMessage?.skippable_errors?.duplicate_backlinks.length > 0
    ) {
      cols += 1;
    }
    if (
      isErrorMessage &&
      isErrorMessage?.skippable_errors?.existing_backlinks &&
      isErrorMessage?.skippable_errors?.existing_backlinks.length > 0
    ) {
      cols += 1;
    }
    if (
      isErrorMessage &&
      isErrorMessage.errors?.ready_status_errors?.length > 0
    ) {
      cols += 1;
    }
    if (
      isErrorMessage &&
      isErrorMessage.errors?.backlink_type_errors?.length > 0
    ) {
      cols += 1;
    }
    if (listing) {
      return `lg:grid-cols-${cols} grid grid-cols-1 gap-3 dark:text-white/70 mt-1`;
    } else {
      return `lg:grid-cols-${cols} grid gap-3 border-b dark:bg-white/10 dark:rounded-md font-bold mt-5`;
    }
  };
  return (
    <>
      <Modal
        open={false}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        handleModalSubmit={handleSubmit}
        className="max-w-[70%]"
        title={"Import Backlinks"}
        modalType="backlinks_import"
        isLoading={isLoading}
        additionalButtons
        handleSampleExport={(type) => handleSampleExport(type)}
        downloadSample={false}
        handleImportCsv={handleSubmit}
        // buttonText={
        //   isValidate?.backlink_sheet_validation
        //     ? "Save and Update"
        //     : !isValidate
        //     ? "Validate Sheet"
        //     : "Show Summary"
        // }
        buttonText={"Import"}
      >
        <div className="grid gap-4 dark:text-white">
          {/* <div>
          <label htmlFor="roleId" className="inputLabel dark:text-white">
            Type:
          </label>
          <select
            value={payloadData.type}
            onChange={(e) =>
              setPayloadData({ ...payloadData, type: e.target.value })
            }
            name="roleId"
            className={`inputField dark:border-white/20 dark:bg-dark mt-1 ${
              errors.type ? "border-red-500" : ""
            }`}
            error={errors.type}
          >
            <option value="">Select Type</option>
            <option value="local">Local</option>
            <option value="nation">Nation</option>
          </select>
          {errors.type && <div className="text-red-500">{errors.type}</div>}
        </div>
        {payloadData?.type === "local" ? (
          <>
            <div>
              <label htmlFor="roleId" className="inputLabel">
                States:
              </label>
              <select
                value={payloadData.state}
                onChange={(e) => {
                  if (e.target.value) {
                    setPayloadData({
                      ...payloadData,
                      state: e.target.value,
                      city: "",
                    });
                    fetchCities(
                      stateOptions?.find(
                        ({ label }) => label === e.target.value
                      )?.value
                    );
                  } else {
                    setPayloadData({
                      ...payloadData,
                      state: e.target.value,
                      city: "",
                    });
                  }
                }}
                name="state"
                className={`inputField mt-1 ${
                  errors.state ? "border-red-500" : ""
                }`}
                error={errors.state}
              >
                <option value="">Select State</option>
                {stateOptions.map(({ label }, index) => (
                  <option key={index} value={label}>
                    {label}
                  </option>
                ))}
              </select>
              {errors.state && (
                <div className="text-red-500">{errors.state}</div>
              )}
            </div>
            <div>
              <label htmlFor="roleId" className="inputLabel">
                Cities:
              </label>
              <select
                value={payloadData.city}
                onChange={(e) =>
                  setPayloadData({ ...payloadData, city: e.target.value })
                }
                name="roleId"
                className={`inputField mt-1 ${
                  errors.city ? "border-red-500" : ""
                }`}
                error={errors.city}
              >
                <option value="">Select City</option>

                {payloadData?.state &&
                  cityOptions.map(({ label }, index) => (
                    <option key={index} value={label}>
                      {label}
                    </option>
                  ))}
              </select>
              {errors.city && <div className="text-red-500">{errors.city}</div>}
            </div>
          </>
        ) : null} */}
          <div className="flex flex-col justify-center w-full">
            <div
              className={`!mt-3 w-full flex justify-center border-2 border-dashed rounded-md !p-2 hover:border-yellow-500 focus:outline-none ${
                isDragActive ? "bg-gray-100" : "bg-white dark:bg-dark"
              } ${
                errors?.file
                  ? "border-red-500"
                  : "border-gray-200 dark:border-white/20"
              }`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <label
                className={`flex justify-center w-full h-[130px] px-4 transition appearance-none cursor-pointer`}
                onClick={open}
              >
                {excelFile ? (
                  <span className="flex items-center space-x-2">
                    <div className="flex flex-col text-center">
                      <span className="font-medium text-gray-600">
                        File Name: {excelFile?.name}
                      </span>
                      <span className="text-gray-600">
                        Size:{" "}
                        {excelFile?.size > 1000 && excelFile.size < 1000000
                          ? (excelFile.size / 1000).toFixed(2) + " KB"
                          : (excelFile.size / 1000000).toFixed(2) + " MB"}
                      </span>
                    </div>
                  </span>
                ) : (
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Drop files to Attach, or{" "}
                      <span className="!text-yellow-500 underline">browse</span>
                    </span>
                  </span>
                )}
                {/* <input {...getInputProps()} /> */}
              </label>
            </div>
            {errors.file && <div className="text-red-500">{errors.file}</div>}
            {errorMsg && <div className="text-red-500">{errorMsg}</div>}
            {showWarning && (
              <p className="text-red-500 text-center">Please select a file</p>
            )}
          </div>
        </div>

        <Modal
          open={!isValidationModal}
          handleModal={handleValidationModal}
          cancelButtonRef={cancelButtonRef2}
          className="max-w-[90vw] dark:text-white/80 text-sm min-h-[50vh]"
          title={"Duplicated backlinks"}
        >
          <div className="grid md:grid-cols-3 gap-5 font-bold">
            {isValidate?.map((domain) => (
              <p>{domain}</p>
            ))}
            {/* <div className="flex flex-row items-center justify-between shadow">
            <span>Total mismatch type: </span>
            <span>
              {isValidate?.backlink_type_mismatch_domain?.total_count}
            </span>
          </div> */}
            {/* <div className="flex flex-row items-center justify-between shadow">
            <span>Total unable to delete backlink: </span>
            <span>{isValidate?.not_able_to_delete?.total_count}</span>
          </div> */}
            {/* <div className="flex items-center justify-between bg-gray-200 rounded-lg py-2 px-4">
            <p>Total delete backlink request: </p>
            <p>{isValidate?.delete_backlink_domain_request?.total_count}</p>
          </div>
          <div className="flex items-center justify-between bg-gray-200 rounded-lg py-2 px-4">
            <p>Total new backlinks: </p>
            <p>{isValidate?.new_backlink?.total_count}</p>
          </div>
          <div className="flex items-center justify-between bg-gray-200 rounded-lg py-2 px-4">
            <p>Total update backlinks: </p>
            <p>{isValidate?.total_update?.total_count}</p>
          </div> */}
          </div>

          {/* Columns Headings */}
          {/* <div className="grid grid-cols-5 gap-3 border-b dark:bg-white/10 dark:rounded-md font-bold mt-5">
          <div className="flex items-center py-1 pr-5 justify-between">
            <p>Total mismatch type: </p>
            <p>{isValidate?.backlink_type_mismatch_domain?.total_count}</p>
          </div>
          <div className="flex items-center py-1 pr-5 justify-between">
            <p>Total unable to delete backlink: </p>
            <p>{isValidate?.not_able_to_delete?.total_count}</p>
          </div>
          <div className="flex items-center py-1 pr-5 justify-between">
            <p>Follow status mismatch: </p>
            <p>
              {isValidate?.backlink_follow_status_mismatch_domain?.total_count}
            </p>
          </div>
          <div className="flex items-center py-1 pr-5 justify-between">
            <p>Ready status mismatch: </p>
            <p>
              {isValidate?.backlink_ready_status_mismatch_domain?.total_count}
            </p>
          </div>
          <div className="flex items-center py-1 pr-5 justify-between">
            <p>Duplicate Backlink: </p>
            <p>{isValidate?.backlink_duplicate_domain?.total_count}</p>
          </div>
        </div> */}

          {/* Listings */}
          {/* <div className="grid grid-cols-1 md:grid-cols-5 gap-3 dark:text-white/70 mt-1">
          <div className="flex flex-col h-96 overflow-y-scroll pt-1">
            {isValidate?.backlink_type_mismatch_domain?.domains
              ?.split(",")
              ?.map((item, index) => {
                return (
                  <p key={index} className="px-2 flex items-center">
                    <span className="w-7">{index + 1}.</span> {item}
                  </p>
                );
              })}
          </div>

          <div className="flex flex-col h-96 overflow-y-scroll pt-1">
            {isValidate?.not_able_to_delete?.backlink_domain_list
              ?.split(",")
              ?.map((item, index) => {
                return (
                  <p key={index} className="px-2 flex items-center">
                    <span className="w-7">{index + 1}.</span> {item}
                  </p>
                );
              })}
          </div>

          <div className="flex flex-col h-96 overflow-y-scroll pt-1">
            {isValidate?.backlink_follow_status_mismatch_domain?.domains
              ?.split(",")
              ?.map((item, index) => {
                return (
                  <p key={index} className="px-2 flex items-center">
                    <span className="w-7">{index + 1}.</span> {item}
                  </p>
                );
              })}
          </div>

          <div className="flex flex-col h-96 overflow-y-scroll pt-1">
            {isValidate?.backlink_ready_status_mismatch_domain?.domains
              ?.split(",")
              ?.map((item, index) => {
                return (
                  <p key={index} className="px-2 flex items-center">
                    <span className="w-7">{index + 1}.</span> {item}
                  </p>
                );
              })}
          </div>

          <div className="flex flex-col h-96 overflow-y-scroll pt-1">
            {isValidate?.backlink_duplicate_domain?.domains
              ?.split(",")
              ?.map((item, index) => {
                return (
                  <p key={index} className="px-2 flex items-center">
                    <span className="w-7">{index + 1}.</span> {item}
                  </p>
                );
              })}
          </div>
        </div> */}
        </Modal>
        <Modal
          open={!isDuplicateDomainModal}
          handleModal={handleDuplicateDomainModal}
          cancelButtonRef={cancelButtonRef3}
          className="max-w-8xl  min-h-[90vh]"
          title={
            <span className="text-red-500 text-sm">
              There are some errors in the excel.
            </span>
          }
        >
          <div className={getClassName(false)}>
            {isErrorMessage?.skippable_errors?.duplicate_backlinks &&
              isErrorMessage?.skippable_errors?.duplicate_backlinks.length >
                0 && (
                <div className="flex items-center py-1 pr-5 justify-between">
                  <p>Duplicate Backlinks</p>
                </div>
              )}
            {isErrorMessage?.skippable_errors?.existing_backlinks &&
              isErrorMessage?.skippable_errors?.existing_backlinks.length >
                0 && (
                <div className="flex items-center py-1 pr-5 justify-between">
                  <p>Existing Backlinks </p>
                </div>
              )}
            {isErrorMessage &&
              isErrorMessage.errors?.ready_status_errors?.length > 0 && (
                <div className="flex items-center py-1 pr-5 justify-between">
                  <p>Invalid Ready Status: </p>
                </div>
              )}
            {isErrorMessage &&
              isErrorMessage.errors?.backlink_type_errors?.length > 0 && (
                <div className="flex items-center py-1 pr-5 justify-between">
                  <p>Invalide Backlink Type: </p>
                </div>
              )}
          </div>
          {/* Listings */}
          <div className={getClassName(true)}>
            {isErrorMessage &&
              isErrorMessage?.skippable_errors?.duplicate_backlinks &&
              isErrorMessage?.skippable_errors?.duplicate_backlinks.length >
                0 && (
                <div className="flex flex-col h-96 overflow-y-auto pt-1 border border-gray-200 border-t-0 text-sm">
                  {isErrorMessage &&
                    isErrorMessage?.skippable_errors?.duplicate_backlinks.map(
                      (dupBacklink, index) => (
                        <p key={index} className="px-1 flex items-center">
                          <span className="text-left pr-2">{index + 1}.</span>{" "}
                          {dupBacklink}
                        </p>
                      )
                    )}
                </div>
              )}
            {isErrorMessage &&
              isErrorMessage?.skippable_errors?.existing_backlinks &&
              isErrorMessage?.skippable_errors?.existing_backlinks.length >
                0 && (
                <div className="flex flex-col h-96 overflow-y-auto pt-1 border border-gray-200 border-t-0 text-sm">
                  {isErrorMessage &&
                    isErrorMessage?.skippable_errors?.existing_backlinks.map(
                      (exiBacklink, index) => (
                        <p key={index} className="px-1 flex items-center">
                          <span className="text-left pr-2">{index + 1}.</span>{" "}
                          {exiBacklink}
                        </p>
                      )
                    )}
                </div>
              )}
            {isErrorMessage &&
              isErrorMessage.errors?.ready_status_errors?.length > 0 && (
                <div className="flex flex-col h-96 overflow-y-auto pt-1 border border-gray-200 border-t-0 text-sm">
                  {isErrorMessage &&
                    Object.keys(isErrorMessage.errors).length > 0 &&
                    isErrorMessage.errors?.ready_status_errors?.map(
                      (error, index) => (
                        <p key={index} className="px-1 flex items-center">
                          <span className="text-left pr-1">{index + 1}.</span>
                          {Object.entries(error).map(([key, value], idx) => (
                            <>
                              <span>
                                <span className="font-semibold">
                                  {key
                                    .replace(/_/g, " ")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                </span>
                                : {value}
                              </span>
                              {idx !== Object.keys(error).length - 1 && (
                                <span className="px-1">|</span>
                              )}
                            </>
                          ))}
                        </p>
                      )
                    )}
                </div>
              )}
            {isErrorMessage &&
              isErrorMessage.errors.backlink_type_errors?.length > 0 && (
                <div className="flex flex-col h-96 overflow-y-auto pt-1 border border-gray-200 border-t-0 text-sm">
                  {isErrorMessage &&
                    Object.keys(isErrorMessage.errors).length > 0 &&
                    isErrorMessage.errors?.backlink_type_errors?.map(
                      (error, index) => (
                        <p key={index} className="px-1 flex items-center">
                          <span className="text-left pr-1">{index + 1}.</span>
                          {Object.entries(error).map(([key, value], idx) => (
                            <>
                              <span>
                                <span className="font-semibold">
                                  {key
                                    .replace(/_/g, " ")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                </span>
                                : {value}
                              </span>
                              {idx !== Object.keys(error).length - 1 && (
                                <span className="px-1">|</span>
                              )}
                            </>
                          ))}
                        </p>
                      )
                    )}
                </div>
              )}
          </div>
          {isErrorMessage &&
            Object.keys(isErrorMessage.errors).length === 0 && (
              <>
                <div className="mt-4 p-2">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 mr-2"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <span className="font-normal">
                      Do you want to Skip Errors ?
                    </span>
                  </label>
                </div>

                <div className="flex items-cente justify-end mt-4 space-x-2 text-white px-4">
                  <button
                    onClick={(e) => handleSubmit(e, true, isImportAhref)}
                    className="btnPrimary bg-green-600"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btnPrimary bg-red-500"
                    onClick={() => setDuplicateDomainModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
        </Modal>
      </Modal>
    </>
  );
}

export default ImportModal;
