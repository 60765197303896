import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CheckBox, DataTable } from "../../components";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import Checkbox from "../../components/common/CheckBox";

export default function UpdateUserDomainLinks() {
  const { user, group, batch, domain } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [groupListBatch, setGroupListBatch] = useState([]);
  const [backlinks, setBacklinks] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [myUser, setMyUser] = useState({});
  const [myGroup, setMyGroup] = useState({});
  const [myBatch, setMyBatch] = useState({});
  const [myDomain, setMyDomain] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  // Fetch users list
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getBacklinks = async () => {
    const response = await api.get(
      `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/groupAssignedBacklinkList/${myGroup.id}`
    );
    setBacklinks(response.data);
  };

  const filterBacklinks = () => {
    const filteredBacklinks = backlinks?.backlinksData?.filter((backlink) =>
      myDomain?.backlink_ids?.split(",")?.includes(backlink.id.toString())
    );
    setSelectedData(filteredBacklinks);
    setIsLoading(false);
  };

  const fetchGroupListHasBatch = () => {
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/groupNameListHasBatch/`,
        {
          user_id: myUser.id,
          level: myUser.role_id.level,
        }
      )
      .then((response) => {
        setGroupListBatch(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const findMyUser = () => {
    const foundUser = users?.users?.find((item) => item.name === user);
    if (foundUser) {
      setMyUser(foundUser);
    }
  };

  const findMyGroup = () => {
    const foundGroup = groupListBatch?.groupList?.find(
      (item) => item.group_name === group
    );
    if (foundGroup) {
      setMyGroup(foundGroup);
    }
  };

  const findMyBatch = () => {
    const foundBatch = groupLinkBuilderBatchList?.groupListbatch?.find(
      (item) => item.name === batch
    );
    if (foundBatch) {
      setMyBatch(foundBatch);
    }
  };

  const findMyDomain = () => {
    const foundDomain = domainList?.find((item) => item.domain === domain);
    if (foundDomain) {
      setMyDomain(foundDomain);
    }
  };

  const [groupLinkBuilderBatchList, setGroupLinkbuilderBatchList] = useState(
    {}
  );
  const getGroupLinkbuilderBatchList = async () => {
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/groupLinkbuilderBatchList`,
        {
          user_id: myUser.id,
          group_id: myGroup.id,
        }
      )
      .then((response) => {
        setGroupLinkbuilderBatchList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGroupDomainList = () => {
    api
      .get(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/batchDomainList/${myBatch.id}`
      )
      .then((res) => {
        if (res.data.length > 0) {
          setDomainList(res.data);
        } else {
          toast.error("no domains assigned");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Find user from users list to get user id.
  useEffect(() => {
    if (users?.totalCount > 0) {
      findMyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  // get groups list if you have user
  useEffect(() => {
    if (myUser?.name) {
      fetchGroupListHasBatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser]);

  // Find group from group list
  useEffect(() => {
    if (groupListBatch?.totalCount > 0) {
      findMyGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupListBatch]);

  // Get group batches list if you have group
  useEffect(() => {
    if (myGroup?.group_name) {
      getGroupLinkbuilderBatchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myGroup]);

  // Find batch from batches list
  useEffect(() => {
    if (groupLinkBuilderBatchList?.totalCount > 0) {
      findMyBatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupLinkBuilderBatchList]);

  // Get Backlinks List
  useEffect(() => {
    if (myGroup?.group_name) {
      getBacklinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myGroup]);

  // Get domains list if you have batch
  useEffect(() => {
    if (myBatch?.name) {
      getGroupDomainList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBatch]);

  // Find domain from domains list
  useEffect(() => {
    if (domainList.length > 0) {
      findMyDomain();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainList]);

  // Filter backlinks using ids
  useEffect(() => {
    if (myDomain?.domain) {
      filterBacklinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myDomain]);

  const onSingleselect = (val) => {
    if (selectedData?.length > 0) {
      if (
        selectedData.filter(
          ({ backlink_domain }) => backlink_domain === val.backlink_domain
        ).length > 0
      ) {
        const arr = selectedData.filter(
          ({ backlink_domain }) => backlink_domain !== val.backlink_domain
        );
        setSelectedData(arr);
      } else {
        setSelectedData([
          ...selectedData,
          {
            backlink_id: val.id,
            backlink_domain: val.backlink_domain,
            signup_url: val.signup_url,
          },
        ]);
      }
    } else {
      setSelectedData([
        ...selectedData,
        {
          backlink_id: val.id,
          backlink_domain: val.backlink_domain,
          signup_url: val.signup_url,
        },
      ]);
    }
  };

  const handleSubmit = () => {
    const batch = {
      id: myDomain?.id?.toString(),
      backlink_domain_ids: selectedData.map(
        (item) => item.id || item.backlink_id
      ),
    };
    setIsLoading(true);
    api
      .put(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/updateBacklinkToBatchDomain`,
        batch
      )
      .then(() => {
        toast.success("Action was successful!", { autoClose: 3000 });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const findAssignedLink = (backlink) => {
    if (
      selectedData?.some(
        (link) => link.backlink_domain === backlink.backlink_domain
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const selectAll = (isChecked) => {
    const allBacklinks = backlinks?.backlinksData || [];

    if (isChecked.target.checked) {
      setSelectedData(allBacklinks);
    } else {
      setSelectedData([]);
    }
  };

  const areAllSelected =
    selectedData?.length === backlinks?.backlinksData?.length;

  return (
    <div className="p-7">
      <DataTable
        searchBox
        handleSubmit={handleSubmit}
        title={
          <>
            backlinks of <span className="lowercase">{domain}</span>{" "}
            <span className="px-4 py-2 ml-2 rounded-full bg-gray-200 dark:bg-white/10 font-medium text-lg">
              Total Assigned = {myDomain?.domain_assign_backlink}
            </span>
          </>
        }
        isLoading={isLoading}
        heads={[
          <Checkbox
            checked={areAllSelected}
            onChange={(isChecked) => selectAll(isChecked)}
          />,
          "domain",
        ]}
        items={backlinks?.backlinksData?.map((backlink, index) => ({
          check: (
            <CheckBox
              key={index}
              checked={findAssignedLink(backlink)}
              onChange={() => onSingleselect(backlink)}
            />
          ),
          label: backlink.backlink_domain,
        }))}
      />
    </div>
  );
}
