import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMerchants } from "../../services/merchantService";
const initialState = {
  isError: false,
  isLoading: false,
  merchants: [],
  message: "",
  errors: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Merchants
export const get_merchants = createAsyncThunk(
  "get_merchants",
  async (data, thunkAPI) => {
    try {
      return await getMerchants(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const merchantsSlice = createSlice({
  name: "getMerchants",
  initialState,
  reducers: {
    merchantReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.message = "";
      state.merchants = [];
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_merchants.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_merchants.fulfilled, (state, action) => {
        state.isLoading = false;
        state.merchants = action.payload?.data;
      })
      .addCase(get_merchants.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.merchants = [];
      });
  },
});

export const { merchantReset } = merchantsSlice.actions;
export default merchantsSlice.reducer;
