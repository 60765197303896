import React from "react";
import { DataTable } from "../../components";

export default function DomainListOfUserBatch({ domainList, getDomainReport }) {
  return (
    <DataTable
      className="px-0 py-0 "
      heads={["Sr#", "domain", "domain report"]}
      items={domainList?.map((domain, index) => ({
        serialNumber: index + 1,
        domain: domain["domain"],
        action: (
          <p onClick={() => getDomainReport(domain)} className="btnSecondary">
            Domain Report
          </p>
        ),
      }))}
    />
  );
}
