import { CheckCircleIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import api from "../../services/api";
import {
  MdAddCircleOutline,
  MdCancel,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { Button } from "@mui/material";
import { Circle } from "@mui/icons-material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MUIDataTable from "../../components/MUIGrid";

function AssignedBacklinksList({
  selectedBatch,
  handleModal,
  setSelectedBatches,
  selectedBatches,
}) {
  //const [backlinks, setBacklinks] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [backlinksData, setBacklinksData] = useState([]);
  //const [selectedFilter, setSelectedFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const queryOptions = {
    groupOp: "",
    rules: [],
  };
  /*  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  }); */
  const [selectedBacklinks, setSelectedBacklinks] = useState(
    selectedBatch?.backlinks
  );
  // Modal
  //const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });

  /* const getBacklinkTypeList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }; */

  useEffect(() => {
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      /*  filter: selectedFilter?.map((filter) => [
        filter.filter_key,
        filter?.filter_type[filter.selectedType],
        filter.selectedValue,
      ]), */
    });
    // getBacklinkTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBacklinksData = async (payload) => {
    setIsLoading(true);
    setBacklinksData([]);
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/backlinks/`, payload)
      .then((response) => {
        //setBacklinks(response.data);

        setBacklinksData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;

  const rows = backlinksData.backlinksData?.map((record, index) => {
    const counter = offset + index + 1;
    const records = record;
    const {
      "b.id": id,
      "b.backlink_domain": backlink_domain,
      "b.city": city,
      "b.state": state,
      "b.source_domains_list": source_domains_list,
      "b.backlink_rank": backlink_rank,
      "b.type": type,
      "b.domain_rating": domain_rating,
      intersect_count,
    } = record;
    return {
      counter,
      records,
      "b.id": id,
      "b.backlink_domain": backlink_domain,
      "b.city": city,
      "b.state": state,
      "b.source_domains_list": source_domains_list,
      "b.backlink_rank": backlink_rank,
      "b.type": type,
      "b.domain_rating": domain_rating,
      intersect_count,
    };
  });

  useEffect(() => {
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };

  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchBacklinksData({
      filters: queryOptions,
      page: +params.page + 1,
      perPage: params.pageSize,
    });
  };

  const isSelected = (backlinkId) => {
    if (selectedBacklinks?.length > 0) {
      if (
        selectedBacklinks?.filter((item) => item["b.id"] === backlinkId)
          .length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  // Assign To All Check Button
  const [assignToAll, setAssignToAll] = useState(false);
  const handleAssignToAll = (e) => {
    e.preventDefault();
    setAssignToAll(!assignToAll);
  };

  const onSingleSelect = ({ checked, data }) => {
    if (checked) {
      setSelectedBacklinks([...selectedBacklinks, data]);
    } else {
      setSelectedBacklinks(
        selectedBacklinks?.filter((item) => item["b.id"] !== data["b.id"])
      );
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = backlinksData?.backlinksData?.map((item) => item);
      setSelectedBacklinks(updatedArray);
    } else {
      setSelectedBacklinks([]);
    }
  };
  const assignBacklinks = (e) => {
    e.preventDefault();

    if (assignToAll) {
      let prevData = [...selectedBatches];
      prevData = prevData.map((item) => {
        return {
          ...item,
          backlinks: selectedBacklinks,
        };
      });
      setSelectedBatches(prevData);
    } else {
      let prevData = [...selectedBatches];
      prevData = prevData?.map((item) => {
        if (item.title === selectedBatch?.title) {
          return {
            ...item,
            backlinks: selectedBacklinks,
          };
        }
        return item;
      });
      setSelectedBatches(prevData);
    }
    handleModal();
  };
  /*   const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    fetchBacklinksData({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  }; */

  /*  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          // data: rule.value ? rule.value : null,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    // ?.toLowerCase()?.includes(searchInput?.toLowerCase())
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
  }, []); */

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3">
        <GridToolbarColumnsButton
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
        <GridToolbarDensitySelector
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
        <GridToolbarFilterButton
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
          ref={setFilterButtonEl}
        />
        <Button
          variant="text"
          onClick={assignBacklinks}
          disabled={!selectedBacklinks?.length}
          startIcon={<MdAddCircleOutline size={16} />}
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            fontSize: "0.8125rem",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          Assign
        </Button>
        <Button
          variant="text"
          onClick={handleAssignToAll}
          startIcon={<MdKeyboardArrowRight size={16} />}
          className={`dark:bg-gray-800 active:scale-105 cursor-pointer transition-all flex items-center gap-2 whitespace-nowrap ${
            assignToAll && "bg-secondary dark:bg-secondary text-white"
          }`}
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            fontSize: "0.8125rem",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          Assign to all batches{" "}
          {assignToAll ? (
            <CheckCircleIcon className="w-6" />
          ) : (
            <Circle className="text-gray-300 dark:text-gray-500" />
          )}
        </Button>
        <Button
          variant="text"
          onClick={handleModal}
          startIcon={<MdCancel size={16} />}
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            fontSize: "0.8125rem",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          Cancel
        </Button>
        {/*   <Button
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            fontSize: "0.8125rem",
            paddingLeft: 1,
            paddingRight: 1,
          }}
          // className="!text-[#042a42]"
        >
          Apply filter
        </Button> */}
      </GridToolbarContainer>
    );
  }

  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            backlinksData?.backlinksData?.length > 0 &&
            selectedBacklinks?.length === backlinksData?.backlinksData?.length
          }
        />
      ),
      filterable: false,
      sortable: false,
      width: 50,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={
            params.row.records.id || isSelected(params.row.records["b.id"])
          }
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
    },
    { headerName: "Sr#", field: "counter", width: 60, filterable: false },
    {
      headerName: "Backlink",
      field: "b.backlink_domain",
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "City",
      field: "b.city",
      flex: 1,
      minWidth: 120,
      filterable: false,
    },
    {
      headerName: "State",
      field: "b.state",
      width: 100,
      flex: 1,
      filterable: false,
    },
    {
      headerName: "Source Domain",
      field: "b.ource_domains_list",
      minWidth: 150,
      flex: 1,
      filterable: false,
    },
    {
      headerName: "Location Type",
      field: "b.backlink_location_type",
      flex: 1,
      minWidth: 120,
      filterable: true,
    },

    {
      field: "b.backlink_rank",
      headerName: "Backlink Rank",
      minWidth: 200,
      filterable: false,
    },
    {
      field: "b.type",
      headerName: "Backlink Type",
      minWidth: 200,
    },
    {
      headerName: "Domain Rating",
      field: "b.domain_rating",
      minWidth: 150,
      filterable: false,
    },
  ];

  const getRowClass = (params) => {
    if (params.row.status === "Completed") {
      return "bg-green-200 dark:bg-green-500/40";
    }
  };

  return (
    <div className="px-2 pt-0">
      <ToastContainer />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          isLoading={isLoading}
          columnDefs={columnDefs}
          items={rows}
          totalItems={backlinksData?.totalCount}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          CustomToolbar={CustomToolbar}
          pagination="Yes"
          height="74vh"
          searchable="No"
          getRowClass={getRowClass}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          // onFilterModelChange={onFilterChange}
        />
      </div>
    </div>
  );
}

export default AssignedBacklinksList;
