import React, { useEffect, useRef, useState } from "react";
import api from "../../services/api";
import { Modal, TextArea } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import CustomDataTable from "../../components/common/CustomDataTable";

export default function BacklinksContent() {
  const [isLoading, setIsLoading] = useState(false);
  const [contentTypeId, setContentTypeId] = useState("");
  const [content, setContent] = useState("");
  const [contentId, setContentId] = useState("");
  const [backlinksContentList, setBacklinksContentList] = useState([]);
  const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const [errors, setErrors] = useState({});
  const [expandedRow, setExpandedRow] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);

  const getBacklinkContentList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_content`)
      .then((res) => setBacklinksContentList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const getBacklinkTypeList = () => {
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getBacklinkContentList();
    getBacklinkTypeList();
  }, []);

  const [modalType, setModalType] = useState("");
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleNewContentType = () => {
    setModalType("new");
    setContentTypeId("");
    setContent("");
    setErrors({});
    handleModal();
  };

  const createNewContent = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});
    const errors = {};
    if (!content) {
      errors.content = "Please enter the content.";
    }
    if (!contentTypeId) {
      errors.backlink_type_id = "Please enter the backlink type.";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }

    api
      .post(`/api/backlinks/backlink_content`, {
        backlink_type_id: contentTypeId,
        content: content,
      })
      .then((res) => {
        toast.success("backlink content created.");
        getBacklinkContentList();
      })
      .catch((err) => toast.error(err.response.data.message));
    setContentTypeId("");
    handleModal("");
    setIsLoading(false);
  };

  //   Update Content Type
  const handleUpdateContent = (item) => {
    setErrors({});
    setContentTypeId(item?.backlink_type_id);
    setContent(item?.content);
    setContentId(item?.id);
    setModalType("update");
    handleModal();
  };
  const updateContent = (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .put(`/api/backlinks/backlink_content/${contentId}`, {
        backlink_type_id: contentTypeId,
        content: content,
      })
      .then((res) => {
        toast.success("backlink content updated.");
        getBacklinkContentList();
        setContentTypeId("");
      })
      .catch((err) => toast.error(err.response.data.message));
    handleModal("");
    setIsLoading(false);
  };

  //   Delete Type
  const [confirm, setConfirm] = useState("");
  const deleteContent = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    if (!isConfirmed) {
      return;
    }
    setIsLoading(true);
    api
      .delete(`/api/backlinks/backlink_content/${id}`)
      .then((res) => {
        toast.success("backlink content removed!");
        getBacklinkContentList();
      })
      .catch((err) => toast.error(err.response.data.message));
    setConfirm(false);
    setIsLoading(false);
  };

  function groupBacklinks(backlinksContentList) {
    let groupedData = {};
    for (let i = 0; i < backlinksContentList.length; i++) {
      let currentItem = backlinksContentList[i];
      let typeId = currentItem.backlink_type_id;

      if (groupedData[typeId]) {
        groupedData[typeId].child_list.push(currentItem);
      } else {
        groupedData[typeId] = {
          id: currentItem.backlink_type_id,
          content: currentItem.content,
          created_at: currentItem.created_at,
          backlink_type_id: typeId,
          child_list: [currentItem],
        };
      }
    }
    return groupedData;
  }
  let groupedData = groupBacklinks(backlinksContentList);
  const handleRowClick = async (itemId) => {
    if (expandedRow === itemId?.id) {
      setExpandedRow(null);
      setAdditionalData([]);
    } else {
      setIsLoading(true);
      if (itemId?.child_list) {
        setAdditionalData(itemId?.child_list);
        setExpandedRow(itemId?.id);
      }
      setIsLoading(false);
    }
  };
  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Backlinks Content", link: "" },
        ]}
      />
      {isLoading && <Loading />}
      <ToastContainer />
      <CustomDataTable
        extras={
          userPermission("Add Backlink Content") ? (
            <button onClick={handleNewContentType} className="btnPrimary mt-2">
              Create New
            </button>
          ) : null
        }
        searchBox
        totalRecords={Object.values(groupedData)?.length}
        tdClass={"!whitespace-normal text-wrap"}
        heads={[" ", "Sr#", "Backlink Type", "Content"]}
        items={Object.values(groupedData)?.map((item, index) => ({
          id: item.id,
          serialnumber: index + 1,
          child_list: item.child_list,
          type:
            backlinksTypeList?.find((list) => list.id === item.backlink_type_id)
              ?.type || null,
          content: <div className="max-w-lg">{"Read more..."}</div>,
          actions: (
            <div
              className={
                confirm === index
                  ? "relative flex items-center gap-3 text-gray-500 max-w-md"
                  : " flex items-center gap-3 text-gray-500 max-w-md"
              }
            >
              {userPermission("Delete Backlink Content") ? (
                <TrashIcon
                  onClick={() => deleteContent(item.id)}
                  className="w-5 cursor-pointer"
                />
              ) : null}
              {userPermission("Edit Backlink Content") ? (
                <PencilIcon
                  onClick={() => handleUpdateContent(item)}
                  className="w-5 cursor-pointer"
                />
              ) : null}
            </div>
          ),
        }))}
        rowClass="flex"
        cellClass="flex-1"
        onRowClick={handleRowClick}
        expandedRow={expandedRow}
        additionalData={additionalData}
        excludeKeys={["id", "child_list",'actions']}
        childActions={true}
        userPermission={userPermission}
        handleUpdateContent={handleUpdateContent}
        deleteContent={deleteContent}
      />
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-2xl"
        title={
          (modalType === "update" && "Update Content") ||
          (modalType === "new" && "Create New Content")
        }
        handleModalSubmit={
          (modalType === "update" && updateContent) ||
          (modalType === "new" && createNewContent)
        }
        modalType="new"
      >
        <div className="grid md:grid-cols-2 gap-4 ">
          <div>
            <label htmlFor="backlinkTypeId" className="inputLabel w-full">
              Backlink type:
            </label>
            <select
              value={contentTypeId}
              onChange={(e) => setContentTypeId(e.target.value)}
              name="backlink_type_id"
              className={`inputField mt-1 ${
                errors.backlink_type_id ? "border-red-500" : ""
              }`}
              error={errors.backlink_type_id}
            >
              <option value="">Select Backlink type</option>
              {backlinksTypeList?.map(({ id, type }) => (
                <option key={id} value={id}>
                  {type}
                </option>
              ))}
            </select>
            {errors.backlink_type_id && (
              <div className="text-red-500">{errors.backlink_type_id}</div>
            )}
          </div>
          <TextArea
            label="Content"
            name="content"
            placeholder="Enter new content"
            type="text"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={3}
            className="col-span-2"
            error={errors.content}
          />
        </div>
      </Modal>
    </div>
  );
}
