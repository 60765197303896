import React, { useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
export default function TaskStatus({
  currentStatus,
  updateStatus,
  statusList,
  handleStatus,
  setStatusList,
  setIsUrlModal,
  setTaskStatus,
  setErrors,
}) {
  const statusRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setStatusList(false);
    }
  };
  const handleClickOutside = (event) => {
    if (statusRef.current && !statusRef.current.contains(event.target)) {
      setStatusList(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "inProcess", label: "In Process" },
    { value: "inReview", label: "In Review" },
    { value: "completed", label: "Completed" },
    { value: "hold", label: "Hold" },
    { value: "cancelled", label: "Cancelled" },
    { value: "reOpen", label: "Reopen" },
  ];
  const handleInreviewToPending = (currentStatus) => {
    setErrors({});
    if (currentStatus === "inReview") {
      setIsUrlModal(true);
      setTaskStatus({
        status: "pending",
      });
    } else {
      updateStatus("pending");
    }
  };

  return (
    <div className="relative" ref={statusRef}>
      {statusList && (
        <div className="flex flex-col w-[180px] absolute top-0 right-0 mt-[55px] p-2 bg-white dark:bg-blue rounded-md capitalize shadow-2xl shadow-black/25">
          <button
            onClick={() => handleInreviewToPending(currentStatus)}
            className={`py-2 px-4 hover:bg-gray-100 dark:hover:bg-white/20 text-red-600 dark:text-red-400 rounded ${
              currentStatus === "pending" && "bg-red-100 dark:bg-red-500/30"
            }`}
          >
            Pending
          </button>
          <button
            onClick={() => {
              updateStatus("inProcess");
            }}
            className={`py-2 px-4 hover:bg-gray-100 dark:hover:bg-white/20 text-indigo-600 dark:text-indigo-300 rounded ${
              currentStatus === "inProcess" &&
              "bg-indigo-100 dark:bg-indigo-500/30"
            }`}
          >
            In Process
          </button>
          <button
            onClick={() => {
              updateStatus("inReview");
            }}
            className={`py-2 px-4 hover:bg-gray-100 dark:hover:bg-white/20 text-gray-600 dark:text-gray-300 rounded ${
              currentStatus === "inReview" && "bg-gray-100 dark:bg-gray-500/50"
            }`}
          >
            In Review
          </button>
          <button
            onClick={() => {
              setIsUrlModal(true);
              setErrors({});
              setTaskStatus({
                status: "hold",
              });
            }}
            className={`py-2 px-4 hover:bg-gray-100 dark:hover:bg-white/20 text-gray-600 dark:text-gray-300 rounded ${
              currentStatus === "hold" && "bg-gray-100 dark:bg-gray-500/50"
            }`}
          >
            Hold
          </button>
          <button
            onClick={() => {
              setIsUrlModal(true);
              setErrors({});
              setTaskStatus({
                status: "cancelled",
              });
            }}
            className={`py-2 px-4 hover:bg-gray-100 dark:hover:bg-white/20 text-red-600 dark:text-red-400 rounded ${
              currentStatus === "cancelled" && "bg-gray-100 dark:bg-gray-500/50"
            }`}
          >
            Cancelled
          </button>
          <button
            onClick={() => {
              updateStatus("completed");
            }}
            className={`py-2 px-4 hover:bg-gray-100 dark:hover:bg-white/20 text-green-800 dark:text-green-300 rounded ${
              currentStatus === "completed" &&
              "bg-green-100 dark:bg-green-500/30"
            }`}
          >
            Completed
          </button>
          <button
            onClick={() => {
              setIsUrlModal(true);
              setErrors({});
              setTaskStatus({
                status: "reOpen",
              });
            }}
            className={`py-2 px-4 hover:bg-gray-100 dark:hover:bg-white/20 text-indigo-600 dark:text-gray-300 rounded ${
              currentStatus === "reOpen" && "bg-gray-100 dark:bg-gray-500/50"
            }`}
          >
            Reopen
          </button>
        </div>
      )}
      <button
        onClick={handleStatus}
        className="card py-3 flex items-center gap-2 capitalize font-bold text-secondary active:scale-105 transition-all"
      >
        {currentStatus
          ? statusOptions?.find((option) => option.value === currentStatus)
              .label
          : ""}
        <ChevronDownIcon className="w-5" />
      </button>
    </div>
  );
}
