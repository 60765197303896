import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import MUIDataTable from "../../components/MUIGrid";
import { Button, Tooltip, TablePagination } from "@mui/material";
import { FaSearch, FaSave, FaCheck } from "react-icons/fa";
import Loading from "../../components/Loader/Loading";
import { Modal } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import BacklinkAgreeForm from "./BacklinkAgreeForm";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { useSelector } from "react-redux";
import SaveFilterModal from "./SaveFilterModal";
import FiltersDropdown from "./FiltersDropdown";
import _ from "lodash";
export default function AssignBacklinks() {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const selectedDomains = location?.state?.selectedDomains;
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [userdata, setUser] = useState("");
  const [backlinksData, setBacklinksData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [modalType, setModalType] = useState("");
  const [agreeData, setAgreeData] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [nicheProductList, setNicheProductList] = useState([]);
  const [showCategoryTooltip, setCategoryShowTooltip] = useState(null);
  const [showLocationTooltip, setLocationShowTooltip] = useState(null);
  const navigate = useNavigate();
  const [toggleStates, setToggleStates] = useState({});
  const [savedFilters, setSavedFilters] = useState(null);
  const [isFilterSave, setIsFilterSave] = useState(false);
  const [editingRecord, setEditingRecord] = useState({});
  const [queryOptions, setQueryOptions] = useState({
    groupOp: "AND",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const [backlinksStatusList, setBacklinksStatusList] = useState([]);
  const followStatus = [
    { value: "Nofollow", label: "Nofollow" },
    { value: "Dofollow", label: "Dofollow" },
    { value: "Pending", label: "Pending" },
  ];
  const locationTypes = [
    { value: "local", label: "Local" },
    { value: "national", label: "National" },
  ];
  const get_user = async () => {
    const filters = {
      groupOp: "AND",
      rules: [
        { field: "is_active", op: "eq", data: 1 },
        { field: "role_id", op: "isAnyOf", data: [3, 8] },
      ],
    };
    try {
      const res = await api.post("/api/users/users_report", {
        filters,
        page: 1,
        perPage: 1000,
      });
      if (res.status === 200) {
        setUsers(res.data?.records);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  const fetchBacklinksData = async (payload) => {
    setIsLoading(true);
    setBacklinksData([]);
    payload.user_id = user?.user?.id;
    payload.is_assigned = true;
    if (location?.state?.domain_id) {
      payload.domain_id = +location?.state?.domain_id;
    }
    payload.batch_id = location?.state?.selectedBatch?.id || "";
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/backlinks/`, payload)
      .then((response) => {
        setBacklinksData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    get_user();
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: queryOptions,
    });
    get_filters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const getBacklinkStatusList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_status_list`)
      .then((res) => setBacklinksStatusList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  //   Get Type List
  const getBacklinkTypeList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const getNicheProductList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/niche_products`)
      .then((res) => setNicheProductList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getBacklinkTypeList();
    getBacklinkStatusList();
    getNicheProductList();
  }, []);

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = backlinksData?.backlinksData?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const isSelected = (domain_id) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter((item) => item["b.id"] === domain_id).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const onSingleSelect = ({ checked, data }) => {
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(
        selectedData?.filter((item) => item["b.id"] !== data["b.id"])
      );
    }
  };

  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            backlinksData?.backlinksData?.length &&
            backlinksData?.backlinksData?.length === selectedData?.length
          }
        />
      ),
      filterable: false,
      sortable: false,
      minWidth: 30,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={
            params.row.records.population_status ||
            isSelected(params.row.records["b.id"])
          }
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
    },
    { field: "counter", headerName: "Sr#", filterable: false, width: 60 },
    {
      field: "b.backlink_domain",
      headerName: "Backlink Domain",
      minWidth: 180,
      editable: true,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row["b.backlink_domain"]}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row["b.backlink_domain"]}
          </a>
        );
      },
    },
    {
      field: "b.backlink_type_id",
      headerName: "Backlink Type",
      minWidth: 150,
      editable: true,
      align: "left",
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.type,
      valueOptions: backlinksTypeList,
      renderCell: (params) => params.value["b.type"],
      valueGetter: (params) => params.row["b.type"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "b.ready_status_id",
      headerName: "Backlink Ready Status",
      minWidth: 100,
      flex: 1,
      editable: true,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.ready_status,
      valueOptions: backlinksStatusList,
      renderCell: (params) => params.value["b.ready_status"],
      valueGetter: (params) => params.row["b.ready_status"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "b.good_to_assign",
      headerName: "Good To Assign",
      width: 120,
      editable: false,
      type: "singleSelect",
      minWidth: 60,
      disableColumnMenu: true,
      align: "center",
      valueOptions: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      renderCell: (params) => {
        const isChecked =
          toggleStates[params.id] !== undefined
            ? toggleStates[params.id]
            : params.row["b.good_to_assign"] === 1;
        return (
          <label className="flex items-center relative w-max cursor-pointer select-none ">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => handleToggle(params)}
              className="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500 checked:bg-green-500"
            />
            <span className="absolute font-medium text-xxs uppercase right-1 text-white">
              {" "}
              No{" "}
            </span>
            <span className="absolute font-medium text-xxs uppercase right-8 text-white">
              {" "}
              Yes{" "}
            </span>
            <span
              className={`w-6 h-6 right-7 absolute rounded-full transform transition-transform bg-gray-200 ${
                isChecked ? "translate-x-7" : "translate-x-0"
              }`}
            />
          </label>
        );
      },
    },
    {
      field: "b.follow_status",
      headerName: "Follow Status",
      minWidth: 50,
      flex: 1,
      editable: true,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: followStatus,
      renderCell: (params) => params.value["b.follow_status"],
      valueGetter: (params) => params.row["b.follow_status"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "b.backlink_paid_status",
      headerName: "Backlink Paid Status",
      minWidth: 100,
      flex: 1,
      editable: true,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
      renderCell: (params) => {
        return (
          <span className="capitalize">
            {params.row["b.backlink_paid_status"]}
          </span>
        );
      },
      valueGetter: (params) => params.row["b.backlink_paid_status"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "b.niche_product_ids",
      headerName: "Niche Category",
      width: 220,
      editable: true,
      align: "left",
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.niche_category,
      valueOptions: nicheProductList,
      renderCell: (params) => {
        const categories =
          params?.row?.["b.niche_categories"]?.split(",") || [];
        function close() {
          setCategoryShowTooltip("");
        }
        return (
          <div className="relative">
            <p
              className={`bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3`}
              onClick={(e) => {
                if (categories.length) {
                  setCategoryShowTooltip(params.id);
                }
              }}
            >
              {categories.length}
            </p>
            {showCategoryTooltip === params.id && (
              <Modal
                className="max-w-xl"
                handleModal={close}
                handleModalSubmit={close}
              >
                <div>
                  <div
                    onClick={close}
                    className="cursor-pointer absolute top-2 right-2 text-xl text-gray-700 pb-2"
                  >
                    <CloseIcon />
                  </div>
                  <div className="max-h-[300px] overflow-y-auto">
                  {categories.map((item, catCount) => (
                    <div className="flex">
                      <p className="pr-2">{catCount + 1}.</p>
                      <p>{item}</p>
                    </div>
                  ))}
                  </div>
                </div>
              </Modal>
            )}
          </div>
        );
      },
      valueGetter: (params) => params?.row["b.niche_product_id"],
      valueSetter: (params) => {
        const { value } = params;
        if (!value) {
          return {
            ...params.row,
            "b.niche_product_id": null,
            "b.niche_category": null,
          };
        }
        const status = nicheProductList.find((status) => status.id === value);
        if (status) {
          return {
            ...params.row,
            "b.niche_product_id": value,
            "b.niche_category": status.niche_category,
          };
        }
        return params.row;
      },
      valueFormatter: (params) => params?.value,
    },
    {
      field: "b.location_type",
      headerName: "Location Type",
      minWidth: 150,
      flex: 1,
      editable: true,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: locationTypes,
      renderCell: (params) => (
        <div className="capitalize">{params.row["b.location_type"]}</div>
      ),
      valueGetter: (params) => params.row["b.location_type"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "b.is_verified",
      headerName: "Verified",
      minWidth: 100,
      flex: 1,
      editable: true,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      renderCell: (params) => (params.value === 1 ? "Yes" : "No"),
      valueGetter: (params) => params.row["b.is_verified"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "b.location",
      headerName: "Location",
      minWidth: 150,
      flex: 1,
      editable: true,
      filterable: false,
      renderCell: (params) => {
        const categories = params?.row?.["b.location_names"]?.split(",") || [];
        function close() {
          setLocationShowTooltip("");
        }
        return (
          <div className="relative">
            <p
              className={`bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3`}
              onClick={(e) => {
                if (categories.length) {
                  setLocationShowTooltip(params.id);
                }
              }}
            >
              {categories.length}
            </p>
            {showLocationTooltip === params.id && (
              <Modal
                className="max-w-xl"
                handleModal={close}
                handleModalSubmit={close}
              >
                <div>
                  <div
                    onClick={close}
                    className="cursor-pointer absolute top-2 right-2 text-xl text-gray-700 pb-2"
                  >
                    <CloseIcon />
                  </div>
                  {categories.map((item, catCount) => (
                    <div className="flex">
                      <p className="pr-2">{catCount + 1}.</p>
                      <p>{item}</p>
                    </div>
                  ))}
                </div>
              </Modal>
            )}
          </div>
        );
      },
    },
    {
      field: "bds.assigned_users",
      headerName: "Task Assign",
      width: 200,
      editable: false,
      type: "singleSelect",
      minWidth: 60,
      disableColumnMenu: true,
      valueOptions: [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ],
      filterOperators: [
        {
          label: "Yes",
          value: "Yes",
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.row.bds.assigned_users.length === 0;
            };
          },
        },
        {
          label: "No",
          value: "No",
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.row.bds.assigned_users.length > 0;
            };
          },
        },
      ],
    },
    {
      field: "bds.total_inReview",
      headerName: "In Review Task",
      width: 120,
      editable: false,
      align: "center",
      type: "number",
      renderCell: (params) =>
        params?.row["bds.total_inReview"] > 0 ? (
          <span
            className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
            onClick={(e) => handleTaskLink(params?.row["b.id"], "inReview")}
          >
            {params?.row["bds.total_inReview"]}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "bds.total_hold",
      headerName: "In Hold Task",
      width: 120,
      editable: false,
      align: "center",
      type: "number",
      renderCell: (params) =>
        params?.row["bds.total_hold"] > 0 ? (
          <span
            className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
            onClick={(e) => handleTaskLink(params?.row["b.id"], "hold")}
          >
            {params?.row["bds.total_hold"]}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "bds.total_completed",
      headerName: "In Completed Task",
      width: 120,
      editable: false,
      align: "center",
      type: "number",
      renderCell: (params) =>
        params?.row["bds.total_completed"] > 0 ? (
          <span
            className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
            onClick={(e) => handleTaskLink(params?.row["b.id"], "completed")}
          >
            {params?.row["bds.total_completed"]}
          </span>
        ) : (
          <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
            0
          </span>
        ),
    },
    {
      field: "b.traffic",
      headerName: "Traffic",
      minWidth: 150,
      editable: true,
      align: "left",
      filterable: false,
    },
    {
      field: "b.domain_rating",
      headerName: "DR",
      width: 100,
      editable: true,
      filterable: false,
      renderHeader: (params) => (
        <div className="flex justify- items-center w-full">
          <Tooltip
            title={
              <div className="flex flex-col text-sm w-[full]">
                Domain Rating
              </div>
            }
          >
            <span>DR</span>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleTaskLink = (backlinkId, task_status) => {
    const stateData = { backlink_id: backlinkId, task_status: task_status };
    const queryString = new URLSearchParams(stateData).toString();
    const url = `/backlink_detail?${queryString}`;
    window.open(url, "_blank");
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchBacklinksData({
      filter: queryOptions,
      page: +params.page + 1,
      perPage: params.pageSize,
    });
  };
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    let filters = {
      ...queryOptions,
      rules: queryOptions.rules.map((rule) => ({ ...rule })), // Deep copy of the rules
    };
    filters.rules.forEach((rule) => {
      if (rule.field === "bds.assigned_users") {
        rule.field = "bds.assigned_users";
        rule.op = rule.op === "Yes" ? "isNotEmpty" : "isEmpty";
      }
    });
    fetchBacklinksData({
      filter: filters,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  };

  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber
      ? "equals"
      : operator === "eq" && isSelect && !isNumber
      ? "is"
      : operator === "eq" && !isSelect && isNumber
      ? "="
      : operator === "not" && !isSelect && isNumber
      ? "!="
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator === "arraySize"
      ? "isEmpty"
      : operator;
  };
  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
      // data === true ? 1 : data === false ? 0 : data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    const item = processFilters(filterModel.items);
    setFilterModel({
      ...filterModel,
      items: item,
    });
    if (filterModel?.items?.length === 0) {
      fetchBacklinksData({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    processFilters(filterModel.items)?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field === "domain_count" ? "domain" : rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data:
            rule.field === "b.niche_product_ids" && rule.value
              ? Array.isArray(rule.value)
                ? rule.value
                : [rule.value]
              : rule.value
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processFilters = (filters) => {
    return filters.map((filter) => {
      if (
        filter.operator === "isAnyOf" &&
        Array.isArray(filter.value) &&
        filter.value.length
      ) {
        if (typeof filter.value[0] === "string" && filter.value[0].includes(",")) {
          return {
            ...filter,
            value: filter.value[0]
              .split(",")
              .map((item) => item.trim()) 
              .map(Number), 
          };
        }
        return filter;
      }
      return filter;
    });
  };
  

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  // Table Tool Bar
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center space-x-1 my-0 justify-between">
        <div>
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
            }}
          />

          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
            }}
            ref={setFilterButtonEl}
          />
          <Button
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            // className="!text-[#042a42]"
          >
            Apply filter
          </Button>
          <Button
            variant="text"
            sx={{
              marginRight: "4px",
              height: "26px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
              color: "black",
            }}
          >
            <span className="inline-flex items-center bg-green-100 text-green-800 text-xs px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 font-semibold">
              <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
              Batch Number: {location?.state?.selectedBatch?.batch_number}
            </span>
          </Button>
          {selectedData?.length > 0 ? (
            <Button
              variant="text"
              sx={{
                borderRadius: "6px",
                marginRight: "4px",
                border: "1px solid",
                borderColor: "#e8eaee",
                height: "26px",
                fontSize: "0.8125rem",
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              Total Selected Backlinks
              <small
                className={`flex w-${Math.max(
                  selectedData?.length.toString().length * 2 + 2,
                  4
                )} h-${Math.max(
                  selectedData?.length.toString().length * 2 + 2,
                  4
                )} p-0.5 rounded-full bg-secondary mb-0 ml-1 items-center justify-center text-white text-xs`}
              >
                {selectedData?.length}
              </small>
            </Button>
          ) : null}
          <Button
            variant="text"
            startIcon={<FaSave size={15} />}
            onClick={() => assignBatchToBacklink()}
            disabled={!selectedData?.length}
            sx={{
              borderRadius: "6px",
              marginRight: "2px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Save
          </Button>
        </div>
        <TablePagination
          color="primary"
          shape="rounded"
          size="medium"
          showFirstButton
          showLastButton
          count={
            backlinksData?.totalCount
              ? backlinksData?.totalCount
              : backlinksData?.backlinksData?.length || 0
          }
          page={paginationModel.page - 1 || 0}
          onPageChange={(e, page) => handleTopPageChange(page)}
          rowsPerPage={paginationModel.pageSize || 100}
          onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
          component="div"
          rowsPerPageOptions={[
            25,
            50,
            75,
            100,
            250,
            500,
            1000,
            1500,
            2000,
            {
              label: "All",
              value: backlinksData?.totalCount
                ? backlinksData?.totalCount
                : backlinksData?.backlinksData?.length || 0,
            },
          ]}
        />
      </GridToolbarContainer>
    );
  }
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    fetchBacklinksData({
      filter: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    fetchBacklinksData({
      filter: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleModalCancel = () => {
    handleModal(false);
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filters: queryOptions,
    });
    setUser("");
    setSelectedData([]);
  };

  const handleAssignBacklink = async (param) => {
    setIsLoading(true);
    try {
      const saveDataArr = {
        // domains: selectedDomains?.map((item) => item["domain"]),
        //user_id: parseInt(userdata),
        is_confirmed: param ? param : isConfirmed,
        backlink_ids: selectedData?.map((item) => item["b.id"]),
      };
      const res = await api.post("api/domains/assign_backlinks", saveDataArr);
      if (res.status === 201) {
        toast.success("Backlinks assigned successfully");
        handleModal(false);
        fetchBacklinksData({
          page: paginationModel?.page,
          perPage: paginationModel?.pageSize,
          filters: queryOptions,
        });
        setUser("");
        setSelectedData([]);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      if (
        err?.response?.data?.availableToCreate ||
        err?.response?.data?.alreadyCreatedtasks
      ) {
        setModalType("agreeModal");
        setAgreeData(err.response.data);
        handleModal();
      } else
        toast.error(
          err.response?.data?.message || "Failed to assign backlinks!"
        );
    } finally {
      setIsLoading(null);
    }
  };

  const assignBatchToBacklink = async (param) => {
    setIsLoading(true);
    try {
      const saveDataArr = {
        batch_id: location?.state?.selectedBatch?.bid,
        is_confirmed: true,
        backlink_ids: selectedData?.map((item) => item["b.id"]),
      };
      const res = await api.post(
        "api/domains/assign_backlinks_in_existing_batch",
        saveDataArr
      );
      if (res.status === 200 || res.status === 201) {
        toast.success("Backlinks assigned successfully");
        handleModal(false);
        fetchBacklinksData({
          page: paginationModel?.page,
          perPage: paginationModel?.pageSize,
          filters: queryOptions,
        });
        setUser("");
        setSelectedData([]);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to assign backlinks!");
      /* if (
        err?.response?.data?.availableToCreate ||
        err?.response?.data?.alreadyCreatedtasks
      ) {
        setModalType("agreeModal");
        setAgreeData(err.response.data);
        handleModal();
      } else
        toast.error(
          err.response?.data?.message || "Failed to assign backlinks!"
        ); */
    } finally {
      setIsLoading(null);
    }
  };

  useEffect(() => {
    if (backlinksData && backlinksData.backlinksData) {
      const initialStates = {};
      backlinksData.backlinksData.forEach((item) => {
        let bid = item["b.id"];
        initialStates[bid] = item["b.good_to_assign"] === 1;
      });
      setToggleStates(initialStates);
    }
  }, [backlinksData]);
  const handleToggle = (params) => {
    setToggleStates((prev) => {
      const currentState =
        prev[params?.id] !== undefined
          ? prev[params?.id]
          : backlinksData?.backlinksData?.find((item) => item.id === params?.id)
              ?.b.good_to_assign === 1;

      const newState = !currentState;
      goodToAssignUpdate(
        params?.id,
        newState,
        params?.row["b.backlink_domain"]
      );
      return {
        ...prev,
        [params?.id]: newState,
      };
    });
  };

  const goodToAssignUpdate = async (bid, newState, backlink_domain) => {
    setIsLoading(true);
    try {
      const res = await api.put(`/api/backlinks/updateBacklink`, {
        goodToAssign: newState,
        id: bid,
        backlink_domain: backlink_domain,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Good To Assign Status Changed");
        fetchBacklinksData({
          size: paginationModel.pageSize,
          page: paginationModel.page,
          filter: queryOptions,
        });
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    "bds.assigned_users": false,
  });
  const handleColumnVisibilityChange = (model) => {
    setColumnVisibilityModel(model);
  };

  const get_filters = async () => {
    try {
      const res = await api.get(
        "api/custom_filter?report_name=ASSIGN_BACKLINKS"
      );
      if (res.status === 200 || res.status === 201) {
        setSavedFilters(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const findMatchingObject = () => {
    return _.find(savedFilters, (item) => {
      if (item?.filter_json) {
        return _.isEqual(item?.filter_json, queryOptions);
      } else {
        return _.isEqual(item, queryOptions);
      }
    });
  };

  const isAllObjectMatched = () => {
    return savedFilters?.some((obj) => {
      if (obj?.filter_json?.groupOp !== queryOptions?.groupOp) return false;
      return queryOptions?.rules?.every((rule) =>
        obj?.filter_json?.rules?.some(
          (objRule) =>
            objRule?.field === rule?.field &&
            objRule?.op === rule?.op &&
            objRule?.data === rule?.data
        )
      );
    });
  };

  const sortUserFilters = (filters) => {
    if (!Array.isArray(filters)) {
      return [];
    }
    return filters.sort((a, b) => {
      const aStartsWithAsterisk = a.filter_name;
      const bStartsWithAsterisk = b.filter_name;

      if (aStartsWithAsterisk && !bStartsWithAsterisk) return -1;
      if (!aStartsWithAsterisk && bStartsWithAsterisk) return 1;
      return 0;
    });
  };
  const areObjectsEqual = (selectedFilter) => {
    return _.isEqual(selectedFilter, queryOptions);
  };

  const handleDeleteFilters = async (id) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;

    setIsLoading(true);
    try {
      const res = await api.delete(`api/custom_filter/${id}`);
      if (res.status === 200) {
        get_filters();
        setFilterModel({
          items: [],
          logicOperator: "and",
          quickFilterValues: [],
          quickFilterLogicOperator: "and",
        });
        toast.success(
          res?.data?.error || res?.data?.message || "Filter saved successfully"
        );
        setQueryOptions({
          groupOp: "AND",
          rules: [],
        });
        fetchBacklinksData({
          page: paginationModel?.page,
          perPage: paginationModel?.pageSize,
        });
      } else {
        toast.error(
          res?.data?.error || res?.data?.message || "Filter couldn't saved"
        );
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          "Filter couldn't saved"
      );
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Domains ", link: "/domains" },
          { label: "Assign backlinks ", link: "" },
        ]}
      />
      {isLoading && <Loading />}
      <ToastContainer />
      {/*  {!location?.state?.selectedBatch?.id ? (
        <div className="mb-2 pr-2">
          <div className="flex items-end justify-between mt-4  dark:text-white pr-2">
            <div className="flex flex-row items-center w-full space-x-2">
              <h2 className="font-bold text-m">
                Assign backlinks to selected{" "}
                {selectedDomains?.length || 0}{" "}
                domain
              </h2>
                <select
                onChange={(e) => setUser(e.target.value)}
                value={userdata}
                name="user_id"
                className="inputField w-[300px] h-[40px] text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md"
              >
                <option value="" className="dark:bg-blue">
                  Select User
                </option>
                {users.map((item, index) => (
                  <option key={index} value={item?.id} className="dark:bg-blue">
                    {item?.name.replace(/\b\w/g, (c) => c.toUpperCase())}{" "}
                    {item?.role ? `( ${item?.role} )` : ""}{" "}
                    {item?.pending_tasks ? item?.pending_tasks : ""}
                  </option>
                ))}
              </select> 
              <button
                onClick={() => handleAssignBacklink()}
                className="btnPrimary bg-green-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {selectedData?.length ? (
            <div className="mb-2 pr-2">
              <div className="flex items-end justify-between mt-4  dark:text-white pr-2">
                <div className="flex flex-row items-center w-full space-x-2">
                  <h2 className="font-bold text-m">
                    {selectedData?.length} more backlink added
                  </h2>
                  <button
                    onClick={() => assignBatchToBacklink()}
                    className="btnPrimary bg-green-600"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )} */}
      {isFilterSave ? (
        <SaveFilterModal
          onClose={() => {
            setIsFilterSave(false);
          }}
          filters={queryOptions}
          editingRecord={editingRecord}
          setFilters={(data) => {
            get_filters();
          }}
        />
      ) : null}

      <div className="flex gap-x-2 bg-white my-3 border rounded px-2 py-2">
        <FiltersDropdown
          options={sortUserFilters(savedFilters)}
          value={findMatchingObject()}
          areObjectsEqual={(data) => areObjectsEqual(data)}
          handleDeleteFilters={(id) => handleDeleteFilters(id)}
          onEdit={(data) => {
            setEditingRecord(data);
            setIsFilterSave(true);
          }}
          onRemove={() => {
            setQueryOptions({
              groupOp: "AND",
              rules: [],
            });
            setPaginationModel({
              pageSize: paginationModel.pageSize,
              page: 1,
            });
            setFilterModel({
              items: [],
              logicOperator: "and",
              quickFilterValues: [],
              quickFilterLogicOperator: "and",
            });
            fetchBacklinksData({
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedData([]);
          }}
          onChange={(data) => {
            const filters = data?.groupOp
              ? data
              : data?.filters
              ? data.filters
              : { groupOp: "AND", rules: [] };
            setFilterModel({
              items: filters?.rules?.map(({ field, op, data }) => ({
                field: field,
                operator: operator({ operator: op, field }),
                value: data,
              })),
              logicOperator: "and",
              quickFilterValues: [],
              quickFilterLogicOperator: "and",
            });
            setQueryOptions(filters);
            setPaginationModel({
              pageSize: paginationModel.pageSize,
              page: 1,
            });
            /*   fetchBacklinksData({
              filter: filters,
              page: 1,
              size: paginationModel.pageSize,
            }); */
          }}
        />
        <div>
          {queryOptions?.rules?.length && !isAllObjectMatched() ? (
            <div className="flex flex-row space-x-1">
              <button
                className="bg-secondary text-white w-[25px] h-[25px] rounded-full flex justify-center items-center mt-2"
                onClick={() => setIsFilterSave(true)}
              >
                <FaCheck color="white" />
              </button>
            </div>
          ) : null}
        </div>
      </div>

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={backlinksData?.backlinksData?.map((data, index) => {
            const counter = offset + index + 1;
            const records = data;
            const {
              "b.id": id,
              "b.backlink_domain": backlink_domain,
              "b.signup_url": signup_url,
              "b.type": type,
              "b.blacklisted": blacklisted,
              "b.domain_rating": domain_rating,
              "b.robot_group": robot_group,
              "b.ready_status": ready_status,
              "b.follow_status": follow_status,
              "b.traffic": traffic,
              "b.backlink_type_id": backlink_type_id,
              "b.ready_status_id": ready_status_id,
              "bds.total_inReview": total_inReview,
              "bds.total_hold": total_hold,
              "bds.total_completed": total_completed,
              "b.good_to_assign": good_to_assign,
              "b.niche_categories": niche_categories,
              "b.niche_product_ids": niche_product_ids,
              "b.location_type": location_type,
              "b.location_names": location_names,
              "b.location_ids": location_ids,
              "bds.total_cancelled": total_cancelled,
              "b.backlink_paid_status": backlink_paid_status,
              "b.is_verified": is_verified,
              "b.intersect_count": intersect_count,
            } = data;
            const source_domains_list_count =
              data["bi.source_domains_list"]?.split(",")?.length;
            const created_at = dayjs(data["b.created_at"]).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            const updated_at = dayjs(data["b.updated_at"]).format(
              "ddd, MMM D, YYYY h:mm A"
            );

            return {
              counter,
              records,
              "b.id": id,
              "b.backlink_domain": backlink_domain,
              "b.signup_url": signup_url,
              "b.type": type || "",
              "b.blacklisted": blacklisted,
              "b.domain_rating": domain_rating,
              "b.robot_group": robot_group,
              "b.ready_status": ready_status || "",
              "b.follow_status": follow_status || "",
              "b.traffic": traffic,
              "b.backlink_type_id": backlink_type_id,
              "b.ready_status_id": ready_status_id,
              "bds.total_inReview": total_inReview,
              "bds.total_hold": total_hold,
              "bds.total_completed": total_completed,
              "b.good_to_assign": good_to_assign,
              "b.niche_categories": niche_categories,
              "b.niche_product_ids": niche_product_ids,
              "b.location_type": location_type,
              "b.location_names": location_names,
              "b.location_ids": location_ids,
              "b.created_at": created_at,
              "b.updated_at": updated_at,
              source_domains_list_count,
              "bds.total_cancelled": total_cancelled,
              "b.backlink_paid_status": backlink_paid_status,
              "b.is_verified": is_verified,
              "b.intersect_count": intersect_count,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={backlinksData?.totalCount}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
          columnVisibilityModel={columnVisibilityModel}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
          filterModel={filterModel}
          hideFooter={true}
        />
      </div>

      {modalType === "agreeModal" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-4xl"
          title="Backlink Confirmations"
        >
          <BacklinkAgreeForm
            handleModal={handleModal}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            agreeData={agreeData}
            setIsConfirmed={setIsConfirmed}
            handleAssignBacklink={handleAssignBacklink}
            handleModalCancel={handleModalCancel}
            selectedData={selectedData}
            selectedDomains={selectedDomains}
            assignBatchToBacklink={assignBatchToBacklink}
            selectedBatch={location?.state?.selectedBatch}
          />
        </Modal>
      )}
    </div>
  );
}
