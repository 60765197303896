import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import Modal from "../common/Modal";
import { CheckBox } from "..";
import { XCircleIcon } from "@heroicons/react/24/outline";
import api from "../../services/api";
import { setInstructionModal } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { FaRegFilePdf } from "react-icons/fa";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
function PDFModal() {
  const dispatch = useDispatch();
  const [pdfUrl, setPdfUrl] = useState("");
  const [showAgain, setShowAgain] = useState(false);
  const handlePdfPreview = async () => {
    const url = "https://api.roboanywhere.com/robo_doc.pdf";
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const previewLink = window.URL.createObjectURL(blob);
      setPdfUrl(previewLink);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };
  useEffect(() => {
    handlePdfPreview();
  }, []);
  const cancelButtonRef = React.useRef(null);
  const closeModal = async () => {
    if (showAgain) {
      try {
        api.put("/api/linkbuilder_user/update_show_popup");
      } catch (error) {
        console.error("Error downloading the PDF:", error);
      }
    }
    dispatch(setInstructionModal(false));
    window.URL.revokeObjectURL(pdfUrl);
  };
  const handlePdfDownload = async () => {
    const url = "https://api.roboanywhere.com/robo_doc.pdf";
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "robo_doc.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };
  return (
    <div className="PDFModal">
      <Modal
        open={false}
        handleModal={closeModal}
        cancelButtonRef={cancelButtonRef}
        title={"Instructions"}
        className="max-w-5xl h-[calc(100vh-60px)]"
      >
        <div className="overflow-y-scroll h-[calc(100vh-200px)] bg-gray-100 p-4 flex justify-center">
          <Document file={pdfUrl}>
            {[...Array(3)].map((_, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
        <div className="flex justify-between items-center mt-3">
          <CheckBox
            onChange={() => setShowAgain(!showAgain)}
            checked={showAgain}
            label="Don't show again"
          />
          <div className="flex gap-x-2">
            <button
              onClick={handlePdfDownload}
              type="button"
              title="Download key"
              className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize ml-2 bg-secondary text-white hover:bg-secondary px-4"
            >
              <FaRegFilePdf size={16} className="mr-1" /> Download
            </button>
            <button
              onClick={closeModal}
              type="button"
              title="Download key"
              className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 capitalize ml-2 bg-red-500 text-white px-4"
            >
              <XCircleIcon className="mr-1 text-white w-6" /> Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PDFModal;
