//import { useState } from "react";
//import { ChartData } from "./chartData";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
export default function Chart({ value, to, title, icon, color, border }) {
/*   const [chartData, setChartData] = useState({
    labels: ChartData.map((data) => data.name),
    datasets: [
      {
        label: title,
        data: ChartData.map((data) => data.percentage),
        backgroundColor: color,
        borderColor: border,
        borderWidth: 2,
        fill: "origin",
      },
    ],
  }); */
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleMenuClick = (e) => {
    if (user?.user?.merchant_id?.id !== 1) {
      e.preventDefault();
      navigate(`/dashboard`);
      toast.error("No records for domains!");
    }
  };
  return (
    <Link
      to={to}
      className="card"
      onClick={to === "/domains" ? handleMenuClick : null}
    >
      <div className="flex justify-between">
        {" "}
        <p className="text-xl font-semibold">{value || 0}</p>
        <img src={icon} alt="" className="w-10" />
      </div>
      <p>{title}</p>
      {/* <Line className="w-full mt-2 h-52" data={chartData} /> */}
    </Link>
  );
}
