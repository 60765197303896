import React, { useState, useCallback } from "react";
import api from "../../services/api";
import { InputField, SelectBox } from "../";
import Loading from "../Loader/Loading";
import TagsInputAutoComplete from "../CustomTagsInputAutoComplete";
import TagsSelect from "../common/TagsSelect";
import debounce from "lodash/debounce";
import { MultiSelect } from "primereact/multiselect";
export default function AddUpdateForm({
  handleModal,
  isLoading,
  nicheProductList,
  followStatus,
  backlinksStatusList,
  backlinksTypeList,
  processRowUpdate,
  selectededitRow,
}) {
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isAutoComLoader, setAutoComLoader] = useState(false);
  const [isLocation, setLocationdata] = useState([]);
  const selectedPayload = {};
  const [subCategory, setSubCategory] = useState([]);
  const [isLoader, setIsLoading] = useState(false);
  const handleSelectSubcategory = (selectedCategoryId) => {
    setIsLoading(true);
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/list_niche_sub_category`,
        { niche_category_id: selectedCategoryId }
      )
      .then((response) => {
        setSubCategory(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSelect = (item) => {
    setPayloadData((prevData) => {
      const newLocationIds = prevData["b.location_ids"]
        ? [...prevData["b.location_ids"], item.id]
        : [item.id];

      const newLocationNames = prevData["b.location_names"]
        ? `${prevData["b.location_names"]}, ${item.name}`
        : item.name;

      return {
        ...prevData,
        "b.location_ids": newLocationIds,
        "b.location_names": newLocationNames,
      };
    });
  };
  // eslint-disable-next-line
  const handleQueryChange = useCallback(
    debounce((newQuery, PreLocationType) => {
      fetchData(newQuery, PreLocationType);
    }, 500),
    []
  );
  const fetchData = useCallback(async (searchQuery, PreLocationType) => {
    setLocationdata([]);
    if (searchQuery?.length === 0) {
      setLocationdata([]);
      return;
    }
    const payload = {
      filters: {
        groupOp: "AND",
        rules: [
          {
            field: "name",
            op: "cn",
            data: searchQuery,
          },
        ],
      },
    };
    setAutoComLoader(true);
    try {
      let newLocationType = PreLocationType;
      let endpoint = "/api/locations";
      if (newLocationType === "national") {
        endpoint += "/countries";
      } else if (newLocationType === "local") {
        endpoint += "/cities ";
      }
      const res = await await api.post(endpoint, payload);
      if (res.status === 200 || res.status === 201) {
        setLocationdata(
          res?.data?.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
    setAutoComLoader(false);
  }, []);
  const handleSubmit = (e) => {
    setPayloadData({
      ...payloadData,
      "b.location_ids": selectedLocations.map((e) => e.id),
      "b.location_names": selectedLocations.map((e) => e.name),
    });
    e.preventDefault();
    let newData;
    if (selectededitRow) {
      newData = selectededitRow;
    } else {
      newData = { isNew: true };
    }
    newData = { ...newData, ...payloadData };
    processRowUpdate(newData);
  };
  let initialData = {
    "b.backlink_domain": selectededitRow
      ? selectededitRow["b.backlink_domain"]
      : "",
    "b.backlink_type_id": selectededitRow
      ? parseInt(selectededitRow["b.backlink_type_id"])
      : "",
    "b.type": selectededitRow ? selectededitRow["b.type"] : "",
    "b.ready_status_id": selectededitRow
      ? parseInt(selectededitRow["b.ready_status_id"])
      : "",
    "b.good_to_assign": selectededitRow
      ? selectededitRow["b.good_to_assign"]
      : "",
    "b.follow_status": selectededitRow
      ? selectededitRow["b.follow_status"]
      : "",
    "b.backlink_paid_status": selectededitRow
      ? selectededitRow["b.backlink_paid_status"]
      : "",
    "b.niche_product_ids": selectededitRow
      ? JSON.parse(selectededitRow["b.niche_product_ids"])
      : "",
    "b.niche_categories": selectededitRow
      ? selectededitRow["b.niche_categories"]
      : "",
    "b.location_type": selectededitRow
      ? selectededitRow["b.location_type"]
      : "",
    "b.location_ids": selectededitRow
      ? JSON.parse(selectededitRow["b.location_ids"])
      : "",
    "b.location_names": selectededitRow
      ? selectededitRow["b.location_names"]
      : "",
    "b.is_verified": selectededitRow ? selectededitRow["b.is_verified"] : "",
  };
  const [payloadData, setPayloadData] = useState(initialData);
  React.useEffect(() => {
    setSelectedLocations([]);
    setSelectedSubcategory([]);
    if (payloadData && payloadData["b.location_names"]) {
      let names = payloadData["b.location_names"]
        .replace(/\s+/g, "")
        .split(",");
      names.map((name, index) => {
        if (
          !selectedLocations.find(
            (e) => e.id === payloadData["b.location_ids"][index]
          )
        ) {
          setSelectedLocations((prevLocations) => [
            ...prevLocations, // Spread the previous locations array
            { name, id: payloadData["b.location_ids"][index] }, // Add the new location object
          ]);
        }
      });
    }

    if (payloadData && payloadData["b.niche_product_ids"]) {
      let names = payloadData["b.niche_categories"]
        .replace(/\s+/g, "")
        .split(",");
      names.map((name, index) => {
        if (
          !selectedSubcategory.find(
            (e) => e.id === payloadData["b.niche_product_ids"][index]
          )
        ) {
          setSelectedSubcategory((prevLocations) => [
            ...prevLocations, // Spread the previous locations array
            {
              niche_sub_category: name,
              id: payloadData["b.niche_product_ids"][index],
            }, // Add the new location object
          ]);
        }
      });
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {isLoading || isLoader ? <Loading /> : null}
      <div className="flex gap-4 w-full">
        {/* <div className="grid md:grid-cols-1 gap-4 w-full"> */}
        <div className="w-1/3">
          {/* <div className="grid md:grid-cols-3 gap-4 w-full"> */}
          <InputField
            label="Backlink Domain"
            name="payloadData.backlink_domain"
            placeholder=""
            value={payloadData["b.backlink_domain"]}
            onChange={(e) =>
              setPayloadData({
                ...payloadData,
                "b.backlink_domain": e.target.value,
              })
            }
            required={true}
          />
          <SelectBox
            className="pt-2"
            label={"Good To Assign"}
            placeholder={"Good To Assign"}
            disabled={!selectededitRow}
            onChange={(e) => {
              setPayloadData({
                ...payloadData,
                "b.good_to_assign": parseInt(e.target.value),
              });
            }}
            name="payloadData.good_to_assign"
            valueKey="value"
            labelKey="label"
            options={[
              { value: 1, label: "Yes" },
              { value: 0, label: "No" },
            ]}
            value={payloadData["b.good_to_assign"]}
            height="h-[42px]"
          />
          <div className="mt-2">
            <label htmlFor="roleId" className="inputLabel">
              Niche Category:
            </label>
            <MultiSelect
              placeholder="Niche Category"
              options={nicheProductList}
              optionLabel="niche_category"
              optionValue="id"
              filter
              maxSelectedLabels={2}
              value={payloadData["b.niche_product_ids"]}
              onChange={(e) =>
                setPayloadData({
                  ...payloadData,
                  "b.niche_product_ids": e.value,
                })
              }
              className="h-[40px] w-full !rounded-[4px] focus:!shadow-none border border-#e5e7eb-500 hover:border-gray-300"
              panelClassName="bg-gray-300 border border-gray-300"
            />
          </div>
          {/* <SelectBox
            className="pt-2"
            label={"Niche Category"}
            placeholder={"Niche Category"}
            onChange={(e) => {
              handleSelectSubcategory(e.target.value);
            }}
            name="payloadData.niche_product_id"
            valueKey="id"
            labelKey="niche_category"
            options={nicheProductList}
            value={payloadData["b.niche_product_id"]}
            height="h-[42px]"
          /> */}
          <SelectBox
            className="pt-2"
            label={"Location Type"}
            placeholder={"Location Type"}
            onChange={(e) => {
              setPayloadData({
                ...payloadData,
                "b.location_type": e.target.value,
                "b.location_ids": [],
                "b.location_names": "",
              });
              setSelectedLocations([]);
            }}
            name="payloadData.location_type"
            valueKey="value"
            labelKey="label"
            options={[
              { value: "local", label: "Local" },
              { value: "national", label: "National" },
            ]}
            value={payloadData["b.location_type"]}
            height="h-[42px]"
          />
        </div>
        <div className=" w-1/3">
          {/* <div className="grid md:grid-cols-3 gap-4 w-full"> */}
          <SelectBox
            label={"Backlink Type"}
            placeholder={"Backlink Type"}
            onChange={(e) => {
              const status = backlinksTypeList.find(
                (status) => status.id === parseInt(e.target.value)
              );
              if (status) {
                setPayloadData({
                  ...payloadData,
                  "b.backlink_type_id": parseInt(e.target.value),
                  type: status.type,
                });
              }
            }}
            name="payloadData.backlink_type_id"
            valueKey="id"
            labelKey="type"
            options={backlinksTypeList}
            value={payloadData["b.backlink_type_id"]}
            height="h-[42px]"
          />
          <SelectBox
            className="pt-2"
            label={"Follow Status"}
            placeholder={"Follow Status"}
            onChange={(e) => {
              setPayloadData({
                ...payloadData,
                "b.follow_status": e.target.value,
              });
            }}
            name="payloadData.follow_status"
            valueKey="value"
            labelKey="label"
            options={followStatus}
            value={payloadData["b.follow_status"]}
            height="h-[42px]"
          />
          {/* <div className="pt-2">
            <label className="inputLabel dark:text-white mb-1 font-bold">
              Niche Sub Category
            </label>
            <TagsSelect
              tags={selectedSubcategory}
              setTags={(tag) => {
                setPayloadData({
                  ...payloadData,
                  "b.niche_product_ids": tag.map((e) => parseInt(e.id)),
                });
                setSelectedSubcategory(tag);
              }}
              className="border p-2 border-[#f0ecec] min-h-[40px]"
              disable={false}
              options={subCategory}
              optionkey="niche_sub_category"
              optionvalue="id"
            />
          </div> */}
          <div>
            <label className="inputLabel dark:text-white mb-1 font-bold pt-2">
              Location
            </label>
            <TagsInputAutoComplete
              className="border p-2 border-[#f0ecec] max-w-1/3"
              width="w-1/3"
              optionClass="w-1/3"
              tags={selectedLocations}
              setTags={(tag) => setSelectedLocations(tag)}
              data={isLocation}
              idField="id"
              valueField="name"
              onSelect={handleSelect}
              onQueryChange={handleQueryChange}
              placeholder="Search ..."
              setAutoComLoader={setAutoComLoader}
              isAutoComLoader={isAutoComLoader}
              selectedItem={selectedPayload}
              extraClass="mt-1"
              PreLocationType={payloadData["b.location_type"]}
              setLocationdata={setLocationdata}
            />
          </div>
        </div>
        <div className=" w-1/3">
          {/* <div className="grid md:grid-cols-3 gap-4 w-full"> */}
          <SelectBox
            label={"Backlink Ready Status"}
            placeholder={"Backlink Ready Status"}
            onChange={(e) => {
              const status = backlinksStatusList.find(
                (status) => status.id === parseInt(e.target.value)
              );
              if (status) {
                setPayloadData({
                  ...payloadData,
                  "b.ready_status_id": parseInt(e.target.value),
                  "b.ready_status": status.ready_status,
                });
              }
            }}
            name="payloadData.ready_status_id"
            valueKey="id"
            labelKey="ready_status"
            options={backlinksStatusList}
            value={payloadData["b.ready_status_id"]}
            height="h-[42px]"
          />
          <SelectBox
            className="pt-2"
            label={"Backlink Paid Status"}
            placeholder={"Backlink Paid Status"}
            onChange={(e) => {
              setPayloadData({
                ...payloadData,
                "b.backlink_paid_status": e.target.value,
              });
            }}
            name="payloadData.backlink_paid_status"
            valueKey="value"
            labelKey="label"
            options={[
              { value: "free", label: "Free" },
              { value: "paid", label: "Paid" },
            ]}
            value={payloadData["b.backlink_paid_status"]}
            height="h-[42px]"
          />
          <SelectBox
            className="pt-2"
            label={"Verified"}
            placeholder={"Verified"}
            onChange={(e) => {
              setPayloadData({
                ...payloadData,
                "b.is_verified": parseInt(e.target.value),
              });
            }}
            name="payloadData.is_verified"
            valueKey="value"
            labelKey="label"
            options={[
              { value: 1, label: "Yes" },
              { value: 0, label: "No" },
            ]}
            value={payloadData["b.is_verified"]}
            height="h-[42px]"
          />
        </div>
      </div>
      <div className="flex items-cente justify-end mt-5 space-x-2 text-white">
        <button
          onClick={handleModal}
          type="button"
          className="btnPrimary bg-red-500"
        >
          Cancel
        </button>

        <button
          type="button"
          onClick={handleSubmit}
          className="btnPrimary bg-green-600"
        >
          Save and Update
        </button>
      </div>
    </div>
  );
}
