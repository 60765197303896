import React from "react";
import userPermission from "../utils/userPermission";
import Unauthorized from "./Unauthorized";

const ProtectedRoute = ({
  element: Element,
  permission,
  pageTitle,
  ...rest
}) => {
  const renderContent = () => {
    if (!permission || userPermission(permission)) {
      return <Element {...rest} />;
    }
    return <Unauthorized />;
  };

  return <>{renderContent()}</>;
};

export default ProtectedRoute;
