import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { InputField, TextArea } from "../../components";
import Loading from "../../components/Loader/Loading";
export default function AddUpdateMerchant({
  handleModal,
  fetchMerchantsData,
  isLoading,
  setIsLoading,
  modalType,
  merchant,
}) {
  const [name, setName] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (modalType === "editMerchant") {
      setName(merchant.name);
      setCompanyName(merchant.company_name);
      setEmail(merchant.email);
      setAddress(merchant.address);
      setPhone(merchant.phone);
    } else if (modalType === "newMerchant") {
      setName("");
      setEmail("");
      setCompanyName("");
      setAddress("");
      setPhone("");
    }
  }, [modalType, merchant]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Form validation
    const errors = {};

    if (!name.trim()) {
      errors.name = "Please enter the name.";
    }

    if (!email.trim()) {
      errors.email = "Please enter the email.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!company_name.trim()) {
      errors.company_name = "Please enter the company name.";
    }

    if (!address.trim()) {
      errors.address = "Please enter the address.";
    }

    if (!phone.trim()) {
      errors.phone = "Please enter the phone.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }

    const merchantData = {
      name,
      email,
      company_name,
      address,
      phone,
    };
    if (modalType === "newMerchant") {
      api
        .post(`${process.env.REACT_APP_PUBLIC_API}/api/merchants`, merchantData)
        .then(() => {
          handleModal();
          fetchMerchantsData();
          toast.success("New Merchant Created!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (modalType === "editMerchant" && merchant.id) {
      api
        .put(
          `${process.env.REACT_APP_PUBLIC_API}/api/merchants/${merchant?.id}`,
          {
            ...merchantData,
          }
        )
        .then(() => {
          handleModal();
          fetchMerchantsData();
          toast.success("Merchant updated successfully!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      <div className="grid md:grid-cols-2 gap-4 w-full">
        <InputField
          label="name"
          name="name"
          placeholder="Enter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={errors.name}
          required={true}
        />
        <InputField
          label="email"
          name="email"
          placeholder="Enter email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email}
          required={true}
        />
        <InputField
          label="company name"
          name="company_name"
          placeholder="Enter Company Name"
          value={company_name}
          onChange={(e) => setCompanyName(e.target.value)}
          error={errors.company_name}
          required={true}
        />
        <InputField
          label="phone"
          name="phone"
          placeholder="Enter phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          error={errors.phone}
          required={true}
        />
        <TextArea
          label="Address"
          name="address"
          placeholder="Enter address"
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          rows={2}
          className="col-span-1"
          error={errors.address}
          required={true}
        />
      </div>
      <div className="flex items-cente justify-end mt-5 space-x-2 text-white">
        <button
          onClick={handleModal}
          type="button"
          className="btnPrimary bg-red-500"
        >
          Cancel
        </button>

        {isLoading ? (
          <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
            <img src="/img/loading.gif" className="w-7" alt="" />
          </div>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="btnPrimary bg-green-600"
          >
            Save and Update
          </button>
        )}
      </div>
    </div>
  );
}
