import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
export default function BacklinkAgreeForm({
  handleModal,
  isLoading,
  setIsLoading,
  agreeData,
  setIsConfirmed,
  handleAssignBacklink,
  handleModalCancel,
  selectedData,
  selectedDomains,
  assignBatchToBacklink,
  selectedBatch,
}) {
  useEffect(() => {}, [
    isLoading,
    setIsLoading,
    setIsConfirmed,
    handleAssignBacklink,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsConfirmed(true);
    setIsLoading(true);
    selectedBatch ? assignBatchToBacklink(true) : handleAssignBacklink(true);
  };
  return (
    <>
      <TableContainer component={Paper} className="mt-5 mb-5">
        <Table
          sx={{ minWidth: 600, minHeight: 100 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                className="bg-slate-200 "
                style={{ fontWeight: "bold" }}
                align="center"
              >
                Total Requested Tasks
              </TableCell>
              <TableCell
                className="bg-slate-200"
                style={{ fontWeight: "bold" }}
                align="center"
              >
                Already Created Tasks
              </TableCell>
              <TableCell
                className="bg-slate-200"
                style={{ fontWeight: "bold" }}
                align="center"
              >
                Available To Create
              </TableCell>
              <TableCell
                className="bg-slate-200"
                style={{ fontWeight: "bold" }}
                align="center"
              >
                Total Selected Backlinks
              </TableCell>
              <TableCell
                className="bg-slate-200"
                style={{ fontWeight: "bold" }}
                align="center"
              >
                Total Selected Domains
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={1}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {agreeData?.totalRequestedTasks}
              </TableCell>
              <TableCell align="center">
                {agreeData?.alreadyCreatedtasks}
              </TableCell>
              <TableCell align="center">
                {agreeData?.availableToCreate}
              </TableCell>
              <TableCell align="center">
                {selectedData && selectedData.length > 0
                  ? selectedData.length
                  : "-"}
              </TableCell>
              <TableCell align="center">
                {selectedDomains && selectedDomains.length > 0
                  ? selectedDomains.length
                  : "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex items-center mt-5 justify-between text-white">
        {isLoading ? (
          <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
            <img src="/img/loading.gif" className="w-7" alt="" />
          </div>
        ) : (
          <>
            {agreeData?.availableToCreate !== 0 ? (
              <button
                type="button"
                onClick={handleSubmit}
                className="btnPrimary bg-green-600"
              >
                Agree & Submit
              </button>
            ) : (
              <div className="text-left">
                <span className="text-green-500 font-bold text-lg">
                  Batch already created.
                </span>
              </div>
            )}
          </>
        )}
        <button
          onClick={handleModalCancel}
          type="button"
          className="btnPrimary bg-red-500 justify-start"
        >
          Cancel
        </button>
      </div>
    </>
  );
}
