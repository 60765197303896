import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { FaAngleDown } from "react-icons/fa";

export default function SubRole({
  label,
  children,
  selectedData,
  onSingleselect,
}) {
  const renderSubRoles = (items) => {
    return (
      <Menu.Items className="pl-5">
        {items.map((item, key) => {
          return (
            <div
              key={key}
              title={item.name}
              className="flex items-center gap-2 border-b py-2"
            >
              {item?.sub_permissions?.length ? (
                <SubRole
                  children={item.sub_permissions}
                  label={item.name}
                  onSingleselect={onSingleselect}
                  selectedData={selectedData}
                />
              ) : (
                <div className="flex items-center gap-2" title={item.name}>
                  <input
                    className="h-5 w-5 focus:ring-0 cursor-pointer form-checkbox text-primary-100 rounded-sm"
                    type={"checkbox"}
                    onChange={() => onSingleselect(item.name)}
                    checked={selectedData?.includes(item.name)}
                  />
                  <span>{item.name}</span>
                </div>
              )}
            </div>
          );
        })}
      </Menu.Items>
    );
  };

  return (
    <Menu as="div" className="w-full">
      <div className="flex items-center gap-2  border-b">
        <input
          className="h-5 w-5 focus:ring-0 cursor-pointer form-checkbox text-primary-100 rounded-sm"
          type={"checkbox"}
          onChange={() => onSingleselect(label)}
          checked={selectedData?.includes(label)}
        />
        <Menu.Button className="flex items-center justify-between w-full border-">
          <div className="flex items-center gap-2 py-2">{label}</div>
          <FaAngleDown />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {renderSubRoles(children, onSingleselect, selectedData)}
      </Transition>
    </Menu>
  );
}
