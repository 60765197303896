import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { DataTable, InputField, Modal } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import dayjs from "dayjs";
import NewSelectTaskForm from "./NewSelectTaskForm";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";

export default function Roles() {
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [roleManager, setRoleManager] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalType, setModalType] = useState("newRole");
  const [editingRoleId, setEditingRoleId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const getRoles = () => {
    setIsLoading(true);
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/roles/`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getRoles();
  }, []);

  // Modal
  const [open, setOpen] = useState(true);
  //const [isPermission, setIsPermission] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const updateRoles = (newRole) => {
    setRoles((prevRoles) => {
      const updatedRoles = [...prevRoles];

      // Find the index of the role in the array
      const index = updatedRoles.findIndex((role) => role.id === newRole.id);

      if (index !== -1) {
        // If the role already exists, update it
        updatedRoles[index] = newRole;
      } else {
        // If the role is new, add it to the array
        updatedRoles.push(newRole);
      }

      return updatedRoles;
    });
  };

  const handleNewRole = () => {
    setRoleName("");
    setErrors({});
    setModalType("newRole");
    handleModal();
  };

  const handleEditRole = (role) => {
    setEditingRoleId(role.id);
    setRoleName(role.name);
    setErrors({});
    setModalType("editRole");
    handleModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Form validation
    const errors = {};
    if (!roleName.trim()) {
      errors.roleName = "Please enter the role name.";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }

    const role = {
      roleName,
    };

    if (modalType === "newRole") {
      api
        .post(`${process.env.REACT_APP_PUBLIC_API}/api/roles/addRole`, role)
        .then((response) => {
          const newRole = response.data;
          setRoleName("");
          handleModal();
          toast.success("New Role Created!", { autoClose: 3000 });
          updateRoles(newRole); // Update roles in the local state
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (modalType === "editRole" && editingRoleId) {
      api
        .put(`${process.env.REACT_APP_PUBLIC_API}/api/roles/updateRole`, {
          id: editingRoleId,
          roleName: roleName,
        })
        .then(() => {
          const updatedRole = {
            id: editingRoleId,
            name: roleName,
          };
          setRoleName("");
          handleModal();
          toast.success("User Roles Updated!", { autoClose: 3000 });
          updateRoles(updatedRole); // Update roles in the local state
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="p-5">
      <CustomBreadcrumbs  crumbs={[
          { label: "Home", link: "/" },
          { label: "Roles", link: "" },
        ]} />
      {roleManager ? (
        <NewSelectTaskForm
          modalTitle="Manage Role Permissions"
          onCancelForm={() => setRoleManager(!roleManager)}
          roles={roles}
          getRoles={getRoles}
        />
      ) : null}

      <ToastContainer />
      {isLoading && (
            <Loading />
        )}
      <div className="flex items-center justify-between mt-2">
        <div className="flex">
          {userPermission("Create Role") ? (
            <button className="btnPrimary" onClick={handleNewRole}>
              Create Role
            </button>
          ) : null}
          {userPermission("Manage Permissions") ? (
            <button
              className="btnPrimary ml-2"
              onClick={() => setRoleManager(!roleManager)}
            >
              Manage Permissions
            </button>
          ) : null}
        </div>
      </div>

      <DataTable
        heads={["Sr#", "name", "created at", "updated at", "actions"]}
        items={roles.map((role, index) => ({
          serialnumber: index + 1,
          name: role["name"],
          created_at: dayjs(role["created_at"]).format(
            "ddd, MMM D, YYYY h:mm A"
          ),
          updated_at: dayjs(role["created_at"]).format(
            "ddd, MMM D, YYYY h:mm A"
          ),
          action: [
            <div className="flex items-center justify-center">
              {userPermission("Edit Role") ? (
                <PencilSquareIcon
                  onClick={() => handleEditRole(role)}
                  className="h-4 cursor-pointer"
                />
              ) : null}
            </div>,
          ],
        }))}
      />
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        handleModalSubmit={handleSubmit}
        className="max-w-xl"
        title={
          (modalType === "newRole" && "Create New Role") ||
          (modalType === "editRole" && "Edit User Role")
        }
        modalType="userRole"
        isLoading={isLoading}
      >
        <InputField
          label="role name"
          name="role name"
          placeholder="Enter role name"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          error={errors.roleName}
        />
      </Modal>
      {/* <Modal
        open={isPermission}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        handleModalSubmit={handleSubmit}
        className="max-w-xl"
        title={"Manage Permissions"}
        modalType="rolePermissions"
        isLoading={isLoading}
      >
        <InputField
          label="role name"
          name="role name"
          placeholder="Enter role name"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          error={errors.roleName}
        />

        <div className="mt-4">
          <label htmlFor="roleId" className="inputLabel">
            Select Role:
          </label>
          <select
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            name="roleId"
            className={`inputField mt-1 ${
              errors.level ? "border-red-500" : ""
            }`}
            // error={errors.level}
          >
            <option value="">Select role</option>
            {roles?.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
          {/* {errors.level && <div className="text-red-500">{errors.level}</div>} 
        </div>
      </Modal> */}
    </div>
  );
}
