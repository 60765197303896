import { useLocation } from "react-router-dom";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";

export default function Breadcrumbs() {
  const location = useLocation();
  //'const navigate = useNavigate();
  const state = location.state;
  const currentPath = location.pathname;

  const pathSegments = currentPath
    ?.replaceAll("_", " ")
    ?.split("/")
    ?.filter((segment) => segment !== "");
  const labelSeg = state?.path
    ? state?.path?.split("/")?.filter((segment) => segment !== "")
    : null;
  const data = labelSeg && labelSeg.length > 0 ? labelSeg : pathSegments;
/*   const handleNavigate = (index) => {
    navigate(`/${pathSegments.slice(0, index + 1).join("/")}`, {
      state: { path: `/${data.slice(0, index + 1)?.join("/")}` },
    });
  }; */
  return (
    <div className="flex items-center space-x-2 max-w-fit text-lg">
      {data.map((segment, index) => (
        <div
          key={index}
          className="flex capitalize font-bold justify-between text-gray-700 dark:text-gray-300"
        >
          {index !== pathSegments.length - 1 ? (
            <button
              to={`/${pathSegments.slice(0, index + 1).join("/")}`}
              className="flex items-center hover:text-primary hover:cursor-not-allowed dark:hover:text-white gap-1 capitalize border-transparent border-b-2 hover:border-primary dark:hover:border-white transition-all"
              // onClick={() => handleNavigate(index)}
            >
              {segment}
              <ChevronDoubleRightIcon className="w-4" />
            </button>
          ) : (
            <div className="flex items-center border-transparent border-b-2 transition-all">
              {segment}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
