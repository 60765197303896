import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
function AlreadyAssignErrorModal({
  isExistCobmiErrors,
  handleBacklinks,
  setAlreadyAssignErrorModal,
  setSelectedData,
}) {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <>
      <div
        class="flex items-center p-4 mb-4 text-lg text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
        role="alert"
      >
        <FaInfoCircle />
        <div>
          <span class="font-medium ml-2">
            {isExistCobmiErrors && isExistCobmiErrors?.message
              ? isExistCobmiErrors?.message
              : ""}
          </span>
        </div>
      </div>
      <div className="lg:grid-cols-1 grid gap-3 border-b dark:bg-white/10 dark:rounded-md font-bold mt-5">
        <div className="flex items-center py-1 pr-5 justify-between">
          <p>Existing Backlinks</p>
        </div>
      </div>

      <div className="lg:grid-cols-1 grid grid-cols-1 gap-3 dark:text-white/70 mt-1">
        <div className="flex flex-col h-96 overflow-y-auto pt-1 border border-gray-200 border-t-0 text-sm">
          {isExistCobmiErrors &&
            isExistCobmiErrors?.errors?.map((data, index) => (
              <p key={index} className="px-1 flex items-center">
                <span className="text-left pr-2">{index + 1}.</span>{" "}
                {data.backlink_domain}
              </p>
            ))}
        </div>
      </div>
      <div className="mt-4 p-2">
        <label className="flex items-center">
          <input
            type="checkbox"
            className="w-4 h-4 mr-2"
            checked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
            }}
          />
          <span className="font-normal">
            Do you want to Skip Existing Backlinks ?
          </span>
        </label>
      </div>
      <div className="flex items-cente justify-end mt-4 space-x-2 text-white px-4">
        <button
          onClick={(e) => handleBacklinks("modal", e, isChecked)}
          className="btnPrimary bg-green-600"
        >
          Submit
        </button>
        <button
          type="button"
          className="btnPrimary bg-red-500"
          onClick={() => {
            setSelectedData([]);
            setAlreadyAssignErrorModal(false);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
export default AlreadyAssignErrorModal;
