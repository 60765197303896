import React from "react";
import { Bars3BottomLeftIcon, Bars3Icon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import EclipseDropdown from "../Menu/EclipseDropdown";
import Breadcrumbs from "../common/Breadcrumbs";
import Topbar from "../Menu/Topbar";
import { useDarkMode } from "../../utils/useDarkMode";
import { BsBrowserChrome } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
export default function LayoutHead({
  show_navlabel,
  toggleNavbar,
  setSearchText,
  searchText,
}) {
  const [isDarkMode, toggleDarkMode] = useDarkMode();
  const { is_browser_active } = useSelector((state) => state.auth);
  const location = useLocation();
  return (
    <div className="fixed top-0 w-full z-40">
      <header
        className={
          show_navlabel
            ? "grid grid-cols-body "
            : "grid grid-cols-bodyClosed dark:bg-blue bg-white dark:text-white/80 border-b dark:border-white/10"
        }
      >
        {show_navlabel ? (
          <div className="flex px-5 pt-5 items-center justify-between">
            {show_navlabel && (
              <Link to="/">
                {isDarkMode ? (
                  <img src="/img/logo.svg" className="h-12" alt="" />
                ) : (
                  <img src="/img/robo_logo_3.png" className="h-12" alt="" />
                )}
              </Link>
            )}
            <Bars3Icon
              onClick={toggleNavbar}
              className="cursor-pointer w-6 dark:text-white"
            />
          </div>
        ) : (
          <div className="flex items-center justify-center ml-4">
            <Bars3BottomLeftIcon
              onClick={toggleNavbar}
              className="cursor-pointer w-6"
            />
          </div>
        )}
        <div className="flex items-center w-full justify-between bg-white px-4 dark:bg-blue py-2">
          {show_navlabel ? (
            <div className="hidden md:flex items-center gap-7 px-2">
              {location.pathname.includes("dashboard") && <Breadcrumbs />}
              <Topbar
                show_navlabel={show_navlabel}
                setSearchText={setSearchText}
                searchText={searchText}
              />
            </div>
          ) : (
            <>
              {" "}
              <Topbar
                show_navlabel={show_navlabel}
                setSearchText={setSearchText}
                searchText={searchText}
              />
             {/*  <div className="lg:hidden">
                <Breadcrumbs />
              </div> */}
            </>
          )}
          <div className="flex items-center justify-end gap-5">
            {/* {show_navlabel && (
              <SearchBox
                className="w-72 border border-gray-300 rounded"
                setSearchText={setSearchText}
                searchText={searchText}
              />
            )} */}
            {+is_browser_active === 1 ? (
              <span className="flex items-center animate-blink">
                <BsBrowserChrome className="mr-2" /> Browser is running...
              </span>
            ) : null}

            <EclipseDropdown toggleDarkMode={toggleDarkMode} />
          </div>
        </div>
      </header>
    </div>
  );
}
