import React, { useState, useRef, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
const MultiSelectBox = ({
  options = [],
  optionLabel = "label",
  optionValue = "value",
  onChange,
  selectedData = [],
  selectLabel = "Select options",
  placeholder = "Filter options...",
}) => {
  const [filter, setFilter] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const containerRef = useRef(null);

  const handleSelectAllChange = () => {
    const newSelectedOptions = selectAll
      ? []
      : options.map((option) => option[optionValue]);
    setSelectedOptions(newSelectedOptions);
    setSelectAll(!selectAll);
    if (onChange) onChange(newSelectedOptions);
  };

  const handleOptionChange = (value) => {
    const newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];

    setSelectedOptions(newSelectedOptions);
    if (onChange) onChange(newSelectedOptions);
  };

  const handleFilterChange = (event) => setFilter(event.target.value);

  const clearFilter = () => {
    setFilter("");
    setIsOpen(false);
  };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const clearAllSelections = () => {
    setSelectedOptions([]);
    setSelectAll(false);
    if (onChange) onChange([]);
  };

  const filteredOptions = options.filter((option) =>
    option[optionLabel].toLowerCase().includes(filter.toLowerCase())
  );

  const selectedLabels =
    selectedOptions.length > 1
      ? `${selectedOptions.length} items selected`
      : options
          .filter((option) => selectedOptions.includes(option[optionValue]))
          .map((option) => option[optionLabel])
          .join(", ");

  useEffect(() => {
    if (JSON.stringify(selectedData) !== JSON.stringify(selectedOptions)) {
      setSelectedOptions(selectedData);
      setSelectAll(selectedData.length === options.length);
    }
  }, [selectedData, options, selectedOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleEscPress = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={containerRef}
      className={`relative border rounded-lg bg-white mt-1 ${
        isOpen ? "rounded-b-none" : ""
      } w-full font-sans`}
    >
      <button
        type="button"
        onClick={toggleDropdown}
        className={`w-full text-left p-2 bg-white border border-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-between`}
      >
        <span className="truncate">{selectedLabels || selectLabel}</span>
        <ChevronDownIcon className="w-5 h-5 text-black" aria-hidden="true" />
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-300 rounded-lg rounded-t-none shadow-lg z-50">
          <div className="flex items-center p-0 border-b border-gray-300">
            <input
              type="text"
              className="w-full p-2 border-none rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder={placeholder}
              value={filter}
              onChange={handleFilterChange}
            />
            <button
              type="button"
              onClick={clearFilter}
              className="ml-2 p-1 text-gray-500 hover:text-gray-700"
            >
              <IoCloseSharp className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
          <div className="flex items-center justify-between p-2 border-b border-gray-300">
            <label className="flex items-center mb-2 cursor-pointer font-semibold text-md">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
                className="mr-2 h-4 w-4"
              />
              Select All
            </label>
            <label className="flex items-center mb-2 cursor-pointer font-semibold text-md">
              <button
                type="button"
                onClick={clearAllSelections}
                className="ml-2 text-gray-500 hover:text-gray-700 flex items-center font-semibold"
              >
                Clear All
              </button>
            </label>
          </div>
          <div className="max-h-48 overflow-y-auto">
            {filteredOptions.map((option) => (
              <div
                key={option[optionValue]}
                className={`flex items-center mb-1 p-2 cursor-pointer text-sm ${
                  selectedOptions.includes(option[optionValue])
                    ? "bg-slate-100 text-black"
                    : "hover:bg-gray-200"
                }`}
              >
                <label className="flex items-center w-full cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option[optionValue])}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleOptionChange(option[optionValue]);
                    }}
                    className="mr-2 h-4 w-4"
                  />
                  {option[optionLabel]}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectBox;
