import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import SubPermission from "./SubPermission";
import { HiPlus } from "react-icons/hi";
import AddUpdatePermission from "./AddUpdatePermission";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";

export default function Permissions() {
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getPermissions = () => {
    setIsLoading(true);
    api
      .get(
        `${process.env.REACT_APP_PUBLIC_API}/api/permissions/list_permissions`
      )
      .then((response) => {
        setPermissions(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getPermissions();
  }, []);
  const [permission, setPermission] = useState("");
  const [permission_id, setPermission_id] = useState(null);
  const [subPermissions, setSubPermissions] = useState([]);
  const [addUpdatePermission, showAddUpdatePermission] = useState(false);
  //const [selectedData, setSelectedData] = React.useState([]);
/*   function getPermissionNames(data) {
    return data.flatMap((item) => {
      const names = [item.name];
      if (item.sub_permissions && item.sub_permissions.length > 0) {
        names.push(...getPermissionNames(item.sub_permissions));
      }
      return names;
    });
  } */

/*   function areAllPermissionsSelected(permissions, selectedData) {
    const permissionNames = permissions.flatMap((permission) => {
      const names = [permission.name];
      if (permission.sub_permissions && permission.sub_permissions.length > 0) {
        names.push(...getPermissionNames(permission.sub_permissions));
      }
      return names;
    });
    const selectedPermissionNames = selectedData.flatMap((permission) => {
      const names = [permission.name];
      if (permission.sub_permissions && permission.sub_permissions.length > 0) {
        names.push(...getPermissionNames(permission.sub_permissions));
      }
      return names;
    });

    // Sort both arrays to ensure consistent comparison
    permissionNames.sort();
    selectedPermissionNames.sort();

    // Compare both arrays
    return (
      JSON.stringify(permissionNames) ===
      JSON.stringify(selectedPermissionNames)
    );
  } */

/*   function handleCheckboxSelection(name) {
    const findMenuItem = (menu, currentLabel) => {
      for (const item of menu) {
        if (item.name === currentLabel) {
          return item;
        } else if (typeof item.sub_permissions === "string") {
          const parsedSubPermissions = JSON.parse(item.sub_permissions);
          const foundInChildren = findMenuItem(
            parsedSubPermissions,
            currentLabel
          );
          if (foundInChildren) {
            return foundInChildren;
          }
        } else if (Array.isArray(item.sub_permissions)) {
          const foundInChildren = findMenuItem(
            item.sub_permissions,
            currentLabel
          );
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return null;
    };

    const menuItem = findMenuItem(permissions, name);
    if (!menuItem) {
      console.error("Menu item not found");
      return [];
    }
    const existingIndex = selectedData.findIndex(
      (item) => item.name === menuItem.name
    );

    if (existingIndex !== -1) {
      const updatedSelectedData = [...selectedData];
      updatedSelectedData.splice(existingIndex, 1); // Remove the permission
      setSelectedData(updatedSelectedData);
    } else {
      const mapToPermissions = (menu) => {
        if (!menu) return null;
        if (typeof menu === "string") {
          menu = JSON.parse(menu);
        }

        // Ensure menu is an array before mapping over it
        if (!Array.isArray(menu)) {
          // If menu is not an array, return an object with its properties
          return {
            name: menu.name,
            sub_permissions: Array.isArray(menu.sub_permissions)
              ? mapToPermissions(menu.sub_permissions)
              : menu.sub_permissions,
          };
        }

        // If menu is an array, map over it
        return menu.map((item) => ({
          name: item.name,
          sub_permissions: Array.isArray(item.sub_permissions)
            ? mapToPermissions(item.sub_permissions)
            : item.sub_permissions,
        }));
      };

      // Construct permissions array, including the parent permission
      const permissionsArray = [
        {
          name: menuItem.name,
          sub_permissions: mapToPermissions(menuItem.sub_permissions),
        },
      ];

      setSelectedData((prevState) => [...prevState, ...permissionsArray]);
    }
  } */

/*   const selectAll = () => {
    const isAllSelected = areAllPermissionsSelected(
      permissions?.map((permission) => {
        if (
          permission.sub_permissions &&
          typeof permission.sub_permissions === "string"
        ) {
          permission.sub_permissions = JSON.parse(permission.sub_permissions);
        }
        return permission;
      }),
      selectedData
    );
    if (isAllSelected) {
      setSelectedData([]);
    } else {
      setSelectedData(permissions);
    }
  }; */
  const handleUpdatePermissions = (data) => {
    setPermission(data?.name);
    setSubPermissions(data?.sub_permissions);
    setPermission_id(data?.id);
    showAddUpdatePermission(true);
  };
  const handleAddPermissions = () => {
    setPermission("");
    setSubPermissions([]);
    setPermission_id(null);
    showAddUpdatePermission(true);
  };
  const onCloseUpdatePermissions = (data) => {
    setPermission("");
    setSubPermissions([]);
    setPermission_id(null);
    showAddUpdatePermission(false);
  };
  return (
    <div className="px-6 pt-4">
      <ToastContainer />
      <CustomBreadcrumbs
                crumbs={[
                  { label: "Home", link: "/" },
                  { label: "Permissions", link: "" },
                ]}
              />
      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-12">
          <div>
            <div className="flex items-center justify-end mb-5 border-b dark:border-white/20">
              {userPermission("Add Permission") ? (
                <button
                  onClick={handleAddPermissions}
                  type="button"
                  className="flex items-center text-secondary"
                >
                  <HiPlus className="text-lg" />
                  <span className="ml-1">Add new permission</span>
                </button>
              ) : null}
            </div>

            {/* Permsission List */}

            <div className="h-[calc(100vh-210px)] overflow-y-scroll">
              {permissions?.map((item, key) => {
                const { name, sub_permissions } = item;
                const subPermissions = JSON.parse(sub_permissions);
                return (
                  <div key={key}>
                    {item?.id ? (
                      item?.sub_permissions ? (
                        <SubPermission
                          children={subPermissions}
                          name={name}
                          id={item.id}
                          item={item}
                          handleUpdatePermissions={handleUpdatePermissions}
                        />
                      ) : (
                        <div className="flex items-center justify-between w-full border-">
                          <div className="flex items-center gap-2 py-2">
                            {name}
                          </div>
                          {userPermission("Edit Permission") ? (
                            <div className="flex items-center pl-1">
                              <PencilSquareIcon
                                key={`${name}-action`}
                                onClick={() =>
                                  handleUpdatePermissions({
                                    id: item?.id,
                                    name,
                                    sub_permissions: subPermissions,
                                  })
                                }
                                className="h-5 cursor-pointer text-gray-400"
                              />
                            </div>
                          ) : null}
                        </div>
                      )
                    ) : null}
                    {subPermissions?.length > 0 ? (
                      <SubPermission children={subPermissions} />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            {addUpdatePermission && (
              <AddUpdatePermission
                permission={permission}
                setPermission={setPermission}
                subPermissions={subPermissions}
                setSubPermissions={setSubPermissions}
                showAddUpdatePermission={showAddUpdatePermission}
                getPermissions={getPermissions}
                permission_id={permission_id}
                setIsLoading={setIsLoading}
                onClose={onCloseUpdatePermissions}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
